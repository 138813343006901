import { useState, useEffect, useRef } from "react";

import './Portfolio.css';

import Cookies from 'universal-cookie';

import Select from 'react-select'

import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

import { Reg_Proposal } from "services/RegisterData";
import { SvgBehance, SvgCircleNoSelected, SvgCircleSelected, SvgDelete, SvgEdit, SvgEye, SvgGoogleDrive, SvgLink_opt2, SvgLockClose, SvgSave, SvgSearch, SvgSetaRight } from "components/SvgFile";
import { cookiesRegister } from "fixedData";
import Toast from "components/Toast";

export default function Page_Portfolio(){
    const cookies = new Cookies();

    const [ loading, setLoading ] = useState(false);
    const [ toast, setToast ]     = useState(false);

    const textArea = useRef();
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('portfolio'));
    const [ showClient, setShowClient ]     = useState(GetDataPage('client'));
    
    const [ listTag, setListTag ]         = useState(ShowTag());
    const [ search, setSearch ]           = useState('');
    const [ tag, setTag ]                 = useState('all');

    const [ userPage, setUserPage ]         = useState(GetUserData('page'));
    const [ userAccess, setUserAccess ]     = useState(GetUserData('access'));

    const [ currentPage, setCurrentPage ]   = useState(GetListPag('currentPage'));

    const [ title, setTitle ]             = useState('');
    const [ client, setClient ]           = useState('');
    const [ newClient, setNewClient ]     = useState('');
    const [ description, setDescription ] = useState('');
    const [ newHeight, setNewHeight ]     = useState(110);
    const [ projectAdd, setProjectAdd ]   = useState([]);

    const [ newProposal, setNewProposal ] = useState(false);

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showDataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showDataPage.slice(startItens, endItens);

    const [ dataSearch, setDataSearch ] = useState([]);
    const [ dup_0, setDup_0 ] = useState([]);
    const [ dup_1, setDup_1 ] = useState([]);
    const [ dup_2, setDup_2 ] = useState([]);
    const [ dup_3, setDup_3 ] = useState([]);

    function SearchInput(value){
        let newList_0 = []; let newList_1 = [];
        let newList_2 = []; let newList_3 = [];

        let valeu_sep = value.toLowerCase().split(' ');
        if(value){

            if(valeu_sep.length == 1){
                GetDataPage('portfolio').map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[0]) != -1){
                        newList_0.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[0]) != -1){
                        newList_0.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[0]) != -1){
                        newList_0.push(key);
                    }
                });
                setDup_0(newList_0.filter((item, index) => newList_0.indexOf(item) == index));
                setTimeout(() => {
                    setShowDataPage(newList_0.filter((item, index) => newList_0.indexOf(item) == index));
                }, 100);

            }
            if(valeu_sep.length == 2){
                dup_0.map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[1]) != -1){
                        newList_1.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[1]) != -1){
                        newList_1.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[1]) != -1){
                        newList_1.push(key);
                    }
                });
                setDup_1(newList_1.filter((item, index) => newList_1.indexOf(item) == index));
                setTimeout(() => {
                    setShowDataPage(newList_1.filter((item, index) => newList_1.indexOf(item) == index));
                }, 100);
            }
            if(valeu_sep.length == 3){
                setDataSearch([]);
                dup_1.map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_2.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_2.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[2]) != -1){
                        newList_2.push(key);
                    }
                });
                setDup_2(newList_2.filter((item, index) => newList_2.indexOf(item) == index));
                setTimeout(() => {
                    setShowDataPage(newList_2.filter((item, index) => newList_2.indexOf(item) == index));
                }, 100);
            }
            if(valeu_sep.length == 4){
                setDataSearch([]);
                dup_2.map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_3.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_3.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[2]) != -1){
                        newList_3.push(key);
                    }
                });
                setDup_3(newList_3.filter((item, index) => newList_3.indexOf(item) == index));
                setTimeout(() => {
                    setShowDataPage(newList_3.filter((item, index) => newList_3.indexOf(item) == index));
                }, 100);
            }

        }else {
            setShowDataPage(GetDataPage("portfolio"));
        }
        setSearch(value);
    }

    function SearchTag(id, value){
        const newList = [];
        if(id){
            if(id == 'all'){
                setShowDataPage(GetDataPage("portfolio"));
                setTag('all');
            }else {
                GetDataPage("portfolio").forEach(item =>{
                    item.tag.map((key, index)=>{
                        if(key.name == id){
                            newList.push(item);
                        }
                    })
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setShowDataPage(duplicate);
                setTag(value);
            }
        }else {
            setShowDataPage(GetDataPage("portfolio"));
            setTag('all');
        }
    }

    function ShowTag(){
        const options = [];
        GetDataPage("portfolio").forEach(item =>{
            item.tag.map((key_1, index_1)=>{
                options.push(key_1.name);
            })
        });
        const duplicate = options.filter((item, index) => options.indexOf(item) === index);
        // const newData = [];
        // duplicate.map((key, index)=>{
        //     let dataPage = GetDataPage("portfolio").find(item => item.id == key.id_given);
        //     newData.push(dataPage);
        // })
        return duplicate;
    }

    function NewPortfolio(id){
        SetListPag('currentPage', 'portfolio_details');
        SetListPag('currentPageId', id);
    }

    function Delete(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_portfolio", "name": value });
        SetModalState('Confirmation', true);
    }

    function AutoGrow() {
        setNewHeight(textArea.current.scrollHeight - 20);
    }

    function ShowCircleSelected(id, value, index, state){
        if(newProposal == true){
            const newData = [...showDataPage];
            newData[index].selected = state;
            setShowDataPage(newData);

            if(state == true){
                projectAdd.push({ "id": id, "project": value, "text": "" })
            }else {
                const dataAdd    = [...projectAdd];
                let index_remuve = '';
                dataAdd.map((key, index)=>{
                    if(key.id == id){
                        index_remuve = index;
                    }
                })
                dataAdd.splice(index_remuve, 1);
                setProjectAdd(dataAdd);
            }
        }
    }

    function AddNewProposal(state){
        setNewProposal(state);
        const newData = [...showDataPage];
        newData.map((key, index)=>{
            key.selected = false;
        });
        setTitle('');
        setClient('');
        setProjectAdd([]);
        setDescription('');
        setNewHeight(110);
        setShowDataPage(newData);
    }

    function ShowClient(){
        const options = [{ "value": "add", "label": "Adicionar novo cliente" }]
        showClient.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function ShowFileCover(type_file, file){
        switch (type_file) {
            case 'Vimeo':
                let linkVimeo = file.split('/');
                return(
                    <iframe className="project_data_img" src={ "https://player.vimeo.com/video/" + linkVimeo[3] + "?autoplay=1&loop=1&title=0&byline=0&portrait=0&loop=1&controls=0" } frameBorder="0" allowFullScreen />
                )

            case 'Youtube':
                let linkYoutube = file.split('=');
                return(
                    <iframe className="project_data_img" src={ "https://www.youtube.com/embed/" + linkYoutube[1] + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + linkYoutube[1] + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen />
                )
        }
    }

    function RegisterProposal(){
        setLoading(true);
        Reg_Proposal(userId, title, client.value, newClient, description, projectAdd, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        setNewProposal(false);
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
    }

    function OpenPortfolio(id){
        window.open(process.env.REACT_APP_API_URL + "?project=portfolio/" + id, '_blank');
    }

    function HandleDataProposal(index, value){
        const newData = [...projectAdd];
        newData[index].text = value;
        setProjectAdd(newData);
    }

    function OpenTag(){
        SetListPag('currentPage', 'show_tag');
    }

    function CopyLink(value){
        setToast(true);
        navigator.clipboard.writeText(process.env.REACT_APP_API_URL + "?project=portfolio/" + value);
        setTimeout(() => {
            setToast(false);
        }, 1500);
    }

    useEffect(()=>{
        RegisterDataPage("portfolio", setShowDataPage);
        RegisterDataPage("client", setShowClient);

        RegisterUserData('id', setUserId);
        RegisterUserData("page", setUserPage);
        RegisterUserData("access", setUserAccess);

        RegisterListPag("currentPage", setCurrentPage);
    }, []);

    useEffect(()=>{
        setListTag(ShowTag());
    }, [currentPage]);
    
    return(
        <div className="Page_Portfolio">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            {
                toast == false ? null : <Toast />
            }
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Portfólio
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar..." />
                </div>
                <div className="list_opt_alt_page">
                    {
                        userAccess == 1 ?
                            <>
                                <div className="new_data" onClick={ ()=>{ OpenTag() } }>
                                    <div className="">
                                        <span className="icons_menu">+</span>
                                    </div>
                                    <div className="">Tags</div>
                                </div>
                                <div className="new_data" onClick={ ()=>{ AddNewProposal(!newProposal) } }>
                                    <div className="">
                                        <span className="icons_menu">+</span>
                                    </div>
                                    <div className="">proposta</div>
                                </div>
                            </> :
                            userPage.find(item => item.page == 'proposal') ?
                                <div className={ newProposal == true ? "new_block_text new_block_active" : "new_block_text" } onClick={ ()=>{ AddNewProposal(!newProposal) } }>
                                    <div className="">
                                        <span className="icons_menu">+</span>
                                    </div>
                                    <div className="">proposta</div>
                                </div>
                            : null
                    }
                    <div className="new_block_text" onClick={ ()=>{ NewPortfolio(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Portfólio
                        </div>
                    </div>
                </div>
            </div>
            {
                listTag.length > 0 ? 
                <div className="list_category">
                    <div className={ tag == 'all' ? "show_name_category category_active" : "show_name_category" } onClick={ ()=>{ SearchTag('all') } }>
                        Todas tags
                    </div>
                    {
                        listTag.map((key, index)=>{
                            return(
                                <div className={ tag == key ? "show_name_category category_active" : "show_name_category" } key={ index } onClick={ ()=>{ SearchTag(key, key) } }>
                                    {
                                        key
                                    }
                                </div>
                            )
                        })
                    }
                </div> 
                : null
            }
            <div className="list_portfolio_new_proposal">
                <div className="list_project">
                    {
                        currentItens.length > 0 ?
                            currentItens.map((key, index)=>{
                                return(
                                    <div className="div_project_data" key={ index }>
                                        <div className="project_data_inf">
                                            <div className={ newProposal == false ? "project_data_client div_show_select_new_data" : "project_data_client select_project_data" } onClick={ ()=>{ OpenPortfolio(key.url) } }>
                                                <div className="div_show_select_name_data">
                                                    { key.name_client }
                                                </div>
                                                <div className="name_project">
                                                    { key.project }
                                                </div>
                                            </div>
                                            {
                                                newProposal == false ? null :
                                                <div className="project_data_opt_select" onClick={ ()=>{ ShowCircleSelected(key.id, key.project, index, !key.selected) } }>
                                                    {
                                                        key.selected == true ?
                                                        <SvgCircleSelected color="#07C4D6" className="circle_selected" /> :
                                                        <SvgCircleNoSelected color="#ffffff" className="circle_selected" />
                                                    }
                                                </div>
                                            }
                                            <div className="click_open" onClick={ ()=>{ OpenPortfolio(key.url) } } />
                                            <div className={ newProposal == false ? "project_data_show_opt" : "hide_project_data" }>
                                                <div className="project_data_delete" onClick={ ()=>{ Delete(key.id, key.project) }}>
                                                    <SvgDelete color="#ffffff" className="icons" />
                                                </div>
                                                <div className="project_data_eye" onClick={ ()=>{ CopyLink(key.url) } }>
                                                    <SvgLink_opt2 color="#ffffff" className="icons" />
                                                </div>
                                                <div className="project_data_eye" onClick={ ()=>{ OpenPortfolio(key.url) } }>
                                                    <SvgEye color="#ffffff" className="icons" />
                                                </div>
                                                <div className="project_data_edit" onClick={ ()=>{ NewPortfolio(key.id) } }>
                                                    <SvgEdit color="#ffffff" className="icons" />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            key.restricted == false ? null :
                                            <div className="project_data_lock_close">
                                                <SvgLockClose color="#FFFFFF" className="lock" />
                                            </div>
                                        }
                                        <div className="project_data_div_img" style={ { backgroundColor: key.type_file == 'Color' ? key.cover_color : null } }>
                                            {
                                                key.type_file == 'Image' ?
                                                <img alt="show img" src={ key.file != '' ? "https://portfoliov3.chroma-garden.com/assets/portfolio/cover/thumbnail/thumbnail_" + key.file : "https://portfoliov3.chroma-garden.com/assets/not_file.jpg" } className="project_data_img" />
                                                : 
                                                    key.type_file == 'Color' ? "Cor no fundo" : ShowFileCover(key.type_file, key.file)
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        :
                        <div className="no_data">Nenhum portfólio encontrado...</div>
                    }
                </div>
                {
                    newProposal == false ? null :
                    <div className="new_proposal">
                        <div className="proposal_title">
                            Nova proposta
                        </div>
                        <div className="save_proposal">
                            <input type="text" maxLength="140" className="input_new_proposal" value={ title } onChange={ (e)=>{ setTitle(e.target.value) } } placeholder="Título..." />
                        </div>
                        <div className="save_proposal">
                            <Select className="input_new_proposal" defaultOptions options={ ShowClient() } onChange={ (e)=> { setClient(e); } } placeholder="Cliente..." />
                        </div>
                        {
                            client.value == 'add' ?
                            <div className="save_proposal">
                                <input type="text" maxLength="140" className="input_new_proposal" value={ newClient } onChange={ (e)=>{ setNewClient(e.target.value) } } placeholder="Novo cliente..." />
                            </div> : null
                        }
                        <div className="save_proposal">
                            <textarea ref={ textArea } className="input_new_proposal" onChange={ (e)=>{ setDescription(e.target.value); AutoGrow() } } value={ description !='' ? description.replace(/<br>/g,'\n') : "" } style={ { height: newHeight + "px" } } placeholder="Descrição..." />
                        </div>
                        <div className="save_proposal summary">
                            Resumo
                        </div>
                        <div className="list_project_add">
                            {
                                projectAdd.length > 0 ?
                                    projectAdd.map((key, index)=>{
                                        return(
                                            <div className="show_icon_proposal" key={ index }>
                                                <div className="">
                                                    Projeto: { key.project }
                                                </div>
                                                <div className="save_proposal">
                                                    <textarea className="input_new_proposal" onChange={ (e)=>{ HandleDataProposal(index, e.target.value); } } value={ key.text !='' ? key.text.replace(/<br>/g,'\n') : "" } style={ { height: "34px" } } placeholder="Descrição..." />
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                <div className="list_input_data">
                                    <div className="no_data" style={ { marginTop:'0px' } }>Nenhuma categoria encontrado...</div>
                                </div>
                            }
                        </div>
                        <div className="save_proposal">
                            <div className="new_block_text" onClick={ ()=>{ RegisterProposal(); } }>
                                Criar
                            </div>
                        </div>
                        <div className="save_proposal">
                            <div className="cancel_proposal" onClick={ ()=>{ AddNewProposal(false) } }>
                                Cancelar
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                showDataPage.length >= 50 ?
                <div className="list_pages">
                    {
                        Array.from(Array(page), (item, index)=>{
                            return(
                                <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                    { index + 1 }
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )
}