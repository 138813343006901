import { useState, useEffect } from "react";

import './Gallery.css';

import { SvgAlert, SvgSetaRight } from "components/SvgFile";

export default function PreviewFixed_Gallery(props){

    const [ dataPage, setDataPage ] = useState(ShowGallery(props.data));
    const [ imgStart, setImgStart ] = useState(1);
    const [ imgEnd, setImgEnd ]     = useState(props.data.gallery.length);

    function ShowGallery(value){
        const newData = [];
        switch (value.gallery.length) {
            case 1:
                    value.gallery.map((key, index)=>{
                        newData.push(key);
                        newData.push(key);
                        newData.push(key);
                        newData.push(key);
                        newData.push(key);
                    });
                break;
            case 2:
                    value.gallery.map((key, index)=>{
                        newData.push(key);
                    });
                    value.gallery.map((key, index)=>{
                        newData.push(key);
                    });
                    value.gallery.map((key, index)=>{
                        newData.push(key);
                    });
                break;
            case 3: case 4:
                    value.gallery.map((key, index)=>{
                        newData.push(key);
                    });
                    value.gallery.map((key, index)=>{
                        newData.push(key);
                    });
                break;
            default:
                    value.gallery.map((key, index)=>{
                        newData.push(key);
                    });
                break;
        }
        return newData;
    }
    
    function AltPosition_prev(index){
        setImgStart(imgStart - 1);
        var stream = document.querySelector('.div_gallery_model_2');
        var items  = document.querySelectorAll('.show_gallery_model_2');
        var prev   = document.querySelector('.gallery__prev_model_2');

        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.show_gallery_model_2');
    }
    
    function AltPosition_next(index){
        setImgStart(imgStart + 1);
        var stream = document.querySelector('.div_gallery_model_2');
        var items  = document.querySelectorAll('.show_gallery_model_2');
        var next   = document.querySelector('.gallery__next_model_2');

        stream.appendChild(items[0]);
        items = document.querySelectorAll('.show_gallery_model_2');
    }

    return(
        <div className="div_show_gallery">
            <div className="data_fixed">
                <div className="div_show_preview">
                    <div className="div_name">
                        {/* <div className="line" /> */}
                        {/* Enhanced Product Understanding */}
                    </div>
                </div>

                <div className="div_arrow" style={ { backgroundColor: props.color } }>
                    {
                        imgStart == 1 ? 
                        <div className="gallery__prev_model_2 arrow_left hide_arrow">
                            <SvgSetaRight className="icons left" color="rgb(255 255 255 / 50%)" />
                            <div className="disabled_arrow_left" />
                        </div> :
                        <div className={ "gallery__prev_model_2 arrow_left" } onClick={ ()=>{ AltPosition_prev("gallery__prev_model_2") } }>                            
                            <SvgSetaRight className="icons left" color="#ffffff" />
                            <div className="space_arrow_left" />
                        </div>
                    }
                    {
                        imgStart == imgEnd ? 
                        <div className="gallery__next_model_2 arrow_right hide_arrow">
                            <div className="disabled_arrow_right" />
                            <SvgSetaRight className="icons" color="#ffffff" />
                        </div> :
                        <div className="gallery__next_model_2 arrow_right" onClick={ ()=>{ AltPosition_next("gallery__prev_model_2") } }>
                            <div className="space_arrow_right" />
                            <SvgSetaRight className="icons" color="#ffffff" />
                        </div>
                    }
                </div>
            </div>
                
            <div className="div_gallery_model_2">
                {                    
                    dataPage.map((key, index)=>{
                        return(
                            <div className={ "show_gallery_model_2 " } key={ index }>
                                <div className="number_gallery"></div>
                                <div className="show_text">
                                    <div className="show_data_project">
                                        {
                                            key.title == "" ? null :
                                            <div className="title" data-animationtext="text">{ key.title }</div>
                                        }
                                        {
                                            key.text == "" ? null : 
                                            <div className="text text_add_div" data-animationtext="text" dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                        }
                                    </div>
                                </div>
                                <div className="background_img">
                                    <img alt="bg" src={ "./assets/portfolio/gallery/" + key.file } className="img_bg" />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}