import { useState, useEffect, useRef } from "react";

import './IntegrationManual.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgFullScreenClose, SvgFullScreenOpen, SvgSetaRight } from "components/SvgFile";

export default function Preview_IntegrationManual(props){

    const PageFullScreen    = useRef();
    const [ url, setUrl ]   = useState(ShowDataLink());

    const [ fullscreen, setFullscreen ] = useState(false);
    const [ page, setPage ]             = useState(1);
    const [ listBtn, setListBtn ]       = useState([
        {
            "type": 0,
            "type_logo": 1,
            "bg_color": "#e5e5e5",
            "title": "Manual de intetração",
            "subtitle": "Transformando ideias em experiências únicas desde",
            "text": "imagine novos formatos",
            "year_document": "2024",
            "year": "2010",
            "file": "http://localhost/React_JS/dashboard_portfolio/v4_final/public/assets/integration_manual/01.jpg"
        }
    ]);
    
    function ShowDataLink(){
        let link    = new URLSearchParams(window.location.search);
        let sepLink = link.get("project").split('/');
        
        if(sepLink[0] == 'integration_manual'){
            return sepLink[1];

        }else {
            return window.history.pushState("", "", "/");
        }
    }

    function TypeFullscreen(value) {
        if(value == 'open'){
            setFullscreen(true);
            let page = document.getElementById("FullScreen");
            if(page.requestFullscreen) {
                page.requestFullscreen();
                
            }else if(page.webkitRequestFullscreen) { 
                page.webkitRequestFullscreen();
                
            }else if(page.msRequestFullscreen) { 
                page.msRequestFullscreen();
            }
        }else if(value == 'close'){
            setFullscreen(false);
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }

    function ShowDataPage(value, type){
        switch (type) {
            case 1:
                return(
                    <div className="">
                        <div className="topic">
                            <div className="year">
                            </div>
                            <div className="title">
                            </div>
                            <div className="logo">
                            </div>
                        </div>
                        <div className="img_examplo">
                        </div>
                        <div className="footer">
                        </div>
                    </div>
                )
        }
    }
    
    return(
        <div className="Preview_IntegrationManual" id="FullScreen" ref={ PageFullScreen }>

            <div className="show_data">
                <div className="div_line">
                    <div className="line_ line_0" />
                    <div className="line_ line_1" />
                    <div className="line_ line_2" />
                </div>

                {
                    listBtn.map((elem, index)=>{
                        return(
                            <div className={ page == (index + 1) ? "show_data_page show_page_active" : "show_data_page" } key={ index } style={ { background: elem.bg_color } }>
                                { ShowDataPage(elem, elem.type) }
                            </div>
                        )
                    })
                }
            </div>

            <div className="show_btn">
                <div className="qtd_page"> 
                    {
                        listBtn.map((elem, index)=>{
                            let space_example = '';
                            if(index == 0){
                                space_example = 'img_example_start';
                            }else if(listBtn.length == index + 1){
                                space_example = 'img_example_end';
                            }
                            return(
                                <div className="space_page" onClick={ ()=>{ setPage(index + 1); } } key={ index }>
                                    <div className={ page == (index + 1) ? "color_line page_active" : "color_line" } />
                                    
                                    {/* 
                                    <div className={ "img_example " + space_example }>
                                        { ShowDataPage(elem.btn, "_example") }
                                    </div> 
                                    */}
                                </div>
                            )
                        })
                    }
                </div>

                <div className="opt_alt_page">
                    <div className="alt_page">
                        {
                            page == 1 ?
                            <div className="arrow">
                                <SvgSetaRight className="icons left" color="#00000078" />
                            </div> : 
                            <div className="arrow" data-tooltip-id="arrow_left" data-tooltip-content="Voltar página" data-tooltip-place="top" onClick={ ()=>{ setPage(page - 1) }}>
                                <SvgSetaRight className="icons left" color="#ededed" />
                            </div>
                        }
                        <Tooltip id="arrow_left" />

                        <div className="">
                            { page }/{ listBtn.length }
                        </div>
                        {
                            listBtn.length == page ?
                            <div className="arrow">
                                <SvgSetaRight className="icons" color="#00000078" />
                            </div> :                            
                            <div className="arrow" data-tooltip-id="arrow_right" data-tooltip-content="Avançar página" data-tooltip-place="top" onClick={ ()=>{ setPage(page + 1) }}>
                                <SvgSetaRight className="icons" color="#ededed" />
                            </div>
                        }
                        <Tooltip id="arrow_right" />
                    </div>

                    <div className="fullscreen">
                        {
                            fullscreen == false ? 
                            <div className="div_fullscreen" data-tooltip-id="fullscreen" data-tooltip-content="Abrir em tela cheia" data-tooltip-place="top" onClick={ ()=>{ TypeFullscreen('open'); } }>
                                <SvgFullScreenClose className="icons" color="#ededed" />
                            </div> :
                            <div className="div_fullscreen" data-tooltip-id="fullscreen" data-tooltip-content="Fechar a tela cheia" data-tooltip-place="top" onClick={ ()=>{ TypeFullscreen('close'); } }>
                                <SvgFullScreenOpen className="icons" color="#ededed" />
                            </div>
                        }
                        <Tooltip id="fullscreen" />
                    </div>
                </div>
            </div>
        </div>
    )
}