import React, { useState, useEffect } from "react";

import './SaveBudget.css';

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_CopyBudget, Reg_CopyService, Reg_Delete, Reg_LockBudget } from "services/RegisterData";

import { GetUserData, RegisterUserData } from "interface/Users";

import { SvgClose } from "components/SvgFile";

export default function PopUP_SaveBudget(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ idUser, setIdUser ]       = useState(GetUserData("id"));

    const [ typeLock, setTypeLock ] = useState(0);

    useEffect(()=>{
        RegisterUserData('id', setIdUser);

        RegisterModalData('SaveBudget', setModaldata);
        RegisterModalObserver('SaveBudget', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == false){
            setTypeLock(0);
        }
    }, [showPopUp]);

    function RegisterStatus(value){
        props.EditLock(value);
        setTypeLock(value);
    }

    function ClosePopUp(){
        setTypeLock(0);
        props.EditLock(0);
        SetModalState('SaveBudget', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all SaveBudget">
                        <div className="div_data">
                            <div className="title">Salvar dados</div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="div_save_budget">
                                    <div className={ typeLock == 0 ? "opt_select active_btn" : "opt_select" } onClick={ ()=>{ RegisterStatus(0); } }>
                                        Salvar rascunho
                                    </div>
                                    <div className={ typeLock == 1 ? "opt_select active_btn" : "opt_select" } onClick={ ()=>{ RegisterStatus(1); } }>
                                        Salvar versão
                                    </div>
                                </div>
                                <button className="register_data space_client">
                                    Cadastrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
