import { useState, useEffect } from "react";

import './Combo.css';

import { SetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { SvgDelete, SvgEdit, SvgSetaRight } from "components/SvgFile";

import PopUp_NewCombo from "components/PopUp/NewCombo";

export default function Page_Services_Combo(){

    const [ dataPage, setDataPage ]   = useState(GetDataPage('services_combo'));
    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(dataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = dataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('services_combo', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('services_combo'));
    }, [dataPage]);

    function NewCombo(id){
        SetListPag('currentPage', 'services_combo_details');
        SetListPag('currentPageId', id);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "services_combo", "type": "delete_combo_all", "name": value });
        SetModalState('Confirmation', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'services');
    }

    return(
        <div className="Page_Services_Combo">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                        Serviços
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page page_return" onClick={ ()=>{ ReturnPage() } }>
                        Combo
                    </div>
                </div>
                <div className="list_opt_alt_page">
                    <div className="new_block_text" onClick={ ()=>{ NewCombo(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Combo
                        </div>
                    </div>
                </div>
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Title</th>
                            <th width="200">Serviços</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td>{ startItens + index + 1 }</td>
                                            <td>{ key.name }</td>
                                            <td>
                                                {
                                                    key.services.map((key_1, index_1)=>{
                                                        return(
                                                            <div className="inf_serv" key={ index_1 }>
                                                                <div className="">{ key_1.name }</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ NewCombo(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data" style={ { marginTop: 0 } }>Nenhum combo encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    dataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUp_NewCombo />
        </div>
    )

}