import { useState, useEffect } from "react";

import './Services.css';

import Select from 'react-select';

import { SvgArchive, SvgArrowSearch, SvgDelete, SvgEdit, SvgFilter, SvgSearch, SvgSetaRight } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export default function Page_Services(){

    const date = new Date();

    const [ search, setSearch ]     = useState('');
    const [ dataPage, setDataPage ] = useState(GetDataPage('services'));
    const [ showData, setShowData ] = useState(InitialData(1));
    const [ disabled, setDisabled ] = useState(InitialData(0));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);

    const [ status, setStatus ]                   = useState(false);
    const [ searchCategoryV, setSearchCategoryV ] = useState('...');
    const [ searchCategoryL, setSearchCategoryL ] = useState('...');

    useEffect(()=>{
        RegisterDataPage('services', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('services'));
        setShowData(InitialData(1));
        setDisabled(InitialData(0));
    }, [dataPage]);

    function InitialData(value){
        const newData = [];
        dataPage.map((elem, index)=>{
            if(elem.status == value){                
                newData.push(elem);
            }
        });
        return newData;
    }

    function ListCategory(){
        const newData = [{ "value": "n/d", "label": "..." }];
        GetDataPage('services_category').map((elem, index)=>{            
            newData.push({ "value": elem.id, "label": elem.name });
        });
        return newData;  
    }

    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('services').forEach(item =>{
                    if(item.code.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.textBudg.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.textPres.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowData(duplicate);

        }else if(value == ''){
            setShowData(GetDataPage('services'));
        }
        setSearch(value);
        
        setStatus(false);
        setSearchCategoryV('...');
        setSearchCategoryL('...');
    }

    function SearchFilter(type){
        if(type == 0){
            const newData = [];
            {
                GetDataPage('services').forEach(item =>{
                    if(searchCategoryV !='...'){
                        item.category.map((elem, index)=>{
                            if(searchCategoryV == elem.idCategory){
                                newData.push(item.id);
                            }
                        })
                    }else {
                        newData.push(item.id);
                    }
                })
            }
            
            const showData = [];
            newData.map((elem, index)=>{
                if(GetDataPage('services').find(item => item.id == elem)){
                    showData.push(GetDataPage('services').find(item => item.id == elem));
                }
            });
            setShowData(showData);

        }else if(type == 1){
            SearchInput('');
            setStatus(false);
            setSearchCategoryV('...');
            setSearchCategoryL('...');
        }
    }

    function NewCombo(){
        SetListPag('currentPage', 'services_combo');
    }

    function NewService(id){
        SetListPag('currentPage', 'services_details');
        SetListPag('currentPageId', id);
    }

    function OpenCategory(){
        SetListPag('currentPage', 'services_category');
    }

    function Delete(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "services", "type": "delete_all", "name": value });
        SetModalState('Confirmation', true);
    }

    function NewHistoric(){
        SetModalData("Historic", { "origin": "services", "type_register": "reactivate", "type": "Serviços desativados", "data": disabled });
        SetModalState('Historic', true);
    }

    return(
        <div className="Page_Services">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Serviços
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                    <div data-tooltip-id="filter_seatch" data-tooltip-content="Filtrar pesquisa" data-tooltip-place="top" className="" onClick={ ()=>{ setStatus(true); setSearch(''); } }>
                        {
                            status == false ? 
                            <SvgFilter color="#606875" className="icons" /> :
                            <SvgArrowSearch color="#606875" className="icons" />
                        }
                    </div>
                    <Tooltip id="filter_seatch" />
                </div>
                <div className="list_opt_alt_page">
                    <div className="new_data" onClick={ ()=>{ OpenCategory() } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">Categoria</div>
                    </div>
                    <div className="new_data" onClick={ ()=>{ NewCombo() } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            combo
                        </div>
                    </div>  
                    {
                        disabled.length == 0 ? null :
                        <>
                            <div data-tooltip-id="historic_" data-tooltip-content="Clique aqui para visualizar os serviços desativados" data-tooltip-place="top" className="" onClick={ ()=>{ NewHistoric(); } }>
                                <SvgArchive color="#606875" className="icons" />
                            </div>
                            <Tooltip id="historic_" />
                        </>
                    }                  
                    <div className="new_block_text" onClick={ ()=>{ NewService(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Serviço
                        </div>
                    </div>
                </div>
            </div>

            {
                status == false ? null : 
                <div className="div_filter">
                    <div className="show_page_data" style={ { marginBottom: "20px" } }>
                        <div className="list_input_data">
                            <div className="div_input">
                                <Select className="category" defaultOptions options={ ListCategory() } onChange={ (e)=> { setSearchCategoryV(e.value); setSearchCategoryL(e.label); } } value={ { "value": searchCategoryV, "label": searchCategoryL } } placeholder="..." />
                                <span className="name_input">Categoria</span>
                            </div>
                            <div className="div_input" onClick={ ()=>{ SearchFilter(0) } }>
                                <div className="new_data_page">Pesquisar</div>                            
                            </div>
                            <div className="div_input" onClick={ ()=>{ SearchFilter(1) } }>
                                <div className="new_block_text">Limpar filtro</div>                            
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Serviço</th>
                            <th width="300">Categoria</th>
                            <th width="300">Combo</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td align="center">
                                                <div data-tooltip-id={ "code_" + key.id } data-tooltip-content="Código do serviço" data-tooltip-place="top" className="code">{ key.code }</div>
                                                <Tooltip id={ "code_" + key.id } />
                                            </td>
                                            <td>{ key.name }</td>
                                            <td style={ { verticalAlign: "baseline" } }>
                                                {
                                                    key.category.map((key_1, index_1)=>{
                                                        return(
                                                            <div className="show_combo" key={ index_1 }>
                                                                <div className="show_color" style={ { backgroundColor: key_1.color } } />
                                                                { key_1.name }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td style={ { verticalAlign: "baseline" } }>
                                                {
                                                    key.combo.map((key_1, index_1)=>{
                                                        return(
                                                            <div className="show_combo" key={ index_1 }>
                                                                { key_1.name }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div data-tooltip-id={ "delete_" + key.id } data-tooltip-content="Deletar serviço" data-tooltip-place="top" onClick={ ()=>{ Delete(key.id, key.name) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <Tooltip id={ "delete_" + key.id } />
                                                    <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Editar serviço" data-tooltip-place="top" onClick={ ()=>{ NewService(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                    <Tooltip id={ "edit_" + key.id } />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data" style={ { marginTop: 0 } }>Nenhum serviço encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}