import { useState, useEffect } from "react";

import './Report.css';

import { SvgReturn, SvgSetaRight } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Page_Report(props){

    const date = new Date();
    const [ showYear, setShowYear ]       = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);
    const [ pageClick, setPageClick ]     = useState('');

    const [ client, setClient ]       = useState(GetDataPage('client'));
    const [ qtdClient, setQtdClient ] = useState(ShowQtd('client'));

    const [ portfolio, setPortfolio ]       = useState(GetDataPage('portfolio'));
    const [ qtdPortfolio, setQtdPortfolio ] = useState(ShowQtd('portfolio'));

    const [ proposal, setProposal ]       = useState(GetDataPage('proposal'));
    const [ qtdProposal, setQtdProposal ] = useState(ShowQtd('proposal'));

    const [ budget, setBudget ]       = useState(GetDataPage('budget'));
    const [ qtdBudget, setQtdBudget ] = useState(ShowQtd('budget'));

    const [ listData, setListData ] = useState([
        {
            "open": "report_clients",
            "title": "Clientes",
            "qtd": qtdClient
        },
        {
            "open": "report_portfolio",
            "title": "Portfólio",
            "qtd": qtdPortfolio
        },
        {
            "open": "report_proposal",
            "title": "Propostas",
            "qtd": qtdProposal
        },
        {
            "open": "report_budget",
            "title": "Orçamentos",
            "qtd": qtdBudget
        }
    ]);

    useEffect(()=>{
        RegisterDataPage('client', setClient);
        RegisterDataPage('portfolio', setPortfolio);
        RegisterDataPage('proposal', setProposal);
        RegisterDataPage('budget', setBudget);
    }, []);

    useEffect(()=>{
        setQtdClient(ShowQtd('client'));
        setQtdPortfolio(ShowQtd('portfolio'));
        setQtdProposal(ShowQtd('proposal'));
        setQtdBudget(ShowQtd('budget'));
    }, [currentYear]);

    function ShowQtd(type){
        if(type == 'client'){
            const newData = client.filter(item => item.customer_since == currentYear);
            return newData.length;

        }else if(type == 'portfolio'){
            const newData = portfolio.filter(item => item.year == currentYear);
            return newData.length;

        }else if(type == 'proposal'){
            const newData = proposal.filter(item => item.year == currentYear);
            return newData.length;

        }else if(type == 'budget'){
            const newData = budget.filter(item => item.year == currentYear);
            return newData.length;
        }
    }

    return(
        <div className="Page_Report">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_fixed name_page">
                        Relatório
                    </div>
                </div>
                <div className="">
                    <div className="name_project">
                        Relatórios { currentYear }
                    </div>
                </div>
            </div>
            
            <div className="list_current_data">
                {
                    listData.map((key, index)=>{
                        return(
                            <div className="show_qtd" key={ index } onClick={ ()=>{ props.CkickPage(key.open); }}>
                                <div className="number">{ key.qtd }</div>
                                <div className="title">{ key.title }</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}