import { useState, useEffect, useRef } from "react";

import './Highlight_4.css';

export default function PreviewFixed_Highlight_4(props){

    const [ dataPage, setDataPage ] = useState(props.data);
    
    return(
        <div className="div_show_highlight_4">
            {
                dataPage.title == "" ? null :
                <div className={ "highlight_4_title align_" + dataPage.width }>{ dataPage.title }</div>
            }
            
            <div className={ "highlight_4_text_1 align_" + dataPage.width } dangerouslySetInnerHTML={ { __html: dataPage.text ? dataPage.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
            
            <div className={ "highlight_4_text_2 align_" + dataPage.width } dangerouslySetInnerHTML={ { __html: dataPage.text_1 ? dataPage.text_1.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
        </div>
    )
}