import React, { useState, useEffect } from "react";

import './NewTag.css';

import { SvgClose } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Reg_Tag } from "services/RegisterData";

export default function PopUp_NewTag(props){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ dataPage, setDataPage ]   = useState(GetDataPage('portfolio_tag'));

    const [ type, setType ] = useState('');
    const [ name, setName ] = useState('');

    useEffect(()=>{
        RegisterModalData('NewTag', setModaldata);
        RegisterModalObserver('NewTag', setShowPopUp);

        RegisterDataPage('portfolio_tag', setDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setDataPage(GetDataPage('portfolio_tag'));
            setType(InitialData('type'));            
            setName(InitialData('name'));
        }
    }, [showPopUp]);

    function InitialData(type){
        if(modalData.id != 0){
            const newData = dataPage.find(item => item.id == modalData.id);
            return newData[type];
        }
        return '';
    }

    function PopUp_SaveData(){
        setLoading(true);
        Reg_Tag(modalData.id, type, name, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        ClosePopUp();
        setType('');
        setName('');
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('NewTag', false);
    }

    return (
        (showPopUp ?
            <>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <img alt="loading" src="./assets/loading.gif" className="loading" />
                </div>
                <div className="PopUp">
                    <div className="all popup_tag">
                        <div className="div_data type_div">
                            <div className="title">
                                Tag
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="type_tag" onChange={ (e)=>{ setType(e.target.value); } } value={ type }>
                                            <option value="Público">Público</option>
                                            <option value="Privado">Privado</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    <div className="div_input space_width">
                                        <input type="text" className="space_width" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                </div>

                                <div className="register_data button_tag" onClick={ ()=>{ PopUp_SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
