import React, { useState, useEffect } from "react";

import './NewOrEditSignature.css';

import { SvgClose } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { Reg_Signature } from "services/RegisterData";
import { GetUserData, RegisterUserData } from "interface/Users";

export default function PopUp_Signature(props){

    const [ userId, setUserId ]       = useState(GetUserData('id'));
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ dataPage, setDataPage ]   = useState(GetDataPage('budget_signature'));

    const [ name, setName ]     = useState('');
    const [ file, setFile ]     = useState('');
    const [ status, setStatus ] = useState(false);

    useEffect(()=>{
        RegisterModalData('Signature', setModaldata);
        RegisterModalObserver('Signature', setShowPopUp);

        RegisterUserData('id', setUserId);
        RegisterDataPage('budget_signature', setDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setName(InitialData('name'));
            setFile(InitialData('img'));
        }
    }, [showPopUp]);

    function InitialData(type){
        if(modalData.id != 0){
            const newData = dataPage.find(item => item.id == modalData.id);
            return newData[type];
        }
        return '';
    }

    function ClosePopUp(){
        setStatus(false);
        SetModalState('Signature', false);
    }

    function SaveData(){
        Reg_Signature(userId, modalData.id, name, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
    }

    function AddFile(value){
        if(value){
            setStatus(true);
            setFile(value);
        }else {
            setStatus(false);
            setFile('');
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_signature">
                        <div className="div_data type_div">
                            <div className="title">
                                Assinatura
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data space_div">
                                    <div className="div_input space_div">
                                        <input type="text" className="space_width space_div" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input">
                                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { status == true ? "Assinatura adicionada" : "Adicionar assinatura" }
                                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    file == '' ? null :
                                    <div className="show_input">
                                        <div className="div_img">
                                            {
                                                status == true ?
                                                <img alt="signature" src={ URL.createObjectURL(file) } className="show_div_img" /> :
                                                <img alt="signature" src={ "./assets/signature/" + file } className="show_div_img" />
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="register_data button_budget space_div" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
