import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select'

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgDelete, SvgEdit, SvgSave, SvgSearch, SvgSetaRight } from "components/SvgFile";

import { Reg_NewCombo } from "services/RegisterData";

export default function Page_Services_Combo_Details(){

    const [ loading, setLoading ]           = useState(false);
    const [ showServices, setShowServices ] = useState(GetDataPage('services'));
    const [ dataPage, setDataPage ]         = useState(GetDataPage('services_combo'));
    const [ idPage, setIdPage ]             = useState(GetListPag('currentPageId'));

    const [ name, setName ]         = useState(InitialData('name'));
    const [ services, setServices ] = useState(InitialData('services'));

    useEffect(()=>{
        RegisterDataPage('services', setShowServices);
        RegisterDataPage('services_combo', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setShowServices(GetDataPage('services'));
        setDataPage(GetDataPage('services_combo'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setServices(InitialData('services'));
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage != 0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'services'){
            return [];
        }
        return '';
    }

    function AddNewServ(){
        setServices([...services, { "id": 0, "idNew": 0, "name": "Selecione uma opção", "status": false }]);
    }

    function ShowServices(){
        const options = [];
        showServices.map((key, index)=>{
            if(services.find(item => item.idNew == key.id)){ }else {
                let label = key.code + ' - ' + key.name;
                options.push({
                    "value": key.id, "label": label
                });
            }
        })
        return options;
    }

    function HandleServ(index, value){
        const newData = [...services];
        newData[index]['idNew'] = value.value;
        newData[index]['name']  = value.label;
        setServices(newData);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...services];
            newData.splice(index, 1);
            setServices(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "services_combo", "type": "delete_combo", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_NewCombo(idPage, name, services, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(type){
        SetListPag('currentPage', type);
    }

    return(
        <div className="Page_Services_Combo">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage('services') } }>
                            Serviços
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage('services_combo') } }>
                            Combo
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <div className="highlight_title_page">
                        Detalhes do combo
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do combo</div>
                        <div className="new_data_page" onClick={ ()=>{ AddNewServ(); } }>
                            <span className="icons_menu">+</span> serviço
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome do combo</span>
                        </div>
                    </div>
                    {
                        services.map((key, index)=>{
                            // { "value": key.idNew, "label": key.name }
                            return(
                                <div className="list_input_data" key={ index }>
                                    <div className="div_input space_div">
                                        <Select className="" defaultOptions options={ ShowServices() } onChange={ (e)=> { HandleServ(index, e) } } value={ { "value": key.idNew, "label": key.name } } placeholder="Serviços..." />
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex', marginTop: '5px' } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )

}