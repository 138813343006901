import { useState, useEffect } from "react";

import './GalleryOpen.css';

export default function PreviewFixed_GalleryOpen(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    useEffect(()=>{
    }, []);

    return(
        <div className="div_show_gallery_open">
            <div className="div_fixed_img">
                {
                    dataPage.gallery.map((key, index)=>{
                        if(index %3 == 0){
                            return(                            
                                <div className="div_one" key={ index }>
                                    <div className="div_img">
                                        <div className={ "div_effect_img type_align_img_" + key.width } data-animationimage="image" style={ { backgroundColor: props.color } } />
                                        <img alt="" src={ "./assets/portfolio/gallery/" + key.file } className="show_img" />
                                    </div>
                                </div>
                            )
                        }else if(index %3 == 1){
                            return(
                                <div className="div_two" key={ index }>
                                    <div className="div_img">
                                        <div className={ "div_effect_img type_align_img_" + key.width } data-animationimage="image" style={ { backgroundColor: props.color } } />
                                        <img alt="" src={ "./assets/portfolio/gallery/" + key.file } className="show_img" />
                                    </div>
                                    {
                                        dataPage.gallery.length > index + 1 ?
                                        <div className="div_img">
                                            <div className={ "div_effect_img type_align_img_" + key.width } data-animationimage="image" style={ { backgroundColor: props.color } } />
                                            <img alt="" src={ "./assets/portfolio/gallery/" + dataPage.gallery[index + 1].file } className="show_img" />
                                        </div> : null
                                    }
                                </div>
                            )
                        }else {
                        }
                    })
                }
            </div>
        </div>
    )
}