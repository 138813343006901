import React, { useState, useEffect } from "react";

import './StatusBudget.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgClose } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";
import { Reg_AltStatusBudget } from "services/RegisterData";
import { GetUserData, RegisterUserData } from "interface/Users";

export default function PopUp_StatusBudget(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ idUser, setIdUser ] = useState(GetUserData("id"));
    const [ dataPage, setDataPage ] = useState(GetDataPage('budget'));
    const [ showStatus, setShowStatus ] = useState(GetDataPage('budget_status'));

    const [ idBudget, setIdBudget ] = useState('');
    const [ code, setCode ] = useState('');
    const [ status, setStatus ] = useState('');
    const [ dateStatus, setDateStatus ] = useState('');
    const [ color, setColor ] = useState('');
    const [ newStatus, setNewStatus ] = useState('');
    const [ currentStatus, setCurrentStatus ] = useState('');

    const [ motive, setMotive ] = useState({ "value": "Não faturado", "label": "Não faturado" });
    const [ newMotive, setNewMotive ] = useState('');
    const [ obsMotive, setObsMotive ] = useState('');
    const [ billed, setBilled ] = useState('Não faturado');

    function ShowOptCancel(){
        const newData = []
        dataPage.map((key, index)=>{
            if(key.status === 'Cancelado'){
                if(key.motive !=''){
                    newData.push(key.motive);
                }
            }
        });        
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);

        const options = [{ "value": "add", "label": "+ novo motivo" }];
        duplicate.map((key, index)=>{
            options.push({
                "value": key, "label": key
            });
        })
        return options;
    }

    function OpenColor(){
        SetModalData('Color', { "setColorPage": setColor });
        SetModalState('Color', true);
    }

    function SaveData(){
        Reg_AltStatusBudget(idBudget, idUser, code, status, dateStatus, newStatus, color, currentStatus, motive, newMotive, obsMotive, billed, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setIdBudget('');
        setCode('');
        setStatus('');
        setDateStatus('');
        setNewStatus('');
        setCurrentStatus('');
        
        setMotive({ "value": "Não faturado", "label": "Não faturado" });
        setNewMotive('');
        setObsMotive('');
        setBilled('Não faturado');
        ClosePopUp();
    }

    function CallbackError(){
        setIdBudget('');
        setCode('');
        setStatus('');
        setDateStatus('');
        setNewStatus('');
        setCurrentStatus('');
        
        setMotive({ "value": "Não faturado", "label": "Não faturado" });
        setNewMotive('');
        setObsMotive('');
        setBilled('Não faturado');
        ClosePopUp();
    }

    function ClosePopUp(){
        setIdBudget('');
        setCode('');
        setStatus('');
        setDateStatus('');
        setNewStatus('');
        setCurrentStatus('');

        setMotive({ "value": "Não faturado", "label": "Não faturado" });
        setNewMotive('');
        setObsMotive('');
        setBilled('Não faturado');
        
        SetModalState('StatusBudget', false);
    }

    useEffect(()=>{
        RegisterModalData('StatusBudget', setModaldata);
        RegisterModalObserver('StatusBudget', setShowPopUp);

        RegisterDataPage('budget_status', setShowStatus);
        RegisterUserData('id', setIdUser);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setIdBudget(modalData.id);
            setCode(modalData.code);
            setCurrentStatus(modalData.status);
            setStatus(modalData.status)
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_status_budget">
                        <div className="div_data type_div">
                            <div className="title">
                                Status do orçamento - { code }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data space_div">
                                    <div className="div_input space_div">
                                        <select className="select_status space_div" onChange={ (e)=>{ setStatus(e.target.value) } } value={ status }>
                                            <option value="newStatus">Novo status</option>
                                            {
                                                showStatus.map((key, index)=>{
                                                    return(
                                                        <option value={ key.id } key={ index }>{ key.name }</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <span className="name_input">Status</span>
                                    </div>
                                    {
                                        status == 1 ? 
                                        <div className="div_input space_div">
                                            <select className="select_status space_div" onChange={ (e)=>{ setBilled(e.target.value) } } value={ billed }>
                                                <option value="Não faturado">Não faturado</option>
                                                <option value="Faturado">Faturado</option>
                                            </select>
                                            <span className="name_input">Faturamento</span>
                                        </div> : null
                                    }
                                    {
                                        status == 1 || status == 2 || status == 12 ? 
                                        <div className="div_input">
                                            <input type="date" className="date_space" value={ dateStatus } onChange={ (e)=>{ setDateStatus(e.target.value) } } required />
                                            <span className="name_input">Data status</span>
                                        </div>
                                        : null
                                    }
                                </div>
                                {
                                    status != "newStatus" ? null :
                                    <div className="list_input_data space_div">
                                        <div className="div_input space_div">
                                            <input type="text" className="space_div" onChange={ (e)=>{ setNewStatus(e.target.value) } } value={ newStatus } maxLength="60" required />
                                            <span className="name_input">Novo status</span>
                                        </div>                            
                                        <div className="div_input" onClick={ ()=>{ OpenColor() } } style={ { width: "auto" } }>
                                            <div className="div_color" style={ { backgroundColor: color } } />
                                            <span className="name_input">Cor do texto</span>
                                        </div>
                                    </div>
                                }

                                {
                                    status == 2 || status == 12 ? 
                                    <>                                            
                                        <div className="list_input_data space_div">
                                            <div className="div_input space_div">
                                                <Select className="select_status space_div" defaultOptions options={ ShowOptCancel() } onChange={ (e)=> { setMotive(e) } } value={ motive } placeholder="..." />
                                                <span className="name_input">Motivo abreviado</span>
                                            </div>
                                        </div>
                                        {
                                            motive.value != 'add' ? null :
                                            <div className="list_input_data space_div">
                                                <div className="div_input space_div">
                                                    <input type="text" className="space_div" onChange={ (e)=>{ setNewMotive(e.target.value) } } value={ newMotive } maxLength="60" required />
                                                    <span className="name_input">Nome do novo motivo abreviado</span>
                                                </div>
                                            </div>
                                        }
                                        <div className="list_input_data space_div">
                                            <div className="div_input space_div">
                                                <JoditEditor config={ config } value={ obsMotive ? obsMotive.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObsMotive(newContent) } />
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }
                                
                                <div className="register_data button_category space_div" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
