import React, { useState, useEffect } from "react";

import './NewPortfolio.css';

import { SvgBehance, SvgCircleNoSelected, SvgCircleSelected, SvgClose, SvgDelete, SvgEye, SvgGoogleDrive, SvgLockClose } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Reg_AddPortifolioProposal } from "services/RegisterData";
import { RegisterUserData, GetUserData } from "interface/Users";

export default function PopUP_NewPortfolio(props){

    const [ userId, setUserId ]       = useState(GetUserData('id'));
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ showPage, setShowPage ]         = useState([]);
    const [ addPortfolio, setAddPortfolio ] = useState([]);
    
    const [ tag, setTag ]         = useState('all');
    const [ search, setSearch ]   = useState('');
    const [ listTag, setListTag ] = useState(ShowTag());
    
    const [ dup_0, setDup_0 ] = useState([]);
    const [ dup_1, setDup_1 ] = useState([]);
    const [ dup_2, setDup_2 ] = useState([]);
    const [ dup_3, setDup_3 ] = useState([]);

    function InitialData(){
        const newData = [];
        GetDataPage('portfolio').map((elem, index)=>{
            if(modalData.portfolio.find(item => item.id_project == elem.id)){ }else {
                newData.push(elem);
            }
        });
        return newData;
    }

    function SearchInput(value){
        let newList_0 = []; let newList_1 = [];
        let newList_2 = []; let newList_3 = [];

        let valeu_sep = value.toLowerCase().split(' ');
        if(value){
            if(valeu_sep.length == 1){
                GetDataPage('portfolio').map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[0]) != -1){
                        newList_0.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[0]) != -1){
                        newList_0.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[0]) != -1){
                        newList_0.push(key);
                    }
                });
                setDup_0(newList_0.filter((item, index) => newList_0.indexOf(item) == index));
                setTimeout(() => {
                    setShowPage(newList_0.filter((item, index) => newList_0.indexOf(item) == index));
                }, 100);

            }
            if(valeu_sep.length == 2){
                dup_0.map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[1]) != -1){
                        newList_1.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[1]) != -1){
                        newList_1.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[1]) != -1){
                        newList_1.push(key);
                    }
                });
                setDup_1(newList_1.filter((item, index) => newList_1.indexOf(item) == index));
                setTimeout(() => {
                    setShowPage(newList_1.filter((item, index) => newList_1.indexOf(item) == index));
                }, 100);
            }
            if(valeu_sep.length == 3){
                dup_1.map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_2.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_2.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[2]) != -1){
                        newList_2.push(key);
                    }
                });
                setDup_2(newList_2.filter((item, index) => newList_2.indexOf(item) == index));
                setTimeout(() => {
                    setShowPage(newList_2.filter((item, index) => newList_2.indexOf(item) == index));
                }, 100);
            }
            if(valeu_sep.length == 4){
                dup_2.map((key, index)=>{
                    // client
                    if(key.name_client.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_3.push(key);
                    }

                    // project
                    if(key.project.toLowerCase().indexOf(valeu_sep[2]) != -1){
                        newList_3.push(key);
                    }

                    // year
                    if(key.year.indexOf(valeu_sep[2]) != -1){
                        newList_3.push(key);
                    }
                });
                setDup_3(newList_3.filter((item, index) => newList_3.indexOf(item) == index));
                setTimeout(() => {
                    setShowPage(newList_3.filter((item, index) => newList_3.indexOf(item) == index));
                }, 100);
            }

        }else {
            setShowPage(GetDataPage("portfolio"));
        }
        setSearch(value);
    } 

    function SearchTag(value){
        const newList = [];
        if(value){
            if(value == 'all'){
                setShowPage(GetDataPage("portfolio"));
                setTag('all');
            }else {
                GetDataPage("portfolio").forEach(item =>{
                    item.tag.map((key, index)=>{
                        if(key.name == value){
                            newList.push(item);
                        }
                    })
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setShowPage(duplicate);
                setTag(value);
            }
        }else {
            setShowPage(GetDataPage("portfolio"));
            setTag('all');
        }
    }

    function ShowTag(){
        const options = [];
        GetDataPage("portfolio").forEach(item =>{
            item.tag.map((key_1, index_1)=>{
                options.push(key_1.name);
            })
        });
        const duplicate = options.filter((item, index) => options.indexOf(item) === index);
        return duplicate;
    }

    function OpenPortfolio(id){
        window.open(process.env.REACT_APP_API_URL + "?project=portfolio/" + id, '_blank');
    }

    function HandleData(id, index, status){
        const dataPortfolio = [...showPage];
        dataPortfolio[index].status = status;
        setShowPage(dataPortfolio);

        const newData = [...addPortfolio];        
        if(newData.find(item => item == id)){
            if(status == false){
                let indexPf = newData.findIndex(e => (e, id));
                newData.splice(indexPf, 1);
                setAddPortfolio(newData);    

                modalData.setNewPortfolio(newData);

            }
        }else {
            newData.push(id);
            setAddPortfolio(newData);
            modalData.setNewPortfolio(newData);
        }
    }

    function ShowFileCover(type_file, file){
        switch (type_file) {
            case 'Vimeo':
                let linkVimeo = file.split('/');
                return(
                    <iframe className="portfolio_img" src={ "https://player.vimeo.com/video/" + linkVimeo[3] + "?autoplay=1&loop=1&title=0&byline=0&portrait=0&loop=1&controls=0" } frameBorder="0" allowFullScreen />
                )

            case 'Youtube':
                let linkYoutube = file.split('=');
                return(
                    <iframe className="portfolio_img" src={ "https://www.youtube.com/embed/" + linkYoutube[1] + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + linkYoutube[1] + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen />
                )
        }
    }

    function ShowText(text){
        if(text.length > 38){
            return text.substring(0, 38).replaceAll('<br><br>', '<br>') + "...";
        }
        return text;
    }

    function SaveData(){
        // modalData.setNewPortfolio(addPortfolio);
        ClosePopUp()
        // Reg_AddPortifolioProposal(userId, modalData.id, addPortfolio, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
        
        ClosePopUp();
    }

    function CallbackError(){
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
        
        ClosePopUp();
    }

    function ClosePopUp(){
        setShowPage(InitialData());
        setAddPortfolio([]);
        setTag('all');
        setSearch('');
        setListTag(ShowTag());

        setDup_0([]);
        setDup_1([]);
        setDup_2([]);
        setDup_3([]);

        SetModalState('NewPortfolio', false);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterModalData('NewPortfolio', setModaldata);
        RegisterModalObserver('NewPortfolio', setShowPopUp);

        RegisterDataPage("portfolio", setShowPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setShowPage(InitialData());
            setAddPortfolio([]);
            setTag('all');
            setSearch('');
            setListTag(ShowTag());

            setDup_0([]);
            setDup_1([]);
            setDup_2([]);
            setDup_3([]);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all new_portfolio">
                        <div className="div_data" style={ { paddingBottom: 10 } }>
                            <div className="title">
                                Novo portfólio
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="show_page_data" style={ { marginTop: 0, padding: 0 } }>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <input type="text" className="input_search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } placeholder="buscar..." />
                                        <span className="name_input">Pesquisa</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <select className="space_div" onChange={ (e)=>{ SearchTag(e.target.value) } } value={ tag }>
                                            <option value="all">#</option>
                                            {
                                                listTag.map((elem, index)=>{
                                                    return(
                                                        <option value={ elem } key={ index }>{ elem }</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <span className="name_input">Tag</span>
                                    </div>
                                </div>
                                
                                <div className="list_portfolio">
                                    {
                                        showPage.map((elem, index)=>{
                                            let namePortfolio = elem.project + " - " + elem.year;
                                            return(                                                
                                                <div className="div_portfolio" key={ index }>
                                                    <Tooltip className="Tooltip" id={ "port_" + elem.id } />
                                                    <div className="div_click">
                                                        <div className="show_portfolio" onClick={ ()=>{ OpenPortfolio(elem.url) } }>
                                                            <SvgEye className="icons" color="#2D3C53" />
                                                        </div>
                                                        <div className="add_portfolio">
                                                            <input type="checkbox" className="" value="true" onChange={ (e)=>{ HandleData(elem.id, index, !elem.status) } } checked={ elem.status } />
                                                        </div>
                                                    </div>
                                                    <div className="div_title" data-tooltip-id={ "port_" + elem.id } data-tooltip-content={ namePortfolio } data-tooltip-place="top">
                                                        {
                                                            ShowText(namePortfolio.replaceAll('&#34;', '"'))
                                                        }
                                                    </div>
                                                    {
                                                        elem.restricted == false ? null : 
                                                        <div className="div_restricted">
                                                            <SvgLockClose color="#FFFFFF" className="lock" />
                                                        </div>
                                                    }
                                                    <div className="div_bg">
                                                        {
                                                            elem.type_file == 'Image' ?
                                                            <img alt="show img" src={ elem.file != '' ? "./assets/portfolio/cover/thumbnail/thumbnail_" + elem.file : "./assets/not_file.jpg" } className="portfolio_img" />
                                                            : 
                                                            elem.type_file == 'Color' ? 
                                                            <div className="color_bg" style={ { backgroundColor: elem.color_bg } }>Cor no fundo</div> 
                                                            : ShowFileCover(elem.type_file, elem.file)
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <button className="register_data space_contact">
                                    Adicionar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
