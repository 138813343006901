import { useState, useEffect } from "react";

import './Tasks.css';

import { SvgDelete, SvgEdit, SvgEye, SvgSetaRight } from "components/SvgFile";

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "moment/locale/pt-br";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PopUP_DetailsTasks from "components/PopUp/DetailsTasks";

import { SetListPag } from "interface/Page";
import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

const localizer = momentLocalizer(moment);

export default function Page_Tasks(props){

    const dateAtual = new Date();
    const [ loading, setLoading ]       = useState(false);
    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ allTasks, setAllTasks ]     = useState(GetDataPage('list_tasks'));    
    const [ listTasks, setListTasks ]   = useState(GeneratorDataTasks());   
    const [ tasksAtual, setTasksAtual ] = useState(ShowDataTasks());

    const views = { month: true, week: true, day: true, agenda: false }
    const defaultMessages = {
        date: "Data",
        time: "Hora",
        event: "Evento",
        allDay: "Dia Todo",
        week: "Semana",
        work_week: "Eventos",
        day: "Dia",
        month: "Mês",
        previous: "Anterior",
        next: "Próximo",
        yesterday: "Ontem",
        tomorrow: "Amanhã",
        today: "Hoje",
        agenda: "Agenda",
        noEventsInRange: "Não há tarefas nesse período.",
        showMore: function showMore(total) {
            return "+" + total + " tarefas";
        }
    };

    function GeneratorDataTasks(){
        const newData = [];
        allTasks.map((elem, index)=>{
            elem.start = new Date(elem.start);
            elem.end   = new Date(elem.end);

            elem.responsible.map((elem_1, index_1)=>{
                if(elem_1.id_responsible == userId){
                    newData.push(elem);
                }
                if(elem_1.id_responsible == 0){
                    newData.push(elem);
                }
            })
        })
        return newData;
    }

    function ShowDataTasks(){
        let dateYear  = dateAtual.getUTCFullYear();
        let dateMonth = dateAtual.getUTCMonth() + 1;
        dateMonth     = dateMonth > 9 ? dateMonth : "0" + dateMonth;
        let dateDay   = dateAtual.getUTCDate();
        dateDay       = dateDay > 9 ? dateDay : "0" + dateDay;
        let dateCompl = dateYear + '-' + dateMonth + '-' + dateDay; 

        const newData = [];
        allTasks.map((elem, index)=>{
            if(dateCompl >= elem.start_only && dateCompl <= elem.end_only){
                elem.responsible.map((elem_1, index_1)=>{
                    if(elem_1.id_responsible == userId){
                        newData.push(elem);
                    }
                    if(elem_1.id_responsible == 0){
                        newData.push(elem);
                    }
                })
            }
        });
        return newData;
    }
    
    function NewTasks(elem){
        let newDateYear  = elem.start.getUTCFullYear();
        let newDateMonth = elem.start.getUTCMonth() + 1;
        newDateMonth     = newDateMonth > 9 ? newDateMonth : "0" + newDateMonth;
        let newDateDay   = elem.start.getUTCDate();
        newDateDay       = newDateDay > 9 ? newDateDay : "0" + newDateDay;
        const newDate   = newDateYear + '-' + newDateMonth  + '-' + newDateDay;
        const newDateBr = newDateDay + '/' + newDateMonth  + '/' + newDateYear;

        SetModalData('DetailsTasks', { "id": 0, "type": "new_tasks", "title": "Adicionar uma nova tarefa", "start": newDate, "start_br": newDateBr });
        SetModalState('DetailsTasks', true);
    }

    function CheckingPendingTasks(){
        let dateYear  = dateAtual.getUTCFullYear();
        let dateMonth = dateAtual.getUTCMonth() + 1;
        dateMonth     = dateMonth > 9 ? dateMonth : "0" + dateMonth;
        let dateDay   = dateAtual.getUTCDate();
        dateDay       = dateDay > 9 ? dateDay : "0" + dateDay;
        let dateCompl = dateYear + '-' + dateMonth + '-' + dateDay; 

        let newData = false;
        allTasks.map((elem, index)=>{
            if(elem.status == "Aguardando"){
                if(dateCompl == elem.end_only){
                    elem.responsible.map((elem_1, index_1)=>{
                        if(elem_1.id_responsible == userId){
                            newData = true;
                        }
                        if(elem_1.id_responsible == 0){
                            newData = true;
                        }
                    })
                }
            }
        });
        setTimeout(() => {
            if(newData){
                SetModalData('ReturnResponse', { "page": "missingData", "text": "Você tem tarefas que tem o prazo final para hoje, caso for necessário reagende elas..." });
                SetModalState('ReturnResponse', true);
            }
        }, 300);
    }

    function OpenClik(type, id){
        SetListPag('currentPage', type + '_details');
        SetListPag('currentPageId', id);
    }

    function DetailsTasks(elem){
        SetModalData('DetailsTasks', elem);
        SetModalState('DetailsTasks', true);
    }

    function DeleteTasks(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "list_tasks", "type": "delete_date", "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('list_tasks', setAllTasks);

        CheckingPendingTasks();
    }, []);

    useEffect(()=>{
        setListTasks(GeneratorDataTasks());
        setTasksAtual(ShowDataTasks());
    }, [allTasks]);

    return(
        <div className="Page_Tasks">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Tarefas
                    </div>
                </div>
            </div>

            <div className="div_color_legend">
                <div className="color_legend">
                    <div className="color" style={ { background: "#f00000" } } />
                    <div className="text">Aviso</div>
                </div>
                <div className="color_legend">
                    <div className="color" style={ { background: "#A020F0 " } } />
                    <div className="text">Comercial</div>
                </div>
                <div className="color_legend">
                    <div className="color" style={ { background: "#2D3C53" } } />
                    <div className="text">Geral</div>
                </div>
                <div className="color_legend">
                    <div className="color" style={ { background: "#ffc400" } } />
                    <div className="text">Portfólios</div>
                </div>
                <div className="color_legend">
                    <div className="color" style={ { background: "#00f000" } } />
                    <div className="text">Propostas</div>
                </div>
                <div className="color_legend">
                    <div className="color" style={ { background: "#0000ff" } } />
                    <div className="text">Orçamentos</div>
                </div>
            </div>

            <div className="show_page_data div_calendar">
                <Calendar
                    views={ views }
                    messages={ defaultMessages }
                    localizer={ localizer }
                    events={ listTasks }
                    eventPropGetter={(listTasks) => {
                        const backgroundColor = listTasks.colorEvento ? listTasks.colorEvento : 'blue';
                        const color = listTasks.color ? listTasks.color : 'blue';
                        return { style: { backgroundColor, color} }
                    }}
                    
                    selectable={ true }
                    onSelectSlot={ NewTasks }
                    onSelectEvent={ DetailsTasks }
                />
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">
                        Tarefas para hoje
                    </div>
                </div>
                <div className="">
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th width="20" align="center">#</th>
                                <th width="120">Responsáveis</th>
                                <th width="80">Origem</th>
                                <th width="80">Início</th>
                                <th width="80">Término</th>
                                <th>Título</th>
                                <th width="20" align="right">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tasksAtual.map((elem, index)=>{
                                    let origem    = '';
                                    let clickOpen = '';
                                    switch (elem.type) {
                                        case "alert":
                                                origem = 'Alerta';
                                            break;
                                        case "general":
                                                origem = 'Geral';
                                            break;
                                        case "portfolio":
                                                origem    = 'Portfólio';
                                                clickOpen = "portfolio";
                                            break;
                                        case "proposal":
                                                origem = 'Proposta';
                                                clickOpen = "proposal";
                                            break;
                                        case "budget":
                                                origem = 'Orçamento';
                                                clickOpen = "budget";
                                            break;
                                    }

                                    return(
                                        <tr key={ index }>
                                            <td>{ index + 1 }</td>
                                            <td>
                                                {
                                                    elem.responsible.map((elem_1, index_1)=>{
                                                        return(
                                                            <div className="" key={ index_1 }>
                                                                { elem_1.name }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td>{ origem }</td>
                                            <td>{ elem.start_br }</td>
                                            <td>{ elem.end_br }</td>
                                            <td>{ elem.title }</td>
                                            <td>
                                                <div className="list_opt">
                                                    {
                                                        clickOpen !=0 ?
                                                        <>                                                        
                                                            <div data-tooltip-id={ "show_data_" + elem.id } data-tooltip-content={ "Clique aqui para visualiar o " + origem.toLowerCase() } data-tooltip-place="top" onClick={ ()=>{ OpenClik(clickOpen, elem.id_given) } }>
                                                                <SvgEye color="#324d6b" className="icons"/>
                                                            </div>
                                                            <Tooltip id={ "show_data_" + elem.id } />
                                                        </>
                                                        : null
                                                    }
                                                    <div data-tooltip-id={ "delete_" + elem.id } data-tooltip-content="Deletar serviço" data-tooltip-place="top" onClick={ ()=>{ DeleteTasks(elem.id, elem.title) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <Tooltip id={ "delete_" + elem.id } />
                                                    <div data-tooltip-id={ "edit_" + elem.id } data-tooltip-content="Editar tarefa" data-tooltip-place="top" onClick={ ()=>{ DetailsTasks(elem) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                    <Tooltip id={ "edit_" + elem.id } />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <PopUP_DetailsTasks />
        </div>
    )
}