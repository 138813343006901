const ModalState = {
    Confirmation    : false,
    Profile         : false,
    ReturnResponse  : false,
    PhotoCrop       : false,
    ShowFile        : false,
    NewContact      : false,
    NewTag          : false,
    SaveBudget      : false,
    HistoricBudget  : false,
    Historic        : false,
    Color           : false,
    Tasks           : false,
    SalesUpdates    : false,
    SendEmail       : false,
    AltOrderTable   : false,
    DetailsTasks    : false,
    AltStatusTasks  : false,
    ListBudget : false,


    NewClient       : false,
    NewCategory     : false,
    NewGroup        : false,
    NewCombo        : false,
    NewPortfolio    : false,
    Signature       : false,
    TextFixed       : false,
    ShowData        : false,
    ShowPortfolio   : false,
    StatusBudget    : false
};

const ModalData = {
    Confirmation    : {},
    Profile         : {},
    ReturnResponse  : {},
    PhotoCrop       : {},
    ShowFile        : {},
    NewContact      : {},
    NewTag          : {},
    SaveBudget      : {},
    HistoricBudget  : {},
    Historic        : {},
    Color           : {},
    Tasks           : {},
    SalesUpdates    : {},
    SendEmail       : {},
    AltOrderTable   : {},
    DetailsTasks    : {},
    AltStatusTasks  : {},
    ListBudget : {},


    NewClient       : {},
    NewCategory     : {},
    NewGroup        : {},
    NewCombo        : {},
    NewPortfolio    : {},
    Signature       : {},
    TextFixed       : {},
    ShowData        : {},
    ShowPortfolio   : {},
    StatusBudget    : {}
}

const NotifyModalObserver = {
    Confirmation    : [],
    Profile         : [],
    ReturnResponse  : [],
    PhotoCrop       : [],
    ShowFile        : [],
    NewContact      : [],
    NewTag          : [],
    SaveBudget      : [],
    HistoricBudget  : [],
    Historic        : [],
    Color           : [],
    Tasks           : [],
    SalesUpdates    : [],
    SendEmail       : [],
    AltOrderTable   : [],
    DetailsTasks    : [],
    AltStatusTasks  : [],
    ListBudget : [],


    NewClient       : [],
    NewCategory     : [],
    NewGroup        : [],
    NewCombo        : [],
    NewPortfolio    : [],
    Signature       : [],
    TextFixed       : [],
    ShowData        : [],
    ShowPortfolio   : [],
    StatusBudget    : []
}

const NotifyModalData = {
    Confirmation    : [],
    Profile         : [],
    ReturnResponse  : [],
    PhotoCrop       : [],
    ShowFile        : [],
    NewContact      : [],
    NewTag          : [],
    SaveBudget      : [],
    HistoricBudget  : [],
    Historic        : [],
    Color           : [],
    Tasks           : [],
    SalesUpdates    : [],
    SendEmail       : [],
    AltOrderTable   : [],
    DetailsTasks    : [],
    AltStatusTasks  : [],
    ListBudget : [],


    NewClient       : [],
    NewCategory     : [],
    NewGroup        : [],
    NewCombo        : [],
    NewPortfolio    : [],
    Signature       : [],
    TextFixed       : [],
    ShowData        : [],
    ShowPortfolio   : [],
    StatusBudget    : []
}

// ModalData
export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}
export function GetModalData(key, value){
    return ModalData[key][value];
}
export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}

// ModalState
export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}
export function GetModalState(key, value){
    return ModalState[key][value];
}
export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}
