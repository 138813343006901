import React, { useState, useEffect } from "react";

import './ShowFilePortfolio.css';
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";
import { SvgClose, SvgEye } from "components/SvgFile";

export default function PopUP_ShowPortfolio(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ portfolio, setPortfolio ] = useState([]);

    useEffect(()=>{
        RegisterModalData('ShowPortfolio', setModaldata);
        RegisterModalObserver('ShowPortfolio', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            document.body.style = "overflow-y: hidden;";
            setPortfolio(modalData.data);
        }
    }, [showPopUp]);

    function ClosePopUp(){
        document.body.style="overflow-y: auto;";
        SetModalState('ShowPortfolio', false);
    }

    function OpenPortifolio(id){
        window.open(process.env.REACT_APP_API_URL + '?project=portfolio/' + id, '_blank');
    }

    function ShowFileCover(type_file, file){
        switch (type_file) {
            case 'Vimeo':
                let linkVimeo = file.split('/');
                return(
                    <iframe className="project_data_img" src={ "https://player.vimeo.com/video/" + linkVimeo[3] + "?autoplay=1&loop=1&title=0&byline=0&portrait=0&loop=1&controls=0" } frameBorder="0" allowFullScreen />
                )

            case 'Youtube':
                let linkYoutube = file.split('=');
                return(
                    <iframe className="project_data_img" src={ "https://www.youtube.com/embed/" + linkYoutube[1] + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + linkYoutube[1] + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen />
                )
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all show_portfolio">
                        <div className="div_data">
                            <div className="title">Lista dos portfólios</div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content list_portfolio_new_proposal list_portfolio">
                                {
                                    portfolio.map((key, index)=>{
                                        return(
                                            <div className="div_project_data" key={ index }>
                                                <div className="project_data_inf">
                                                    <div className="project_data_show_opt" onClick={ ()=>{ OpenPortifolio(key.id) } }>
                                                        Visualizar portfólio
                                                    </div>
                                                </div>
                                                <div className="project_data_div_img">
                                                    {
                                                        key.type_file == 'Image' ?
                                                        <img alt="show img" src={ key.file != '' ? "./assets/portfolio/cover/thumbnail/thumbnail_" + key.file : "./assets/not_file.jpg" } className="project_data_img" />
                                                        : key.type_file == 'Color' ? <div className="color_bg" style={ { backgroundColor: key.cover_color } }>Cor no fundo</div> : ShowFileCover(key.type_file, key.file)
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}