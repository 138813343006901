import { useState, useEffect, useRef } from "react";

import './Budget.css';

import { motion } from "framer-motion";

import { SvgEye, SvgReturn, SvgSetaRight } from "components/SvgFile";

import { SetListPag } from "interface/Page";

import { monthList } from "fixedData";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import PopUP_ListBudget from "components/PopUp/ListBudget";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Report_Budget(){
    
    const [ budget, setBudget ] = useState(GetDataPage('report_budget'));

    const carousel_1 = useRef();
    const carousel_2 = useRef();
    const carousel_3 = useRef();
    const carousel_4 = useRef();
    const date = new Date();

    const [ loading, setLoading ] = useState(false);
    const [ width_1, setWidth_1 ] = useState(0);
    const [ width_2, setWidth_2 ] = useState(0);
    const [ width_3, setWidth_3 ] = useState(0);
    const [ width_4, setWidth_4 ] = useState(0);
    const [ showYear, setShowYear ] = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);


    const [ budgetAll, setBudgetAll ] = useState(InitialData('report'));
    const [ budgetYear, setBudgetYear ] = useState(InitialData('budget'));
    
    const [ budgetApproved, setBudgetApproved ] = useState(ShowDataSpecific('budget_approved'));
    const [ budgetQtdApproved, setBudgetQtdApproved ] = useState(ShowDataSpecific('qtd_approved'));
    const [ budgetCanceled, setBudgetCanceled ] = useState(ShowDataSpecific('budget_canceled'));
    const [ budgetQtdCanceled, setBudgetQtdCanceled ] = useState(ShowDataSpecific('qtd_canceled'));
    const [ budgetChance, setBudgetChance ] = useState(ShowDataSpecific('budget_chance'));

    let TotalQtdOpportunities = 0;
    let TotalQtdBudgetSent    = 0;
    let TotalQtdLost          = 0;
    let TotalQtdConquered     = 0;
    let TotalQtdWaitingReply  = 0;

    let TotalCashConversion     = "0";
    let TotalCashBudgetedAmount = 0;
    let TotalCashLost           = 0.00;
    let TotalCashConquered      = 0.00;
    let TotalCashConverted      = "0";

    function InitialData(type){
        if(type == 'report'){
            if(budget.find(item => item.year == currentYear)){
                const newData = budget.find(item => item.year == currentYear);
                return newData.list;
            }
            return [];
        }
        
        if(type == 'budget'){
            if(GetDataPage('budget').filter(item => item.year == currentYear)){
                const newData = GetDataPage('budget').filter(item => item.year == currentYear);
                return newData;
            }
            return [];
        }
    }
    
    function ShowDataSpecific(type){
        if(GetDataPage('budget').filter(item => item.year == currentYear)){
            const newData = GetDataPage('budget').filter(item => item.year == currentYear);

            // Qtd approved
            if(type == 'qtd_approved'){
                if(newData.filter(item => item.status == 1)){
                    return newData.filter(item => item.status == 1);
                }
                return [];
            }
            // Approved
            if(type == 'budget_approved'){
                const budgetApproved = [];
                newData.map((elem, index)=>{
                    if(elem.status == 1){
                        if(budgetApproved.find(item => item.idClient == elem.idClient)){ }else {
                            budgetApproved.push(elem);
                        }
                    }
                });
                return budgetApproved;
            }

            // Qtd canceled
            if(type == 'qtd_canceled'){
                const budgetCanceled = [];
                newData.map((elem, index)=>{
                    if(elem.status == 2 || elem.status == 12){
                        budgetCanceled.push(elem);
                    }
                });
                return budgetCanceled;
            }
            // Canceled
            if(type == 'budget_canceled'){
                const budgetCanceled = [];
                newData.map((elem, index)=>{
                    if(elem.status == 2 || elem.status == 12){
                        if(budgetCanceled.find(item => item.motive == elem.motive)){ }else {
                            budgetCanceled.push(elem);
                        }
                    }
                });
                return budgetCanceled;
            }

            // Chance
            if(type == 'budget_chance'){
                const budgetChance = [];
                newData.map((elem, index)=>{
                    if(elem.status != 1 || elem.status != 2 || elem.status != 12){
                        if(elem.chance_close == 1){
                            if(budgetChance.find(item => item.id == elem.id)){ }else {
                                budgetChance.push(elem);
                            }
                        }
                    }
                });
                return budgetChance;
            }
        }
        return [];
    }

    function ReturnPage(){
        SetListPag('currentPage', 'report');
        SetListPag('currentPageId', 'remuve');
    }

    function formatNumber(number) {
        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        let cashTotal = formatter.format(number); 
        return cashTotal;
    }

    function cashConversion(cash, total) {
        let cashTotal = (cash * 100) / total;
        cashTotal = cashTotal.toString();
        return cashTotal.substring(0, 4);
    }

    function OpenBudget(type, title, list){
        if(list.length > 0){
            SetModalData('ListBudget', { "type": type, "title": title, "list": list });
            SetModalState('ListBudget', true);
        }
    }

    useEffect(()=>{
        RegisterDataPage('report_budget', setBudget);
    }, []);

    useEffect(()=>{
        setWidth_1(carousel_1.current?.scrollWidth - carousel_1.current?.offsetWidth);
        setWidth_2(carousel_2.current?.scrollWidth - carousel_2.current?.offsetWidth);
        setWidth_3(carousel_3.current?.scrollWidth - carousel_3.current?.offsetWidth);
        setWidth_4(carousel_4.current?.scrollWidth - carousel_4.current?.offsetWidth);

        setBudgetAll(InitialData('report'));
        setBudgetYear(InitialData('budget'));
        setBudgetApproved(ShowDataSpecific('budget_approved'));
        setBudgetQtdApproved(ShowDataSpecific('qtd_approved'));
        setBudgetCanceled(ShowDataSpecific('budget_canceled'));
        setBudgetQtdCanceled(ShowDataSpecific('qtd_canceled'));
        setBudgetChance(ShowDataSpecific('budget_chance'));
    }, [currentYear]);
    
    return(
        <div className="Page_Report_Budget">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>

            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                        Relatório
                    </div>
                    <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Detalhes
                    </div>
                </div>
                <div className="">
                    <div className="name_project">
                        Orçamentos
                    </div>
                </div>
            </div>
            
            <div className="list_opt_year">
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <SvgReturn color="#324d6b" className="icons" />
                </div>
                <div className="name_project">
                    { currentYear }
                </div>
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <SvgReturn color="#324d6b" className="icons icon_next" />
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Indicador comercial</div>
                    <div className="opt_download">
                        <div className="title_opt">Download da tabela em</div>
                        <a href={ process.env.REACT_APP_API_URL + "/php/donwloadTable.php?typeFile=indicator/pdf/" + currentYear  }>
                            <div className="show_opt">PDF</div>
                        </a>
                        <a href={ process.env.REACT_APP_API_URL + "/php/donwloadTable.php?typeFile=indicator/csv/" + currentYear  }>
                            <div className="show_opt">Excel</div>
                        </a>
                    </div>
                </div>

                <div className="list_table">
                    <motion.div className="carousel" ref={ carousel_1 } layoutId="carousel_1">
                        <motion.div className="div_gallery" drag="x" dragConstraints={ { right:0, left: -width_1 } } transition={ { repeat: Infinity, duration: 0.8 } }>                                
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th align="center">Mês</th>
                                        <th align="center">Oportunidades</th>
                                        <th align="center">Orçamento enviado</th>
                                        <th align="center">Perdidas</th>
                                        <th align="center">Aprovados</th>
                                        <th align="center">Aguardando resposta</th>
                                        <th align="center">% de conversão</th>
                                        <th align="center">Valor orçado</th>
                                        <th align="center">Valor perdido</th>
                                        <th align="center">Valor conquistado</th>
                                        <th align="center">% Valor convertido</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        monthList.map((key, index)=>{

                                            let qtdOpportunities = 0;
                                            let qtdBudgetSent    = 0;
                                            let qtdLost          = 0;
                                            let qtdConquered     = 0;
                                            let qtdWaitingReply  = 0;

                                            let cashConversion     = "0";
                                            let cashBudgetedAmount = 0.00;
                                            let cashLost           = 0.00;
                                            let cashConquered      = 0.00;
                                            let cashConverted      = "0";

                                            let showData = [];

                                            let ListQtdOpportunities = [];
                                            let ListQtdSent = [];
                                            let ListQtdLost = [];
                                            let ListQtdConquered = [];
                                            let ListQtdWaitingReply = [];

                                            if(budgetYear){
                                                if(budgetYear.filter(item => item.month == key.month)){
                                                    let showBudgetMonth = budgetYear.filter(item => item.month == key.month);
                                                    ListQtdOpportunities = showBudgetMonth;
                                                    ListQtdSent = showBudgetMonth.filter(item => item.status == 8);
                                                    ListQtdLost = showBudgetMonth.filter(item => item.status == 2).filter(item => item.status == 12);
                                                    ListQtdConquered = showBudgetMonth.filter(item => item.status == 1);
                                                    ListQtdWaitingReply = showBudgetMonth.filter(item => item.status != 1).filter(item => item.status != 2).filter(item => item.status != 12);
                                                }
                                            }

                                            if(budgetAll){
                                                if(budgetAll.find(item => item.month == key.month)){
                                                    showData = budgetAll.find(item => item.month == key.month);

                                                    TotalQtdOpportunities = TotalQtdOpportunities + showData.contents.length;
                                                    TotalQtdBudgetSent    = TotalQtdBudgetSent + showData.contents.filter(item => item.status == 8).length;
                                                    TotalQtdLost          = TotalQtdLost + showData.contents.filter(item => item.status == 2).filter(item => item.status == 12).length;
                                                    TotalQtdConquered     = TotalQtdConquered + showData.contents.filter(item => item.status == 1).length;
                                                    TotalQtdWaitingReply  = TotalQtdWaitingReply + showData.contents.filter(item => item.status != 1).filter(item => item.status != 2).filter(item => item.status != 12).length;

                                                    TotalCashConversion     = TotalCashConversion + showData.cash_conversion.substring(0, 4);
                                                    TotalCashBudgetedAmount = TotalCashBudgetedAmount + Number(showData.cash_amount);
                                                    TotalCashLost           = TotalCashLost + Number(showData.cash_lost);
                                                    TotalCashConquered      = TotalCashConquered + Number(showData.cash_conquered);
                                                    TotalCashConverted      = TotalCashConverted + showData.cash_converted.substring(0, 4);

                                                    qtdOpportunities = showData.contents.length;
                                                    qtdBudgetSent    = showData.contents.filter(item => item.status == 8).length;
                                                    qtdLost          = showData.contents.filter(item => item.status == 2).filter(item => item.status == 12).length;                                                
                                                    qtdConquered     = showData.contents.filter(item => item.status == 1).length;
                                                    qtdWaitingReply  = showData.contents.filter(item => item.status != 1).filter(item => item.status != 2).filter(item => item.status != 12).length;
                                                    
                                                    cashConversion     = showData.cash_conversion;
                                                    cashBudgetedAmount = showData.cash_amount;
                                                    cashLost           = showData.cash_lost;
                                                    cashConquered      = showData.cash_conquered;
                                                    cashConverted      = showData.cash_converted;
                                                }
                                            }
                                            
                                            return(
                                                <tr key={ index }>
                                                    <td align="center">{ key.name.substring(0, 3) }</td>
                                                    <td align="center" onClick={ ()=>{ OpenBudget(1, 'Todos orçamentos', ListQtdOpportunities) } }>{ qtdOpportunities }</td>
                                                    <td align="center" onClick={ ()=>{ OpenBudget(1, 'Orçamentos enviados', ListQtdSent) } }>{ qtdBudgetSent }</td>
                                                    <td align="center" onClick={ ()=>{ OpenBudget(1, 'Orçamentos perdidos', ListQtdLost) } }>{ qtdLost }</td>
                                                    <td align="center" onClick={ ()=>{ OpenBudget(1, 'Orçamentos aprovados', ListQtdConquered) } }>{ qtdConquered }</td>
                                                    <td align="center" onClick={ ()=>{ OpenBudget(1, 'Aguardando resposta', ListQtdWaitingReply) } }>{ qtdWaitingReply }</td>
                                                    <td align="center">{ cashConversion.substring(0, 4) }%</td>
                                                    <td align="center">{ formatNumber(cashBudgetedAmount) }</td>
                                                    <td align="center" onClick={ ()=>{ OpenBudget(1, 'Orçamentos enviados', ListQtdSent) } }>{ formatNumber(cashLost) }</td>
                                                    <td align="center" onClick={ ()=>{ OpenBudget(1, 'Orçamentos aprovados', ListQtdConquered) } }>{ formatNumber(cashConquered) }</td>
                                                    <td align="center">{ cashConverted.substring(0, 4) }%</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <th align="center" className="end_th_left">Total</th>
                                        <th align="center">{ TotalQtdOpportunities }</th>
                                        <th align="center">{ TotalQtdBudgetSent }</th>
                                        <th align="center">{ TotalQtdLost }</th>
                                        <th align="center">{ TotalQtdConquered }</th>
                                        <th align="center">{ TotalQtdWaitingReply }</th>
                                        <th align="center">{ TotalQtdOpportunities !=0 ? cashConversion(TotalQtdConquered, TotalQtdOpportunities) : 0 }%</th>
                                        <th align="center">{ TotalCashBudgetedAmount !=0 ? formatNumber(TotalCashBudgetedAmount) : 0 }</th>
                                        <th align="center">{ TotalCashLost !=0 ? formatNumber(TotalCashLost) : 0 }</th>
                                        <th align="center">{ TotalCashConquered !=0 ? formatNumber(TotalCashConquered) : 0 }</th>
                                        <th align="center" className="end_th_right">{ TotalCashBudgetedAmount !=0 ? cashConversion(TotalCashConquered, TotalCashBudgetedAmount) : 0 }%</th>
                                    </tr>
                                </tbody>
                            </table>
                        </motion.div>
                    </motion.div>
                </div>
            </div>

            {
                budgetChance.length > 0 ?  
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Orçamentos com chances de fechar</div>
                    </div>
                    <div className="list_table">
                        <motion.div className="carousel" ref={ carousel_2 } layoutId="carousel_2">
                            <motion.div className="div_gallery" drag="x" dragConstraints={ { right:0, left: -width_2 } } transition={ { repeat: Infinity, duration: 0.8 } }>                                
                                <table cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th align="left" width="96">#</th>
                                            <th align="left">Cliente</th>
                                            <th align="left">Assunto</th>
                                            <th align="left" width="120">Assinatura</th>
                                            <th align="left" width="100">Valor R$</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            budgetChance.map((elem, index)=>{
                                                return(
                                                    <tr key={ index }>
                                                        <td align="center">
                                                            <div className="">#EST-{ elem.code }</div>
                                                            {
                                                                elem.version == 0 ? null : 
                                                                <div className="">
                                                                    Revisão { elem.version }
                                                                </div>
                                                            }
                                                        </td>
                                                        <td align="left">{ elem.nameClient }</td>
                                                        <td align="left">{ elem.subject == '' ? '---' : elem.subject }</td>
                                                        <td align="left">{ elem.nameSignature }</td>
                                                        <td align="left">{ elem.cash_total }</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
                : null
            }

            {
                budgetCanceled.length > 0 ? 
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Orçamentos cancelados ou perdidos</div>
                    </div>
                    <div className="list_table">
                        <motion.div className="carousel" ref={ carousel_3 } layoutId="carousel_3">
                            <motion.div className="div_gallery" drag="x" dragConstraints={ { right:0, left: -width_3 } } transition={ { repeat: Infinity, duration: 0.8 } }>                                
                                <table cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th align="left" width="30">#</th>
                                            <th align="left">Motivo</th>
                                            <th align="center" width="30">Qtd</th>
                                            <th align="left" width="30">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            budgetCanceled.map((elem, index)=>{
                                                let qtd = 0;
                                                let QtdCanceled = [];
                                                if(budgetQtdCanceled.filter(item => item.motive == elem.motive)){
                                                    QtdCanceled = budgetQtdCanceled.filter(item => item.motive == elem.motive);
                                                    qtd = QtdCanceled.length;
                                                }

                                                return(
                                                    <tr key={ index }>
                                                        <td align="center">{ index + 1 }</td>
                                                        <td align="left">{ elem.motiveName }</td>
                                                        <td align="center">{ qtd }</td>
                                                        <td align="left">
                                                            <div className="" onClick={ ()=>{ OpenBudget(0, 'Orçamentos cancelados ou perdidos', QtdCanceled) } }>
                                                                <SvgEye className="icons" color="#2D3C53" />
                                                            </div>    
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
                : null
            }

            {
                budgetApproved.length > 0 ? 
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Orçamentos aprovados</div>
                    </div>
                    <div className="list_table">
                        <motion.div className="carousel" ref={ carousel_4 } layoutId="carousel_4">
                            <motion.div className="div_gallery" drag="x" dragConstraints={ { right:0, left: -width_4 } } transition={ { repeat: Infinity, duration: 0.8 } }>                                
                                <table cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th align="left" width="30">#</th>
                                            <th align="left">Cliente</th>
                                            <th align="center" width="30">Qtd</th>
                                            <th align="left" width="120">Valor total R$</th>
                                            <th align="left" width="30">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            budgetApproved.map((elem, index)=>{
                                                let qtd = 0;
                                                let cashTotal = 0;
                                                let QtdApproved = [];
                                                if(budgetQtdApproved.filter(item => item.idClient == elem.idClient)){
                                                    QtdApproved = budgetQtdApproved.filter(item => item.idClient == elem.idClient);
                                                    qtd = QtdApproved.length;
                                                    QtdApproved.map((elem, index)=>{
                                                        cashTotal += elem.cash_total_;
                                                    });
                                                }

                                                return(
                                                    <tr key={ index }>
                                                        <td align="center">{ index + 1 }</td>
                                                        <td align="left">{ elem.nameClient }</td>
                                                        <td align="center">{ qtd }</td>
                                                        <td align="left">
                                                            { formatNumber(cashTotal) }
                                                        </td>
                                                        <td align="left">
                                                            <div className="" onClick={ ()=>{ OpenBudget(0, 'Orçamentos aprovados', QtdApproved) } }>
                                                                <SvgEye className="icons" color="#2D3C53" />
                                                            </div>    
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
                : null
            }

            <PopUP_ListBudget />
        </div>
    )
}