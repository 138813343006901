import { useState, useEffect } from "react";

import './Image.css';

export default function PreviewFixed_Image(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    return(
        <div className={ "div_show_image position_" + dataPage.height }>
            <div className={ "div_img position_" }>
                <img alt={ "img_" } src={ "./assets/portfolio/img/" + dataPage.file } className={ "show_img " } />
            </div>
        </div>
    )
}