import { useState, useEffect } from "react";

import './Proposal.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { Reg_UpdateProposal } from "services/RegisterData";

import { SvgCopy, SvgDelete, SvgEdit, SvgEye, SvgSearch, SvgSetaRight } from "components/SvgFile";
import { GetUserData, RegisterUserData } from "interface/Users";

export default function Page_Proposal(){

    const date = new Date();

    const [ search, setSearch ]     = useState('');
    const [ year, setYear ]         = useState(date.getFullYear());

    const [ month, setMonth ]         = useState('All');
    const [ nameMonth, setNameMonth ] = useState('All');
    const [ status, setStatus ]       = useState('All');

    const [ dataUser, setDataUser ] = useState(GetUserData('access'));
    const [ showData, setShowData ] = useState(GetDataPage('proposal'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);
    
    const [ tableTd, setTableTd ] = useState(TypeTD());

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);

    function TypeTD(){
        const dataTD  = [];
        const optTD   = GetDataPage('list_opt_td').filter(item => item.origin == "proposal");        
        const newData = GetDataPage("access_dash").find(item => item.id == GetUserData('id')).list_table_td;
        optTD.map((elem, index)=>{
            if(newData.find(item => item.id_given == elem.id)){
                dataTD.push(elem.page);
            }
        });
        return dataTD;
    }

    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('proposal').forEach(item =>{
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.nameClient.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.description.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowData(duplicate);

        }else if(value == ''){
            setShowData(GetDataPage('proposal'));
        }
        setSearch(value);
    }

    function NewProposal(id){
        SetListPag('currentPage', 'proposal_details')
        SetListPag('currentPageId', id);
    }

    function RegisterBudget(idProject, idBudget){
        SetListPag('currentPage', 'budget_details');
        SetListPag('currentPageId', idBudget);
        SetListPag('idNewRegister', idProject);
    }

    function OpenProposal(id){
        window.open(process.env.REACT_APP_API_URL + '?project=proposal/' + id, '_blank');
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "proposal", "type": "delete_proposal", "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('proposal', setShowData);
        RegisterUserData('access', setDataUser);
    }, []);

    return(
        <div className="Page_Proposal">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Proposta
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="new_block_text" onClick={ ()=>{ NewProposal(0) } }>
                    <div className="">
                        <span className="icons_menu">+</span>
                    </div>
                    <div className="">
                        Proposta
                    </div>
                </div>
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        {
                            tableTd.length > 0 ?
                            <tr>
                                <th width="20" align="center">#</th>
                                {
                                    tableTd.map((elem, index)=>{
                                        switch (elem) {
                                            case "status":
                                                return <th width="180" key={ index }>Status</th>;

                                            case "client":
                                                return <th key={ index }>Cliente</th>;

                                            case "project":
                                                return <th key={ index }>Projeto</th>;

                                            case "type":
                                                return <th width="130" key={ index }>Tipo</th>;

                                            case "date":
                                                return <th width="80" key={ index }>Data</th>;
                                        }
                                    })
                                }
                                <th width="50" align="right">#</th>
                            </tr>
                            :
                            <tr>
                                <th width="20" align="center">#</th>
                                <th width="180">Status</th>
                                <th>Cliente</th>
                                <th>Projeto</th>
                                <th width="80">Data</th>
                                <th width="130">Tipo</th>
                                <th width="30" align="right">#</th>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    // status
                                    let classStatus = "";
                                    switch (key.status) {
                                        case 'Contato inicial': classStatus = 'status_1'; break;
                                        case 'Reunião agendada': classStatus = 'status_2'; break;
                                        case 'Proposta enviada': classStatus = 'status_3'; break;
                                        case 'Negociação': classStatus = 'status_4'; break;                                            
                                        case 'Conquistado': classStatus = 'status_5'; break;
                                        case 'Perdido': classStatus = 'status_6'; break;
                                        case 'Refazer proposta': classStatus = 'status_7'; break;                                           
                                        case 'Preparando proposta': classStatus = 'status_8'; break;
                                    }

                                    if(tableTd.length > 0){
                                        return(
                                            <tr key={ index }>
                                                <td align="center">{ startItens + index + 1 }</td>
                                                {
                                                    tableTd.map((elem, index)=>{
                                                        switch (elem) {
                                                            case "status":
                                                                return(
                                                                    <td key={ index }>
                                                                        <div className={ "btn_status " + classStatus }>{ key.status }</div>
                                                                    </td>
                                                                );

                                                            case "client":
                                                                return <td key={ index }>{ key.nameClient }</td>;

                                                            case "project":
                                                                return <td key={ index }>{ key.title }</td>;

                                                            case "type":
                                                                return(
                                                                    <td key={ index } align="right">
                                                                        <div className="list_opt_">
                                                                            {
                                                                                key.id_budget == 0 ?
                                                                                <div className="new_budget" onClick={ ()=>{ RegisterBudget(key.id, key.id_budget) } }>+ Orçamento</div> :
                                                                                <div className="show_budget" onClick={ ()=>{ RegisterBudget(key.id, key.id_budget) } }>Ver orçamento</div>
                                                                            } 
                                                                        </div>
                                                                    </td>
                                                                );

                                                            case "date":
                                                                return <td key={ index }>{ key.date_br }</td>;
                                                        }
                                                    })
                                                }

                                                <td align="right">
                                                    <div className="list_opt">
                                                        {/* <div className="" title="Copiar link" onClick={ ()=>{ CopyLink(key.id) } }>
                                                            <SvgCopy color="#324d6b" className="icons" />
                                                        </div> */}
                                                        {
                                                            dataUser == 1 ? 
                                                            <div onClick={ ()=>{ DeleteData(key.id, key.nameClient + " - " + key.title) } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div> : null
                                                        }
                                                        <div onClick={ ()=>{ OpenProposal(key.id) } }>
                                                            <SvgEye color="#324d6b" className="icons"/>
                                                        </div>
                                                        <div onClick={ ()=>{ NewProposal(key.id) } }>
                                                            <SvgEdit color="#324d6b" className="icons"/>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                    }else {
                                        return(
                                            <tr key={ index }>
                                                <td align="center">{ startItens + index + 1 }</td>
                                                <td>
                                                    <div className={ "btn_status " + classStatus }>{ key.status }</div>
                                                </td>
                                                <td>{ key.nameClient }</td>
                                                <td>{ key.title }</td>
                                                <td>{ key.date_br }</td>
                                                <td align="right">
                                                    <div className="list_opt_">
                                                        {
                                                            key.id_budget == 0 ?
                                                            <div className="new_budget" onClick={ ()=>{ RegisterBudget(key.id, key.id_budget) } }>+ Orçamento</div> :
                                                            <div className="show_budget" onClick={ ()=>{ RegisterBudget(key.id, key.id_budget) } }>Ver orçamento</div>
                                                        } 
                                                    </div>                                               
                                                </td>
                                                <td align="right">
                                                    <div className="list_opt">
                                                        {/* <div className="" title="Copiar link" onClick={ ()=>{ CopyLink(key.id) } }>
                                                            <SvgCopy color="#324d6b" className="icons" />
                                                        </div> */}
                                                        {
                                                            dataUser == 1 ? 
                                                            <div onClick={ ()=>{ DeleteData(key.id, key.nameClient + " - " + key.title) } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div> : null
                                                        }
                                                        <div onClick={ ()=>{ OpenProposal(key.id) } }>
                                                            <SvgEye color="#324d6b" className="icons"/>
                                                        </div>
                                                        <div onClick={ ()=>{ NewProposal(key.id) } }>
                                                            <SvgEdit color="#324d6b" className="icons"/>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })
                            :
                            <tr>
                                <td colSpan="8">
                                    <div className="no_data" style={ { marginTop: 0 } }>Nenhum portfólio encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className="div_qtd_proposal">
                    <span className="count_proposal">
                        {
                            GetDataPage('proposal').length == 0 ?
                            "Nenhuma proposta criada" :
                                GetDataPage('proposal').length == 1 ?
                                    GetDataPage('proposal').length + " proposta criada" :
                                    GetDataPage('proposal').length + " propostas criadas"
                        }
                    </span>
                </div>
                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}