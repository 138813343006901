import React, { useState, useEffect } from "react";

import './NewCategory.css';

import { SvgChekedReturn, SvgClose, SvgDelete, SvgImg, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Reg_Category } from "services/RegisterData";

export default function PopUp_NewCategory(props){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ dataPage, setDataPage ]   = useState(GetDataPage('services_category'));

    const [ name, setName ]     = useState('');
    const [ typeFile, setTypeFile ] = useState('Image');
    const [ video, setVideo ] = useState('');
    const [ color, setColor ]   = useState('');
    const [ file, setFile ]     = useState('');
    const [ status, setStatus ] = useState(false);

    const [ listColor, setListColor ] = useState([
        { "color": "#111111" },
        { "color": "#383838" },
        { "color": "#757575" },
        { "color": "#D9D9D9" },
        { "color": "#E03E1A" },
        { "color": "#F79722" },
        { "color": "#F3C11B" },
        { "color": "#198F51" },
        { "color": "#A1E8B9" },
        { "color": "#0C8CE9" },
        { "color": "#A8D7FA" },
        { "color": "#8A38F5" },
        { "color": "#D6B6FB" },
        { "color": "#FBBCB6" },
        { "color": "#FFC470" },
        { "color": "#F9DF90" }
    ]);

    useEffect(()=>{
        RegisterModalData('NewCategory', setModaldata);
        RegisterModalObserver('NewCategory', setShowPopUp);

        RegisterDataPage('services_category', setDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setDataPage(GetDataPage('services_category'));
            setName(InitialData('name'));
            setTypeFile(InitialData('type_file'));
            setColor(InitialData('color'));
            setFile(InitialData('file'));
            setVideo(InitialData('video'));
        }
    }, [showPopUp]);

    function InitialData(type){
        if(modalData.id != 0){
            const newData = dataPage.find(item => item.id == modalData.id);
            return newData[type];
        }
        if(type == 'type_file'){
            return 'Image';
        }
        if(type == 'color'){
            return '#111111';
        }
        return '';
    }

    function OpenColor(){
        SetModalData('Color', { "setColorPage": setColor });
        SetModalState('Color', true);
    }

    function PopUp_SaveData(){
        setLoading(true);
        Reg_Category(modalData.id, modalData.origin, name, typeFile, video, color, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setName('');
        setColor('');
        setFile('');
        setTypeFile('Image');
        setVideo('');
        setLoading(false);
        setStatus(false);
        SetModalState('NewCategory', false);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    return (
        (showPopUp ?
            <>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <img alt="loading" src="./assets/loading.gif" className="loading" />
                </div>
                <div className="PopUp">
                    <div className="all popup_category">
                        <div className="div_data type_div">
                            <div className="title">
                                Categoria
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">                                                      
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ setTypeFile(e.target.value) } } value={ typeFile } required>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vídeo Vimeo</option>
                                            <option value="Youtube">Vídeo Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo de capa</span>
                                    </div>
                                    {
                                        typeFile == 'Image' ?
                                        <span className="div_input div_add_img">
                                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { status == true ? "Foto adicionada" : "Adicionar foto" }
                                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                file !='' ?
                                                <>
                                                    <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    {
                                                        status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </span> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="space_width" onChange={ (e)=>{ setVideo(e.target.value) } } value={ video } />
                                                <span className="name_input">Link do vídeo</span>
                                            </div>
                                            {
                                                video == '' ? null :
                                                <div className="space_top" onClick={ ()=>{ OpenFile(typeFile, video) } }>
                                                    {
                                                        typeFile == 'Vimeo' ?  
                                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }     
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="space_width" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>                               
                                    <div className="div_input" onClick={ ()=>{ OpenColor() } }>
                                        <div className="div_color" style={ { backgroundColor: color } } />
                                        <span className="name_input">Cor do texto</span>
                                    </div>
                                </div>

                                <div className="register_data button_category" onClick={ ()=>{ PopUp_SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
