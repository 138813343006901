import React, { useState, useEffect } from "react";

import './SendEmail.css';

import { SvgClose, SvgDelete } from "components/SvgFile";

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_UpdatesEmail } from "services/RegisterData";

export default function PopUP_SendEmail(){

    const [ loading, setLoading ] = useState(false);
    const [ idPage, setIdPage ]   = useState(GetListPag('currentPageId'));
    const [ userId, setUserId ]   = useState(GetUserData('id'));

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);
    
    const [ subject, setSubject ]     = useState(modalData.year + ' ' + modalData.subject + ' | Update de vendas');
    const [ listEmail, setListEmail ] = useState([]);

    function HandleData(type, index, value){
        const newData  = [...listEmail];
        newData[index][type] = value;
        setListEmail(newData);
    }

    function DeleteData(index){
        const newData  = [...listEmail];
        newData.splice(index, 1);
        setListEmail(newData);
    }

    function SaveData(){
        setLoading(true);
        Reg_UpdatesEmail(userId, idPage, subject, listEmail, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        modalData.UpdateEmail();
        
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
        ClosePopUp();
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
        ClosePopUp();
    }

    function ClosePopUp(){
        setLoading(false);
        setSubject('');
        setListEmail([]);
        SetModalState('SendEmail', false);
    }

    useEffect(()=>{
        RegisterModalData('SendEmail', setModaldata);
        RegisterModalObserver('SendEmail', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setSubject(modalData.year + ' ' + modalData.subject + ' | Update de vendas');
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <img alt="loading" src="./assets/loading.gif" className="loading" />
                </div>
                <div className="PopUp">
                    <div className="all popup_send_email">
                        <div className="div_data type_div">
                            <div className="title">
                                Enviar e-mail
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data space_div">
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setSubject(e.target.value) } } value={ subject } />
                                        <span className="name_input">Assunto</span>
                                    </div>
                                    <div className="div_input add_new_email" onClick={ ()=>{ setListEmail([...listEmail, { "email": "" }]) } }>
                                        Adicionar e-mail
                                    </div>
                                </div>
                                {
                                    listEmail.map((elem, index)=>{
                                        return(
                                            <div className="list_input_data space_div" key={ index }>
                                                <div className="div_input space_div">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleData('email', index, e.target.value) } } value={ elem.email } />
                                                    <span className="name_input">Email</span>
                                                </div>
                                                <div className="div_input" onClick={ ()=>{ DeleteData(index) } }>
                                                    <SvgDelete className="icons" color="#f00000" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className="register_data button_profile" onClick={ ()=>{ SaveData() } }>
                                    Enviar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
