// Connection type
// const pageServidor = 'http://localhost/React_JS/dashboard_portfolio/v4_final/public';
const pageServidor = '.';
export const connectionPage = pageServidor;

// Cookies type
// const cookiesServidor = 'localhost';
const cookiesServidor = 'portfoliov3.chroma-garden.com/';
// const cookiesServidor = 'esc.chroma-garden.co/';
export const cookiesRegister = cookiesServidor;

// Cookies name
export const typeCookie = 'chroma_portfolio_dash';
export const typeCookiePassw = 'chroma_portfolio_passw';
export const typeCookieEmail = 'chroma_portfolio_email';
export const typeCookiePage = 'chroma_portfolio_page';
export const typeCookiePageId = 'chroma_portfolio_page_id';
export const typeCookieNewId = 'chroma_portfolio_new_id';

// export const typeCookie = 'esc_dash';
// export const typeCookiePassw = 'esc_passw';
// export const typeCookieEmail = 'esc_email';
// export const typeCookiePage = 'esc_page';
// export const typeCookiePageId = 'esc_page_id';
// export const typeCookieNewId = 'esc_new_id';

// opt box editor
export const config = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,
    buttons: ['fontsize', 'bold', 'italic', 'underline', 'brush', 'hr', 'image', 'link', 'align', 'paragraph', 'ul', 'ol', 'source'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "Digite o texto aqui...",
    toolbarAdaptive: false
}

// opt box editor simple
export const configText = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,
    buttons: ['bold', 'italic', 'underline', 'link', 'ul', 'ol', 'source'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "Digite o texto aqui...",
    toolbarAdaptive: false
}

// List month
export const monthList = [
    {
        "name": "Janeiro",
        "month": "01"
    },
    {
        "name": "Fevereiro",
        "month": "02"
    },
    {
        "name": "Março",
        "month": "03"
    },
    {
        "name": "Abril",
        "month": "04"
    },
    {
        "name": "Maio",
        "month": "05"
    },
    {
        "name": "Junho",
        "month": "06"
    },
    {
        "name": "Julho",
        "month": "07"
    },
    {
        "name": "Agosto",
        "month": "08"
    },
    {
        "name": "Setembro",
        "month": "09"
    },
    {
        "name": "Outubro",
        "month": "10"
    },
    {
        "name": "Novembro",
        "month": "11"
    },
    {
        "name": "Dezembro",
        "month": "12"
    }
]