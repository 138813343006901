import React, { useState, useEffect } from "react";

import './ListBudget.css';

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

export default function PopUP_ListBudget(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ budget, setBudget ] = useState([]);

    function ClosePopUp(){
        SetModalState('ListBudget', false);
    }

    useEffect(()=>{
        RegisterModalData('ListBudget', setModaldata);
        RegisterModalObserver('ListBudget', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setBudget(modalData.list);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all ListBudget">
                        <div className="div_data" style={ { paddingBottom: 10 } }>
                            <div className="title">
                                { modalData.title }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th align="left" width="96">#</th>
                                        {
                                            modalData.type == 1 ? <th align="left">Cliente</th> : null
                                        }                                        
                                        <th align="left">Assunto</th>
                                        <th align="left" width="100">Assinatura</th>
                                        <th align="left" width="100">Valor R$</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        budget.map((elem, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td align="left">
                                                        <div className="">#EST-{ elem.code }</div>
                                                        {
                                                            elem.version == 0 ? null : <div className="">Revisão { elem.version }</div>
                                                        }
                                                    </td>
                                                    {
                                                        modalData.type == 1 ? <td align="left">{ elem.nameClient }</td> : null
                                                    }
                                                    <td align="left">{ elem.subject == '' ? '---' : elem.subject }</td>
                                                    <td align="left">{ elem.nameSignature }</td>
                                                    <td align="left">{ elem.cash_total }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
