import React, { useState, useEffect } from "react";

import './NewContact.css';

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { Reg_NewContact } from "services/RegisterData";
import { phoneMask } from "services/Mask";

export default function PopUP_NewContact(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ name, setName ]         = useState('');
    const [ email, setEmail ]       = useState('');
    const [ phone, setPhone ]       = useState('');
    const [ position, setPosition ] = useState('');

    useEffect(()=>{
        RegisterModalData('NewContact', setModaldata);
        RegisterModalObserver('NewContact', setShowPopUp);
    }, []);

    function ClosePopUp(){
        SetModalState('NewContact', false);
    }

    function SaveData(){
        Reg_NewContact(modalData.id, name, email, phone, position, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all new_contact">
                        <div className="div_data" style={ { paddingBottom: 10 } }>
                            <div className="title">
                                Novo contato
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="show_page_data" style={ { marginTop: 0, padding: 0 } }>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setName(e.target.value) } } maxLength="80" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setPosition(e.target.value) } } maxLength="50" />
                                        <span className="name_input">Cargo</span>
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } maxLength="40" />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="phone" onChange={ (e)=>{ setPhone(e.target.value) } } maxLength="20" value={ phoneMask(phone) } />
                                        <span className="name_input">Telefone</span>
                                    </div>
                                </div>

                                <div className="register_data space_contact" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
