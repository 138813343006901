import { useState, useEffect, useRef } from "react";

import './Highlight_3.css';

export default function PreviewFixed_Highlight_3(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    return(
        <div className="div_show_highlight_3">
            {
                dataPage.title == "" ? null :
                <div className={ "highlight_3_title align_" + dataPage.width }>{ dataPage.title }</div>
            }
            
            <div className={ "highlight_3_text align_" + dataPage.width } dangerouslySetInnerHTML={ { __html: dataPage.text ? dataPage.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
        </div>
    )
}