import React, { useState, useEffect, version } from "react";

import './HistoricBudget.css';

import { SvgClose } from "components/SvgFile";

import { GetUserData } from "interface/Users";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { Reg_AltVersionBudget } from "services/RegisterData";

export default function PopUP_HistoricBudget(props){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('budget_historic'));

    const [ showData, setShowData ] = useState([]);
    const [ version, setVersion ]   = useState('');

    useEffect(()=>{
        RegisterModalData('HistoricBudget', setModaldata);
        RegisterModalObserver('HistoricBudget', setShowPopUp);

        RegisterDataPage('budget_historic', setDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setShowData(InitialData(''));
            setVersion(modalData.version);
        }
    }, [showPopUp]);

    function InitialData(){
        const newData = dataPage.filter(item => item.id_budget == parseInt(modalData.id));
        return newData;
    }

    function Reactivate(id, version){
        setLoading(true);
        Reg_AltVersionBudget(id, version, userId, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('HistoricBudget', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                        <img alt="loading" src="./assets/loading.gif" className="loading" />
                    </div>
                    <div className="all historic_budget">
                        <div className="div_data type_div">
                            <div className="title">
                                Histórico do orçamento - { modalData.name }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <table cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th align="left" width="20">Versão</th>
                                            <th align="left">Type</th>
                                            <th align="center" width="60">Valor</th>
                                            <th align="center" width="60">Imposto</th>
                                            <th align="center" width="60">total</th>
                                            <th align="right" width="20">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            showData.map((key, index)=>{
                                                return(
                                                    <tr key={ index }>
                                                        <td align="center">{ key.version }</td>
                                                        <td align="left">{ key.type }</td>
                                                        <td align="center">{ key.cash.toLocaleString('pt-br', {minimumFractionDigits: 2}) }</td>
                                                        <td align="center">{ key.tash.toLocaleString('pt-br', {minimumFractionDigits: 2}) }</td>
                                                        <td align="center">{ key.total.toLocaleString('pt-br', {minimumFractionDigits: 2}) }</td>
                                                        <td align="right">
                                                            {
                                                                version == key.version ? 
                                                                <div className="version_actual">
                                                                    Versão atual
                                                                </div> :
                                                                <div className="reactivate" onClick={ ()=>{ Reactivate(key.id_budget, key.version) } }>
                                                                    Reativar
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
