import { useState, useEffect } from "react";

import './Portfolio.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SvgCheked, SvgMenuHamburguer } from "components/SvgFile";

import { Reg_AddPortifolio } from "services/RegisterData";

import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import PreviewFixed_Text from "./Text";
import PreviewFixed_Video from "./Video";
import PreviewFixed_Image from "./Image";
import PreviewFixed_Gallery from "./Gallery";
import PreviewFixed_GalleryOpen from "./GalleryOpen";
import PreviewFixed_Highlight_4 from "./Highlight_4";
import PreviewFixed_Highlight_3 from "./Highlight_3";
import PreviewFixed_Highlight_2 from "./Highlight_2";
import PreviewFixed_highlight_1 from "./Highlight_1";

export default function Preview_Portfolio(props){
    const [ url, setUrl ] = useState(ShowDataLink());
    
    const [ loading, setLoading ]           = useState(false);
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ typePage, setTypePage ]         = useState(GetListPag('page'));
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('portfolio'));
    const [ dataProposal, setDataProposal ] = useState(GetDataPage('proposal'));

    const [ dataPage, setDataPage ] = useState(InitialData());

    const [ status, setStatus ] = useState(false);

    const [ colorBg, setColorBg ] = useState(ColorPage());

    useEffect(()=>{        
        RegisterUserData('id', setUserId);
        RegisterListPag("page", setTypePage);
        RegisterDataPage("portfolio", setShowDataPage);
        RegisterDataPage("proposal", setDataProposal);
        

        let showTitle = dataPage.project.replaceAll('<br />', ' ');
        showTitle     = showTitle.replaceAll('<br/>', ' ');
        showTitle     = showTitle.replaceAll('<br>', ' ');
        document.title                      = showTitle;
        document.body.style.backgroundColor = colorBg;
    }, []);
    
    function ShowDataLink(){
        let link = new URLSearchParams(window.location.search);
        return link.get("project");        
    }

    function InitialData(){
        let showDataLink = url.split('/');
        if(showDataLink[0] == 'portfolio'){
            const newData = showDataPage.find(item => item.url == showDataLink[1]);
            return newData;
        }else {
            return window.history.pushState("", "", "/");
        }
    }

    function ColorPage(){
        if(dataPage.color_bg == ''){
            return "#121212";
        }
        return dataPage.color_bg;
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://player.vimeo.com/video/" + video + "?background=1&autoplay=1&loop=1&autopause=0&controls=0" } frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                        </div>
                    </div>
                )

            case 'Youtube':
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://www.youtube.com/embed/" + video + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + video + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen />
                        </div>
                    </div>
                )
        }
    }

    function RegisterData(status, id){
        if(status == true){
            setStatus(false);
        }else {
            Reg_AddPortifolio(userId, id, parseInt(props.idPage), ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
    }

    function CallbackSuccess(){        
        setLoading(false);
        setStatus(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        setStatus(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ShowContentsData(type, data, index_1, page_full){
        switch (type) {
            case "text":
                return <PreviewFixed_Text data={ data } />;
                
            case "highlight_1":
                return <PreviewFixed_highlight_1 data={ data } />;
                
            case "highlight_2":
                return <PreviewFixed_Highlight_2 data={ data } />;
                
            case "highlight_3":
                return <PreviewFixed_Highlight_3 data={ data } />;
                
            case "highlight_4":
                return <PreviewFixed_Highlight_4 data={ data } />;
                
            case "video":
                return <PreviewFixed_Video data={ data } />;
                
            case "img":
                return <PreviewFixed_Image data={ data } color={ colorBg } typePage={ page_full } />;
                
            case "gallery":
                if(data.model_gallery == 'Normal'){
                    return <PreviewFixed_Gallery index={ index_1 } data={ data } color={ colorBg } />;
                }else {
                    return <PreviewFixed_GalleryOpen index={ index_1 } data={ data } color={ colorBg } />;
                }
        }
    }
    
    return(
        <div className="Preview_Portfolio" style={ { backgroundColor: colorBg } }>
            <div className="line_fixed">
                <div className="line_ line_1" />
                <div className="line_ line_2" />
                <div className="line_ line_3" />
            </div>

            <div className="container div_topic_fixed">
                <div className="logotipo">
                    <img alt="img" src="./assets/logotipo.png" className="logotipo_img" />
                </div>
                {
                    typePage === 'dashboard' ? 
                    <div className="selected">
                        <div className="opt_selected" onClick={ ()=>{ setStatus(!status); } }>
                            <SvgMenuHamburguer className="icons" color="#ffffff" />
                        </div>
                        {
                            status === false ? null : 
                            <div className="list_proposal">
                                {
                                    dataProposal.map((key, index)=>{
                                        let checked = false;
                                        key.portfolio.map((key_1, index_1)=>{
                                            if(parseInt(key_1.id_project) === parseInt(props.idPage)){
                                                checked = true;
                                            }
                                        })
                                        return(
                                            <div className="name_opt" key={ index } onClick={ ()=>{ RegisterData(checked, key.id) } }>
                                                <div className="">
                                                    { key.title } - { key.year }
                                                </div>
                                                {
                                                    checked == true ? <SvgCheked className="icons" color="#ffffff" /> : null
                                                }                                                
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    : null
                    }
            </div>
            
            <div className="div_initial" style={ { backgroundColor: dataPage.type_file == "Color" ? dataPage.cover_color : colorBg } }>
                <div className="color_" style={ { backgroundImage: "linear-gradient(to top, " + colorBg + ", rgb(255 255 255 / 0%), rgb(255 255 255 / 0%), rgb(255 255 255 / 0%), rgb(255 255 255 / 0%))" } }>

                </div>
                {
                    dataPage.type_file == "Color" ?                     
                    <div className="div_cover_text">
                        <div className="data_project">
                            <div className="title" dangerouslySetInnerHTML={ { __html: dataPage.project ? dataPage.project.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        </div>
                        <div className="data_project show_data_project_">
                            {
                                dataPage.name_client == "" ? null : 
                                <div className="name_cliente">
                                    <span className="dest_a">Cliente:</span> <span className="dest_b">{ dataPage.name_client }</span>
                                </div>
                            }
                            {
                                dataPage.year == "" ? null : 
                                <div className="name_cliente">
                                    <span className="dest_a">Ano:</span> <span className="dest_b">{ dataPage.year }</span>
                                </div>
                            }
                        </div>
                    </div> :
                    dataPage.type_file == 'Image' ? 
                    <img alt="img" src={ "./assets/portfolio/cover/" + dataPage.file } className="img_full" /> :
                    ShowVideo(dataPage.type_file, dataPage.file)
                }
                {/* 
                <div className="div_line">
                    <div className="line bg_1" style={ { backgroundColor: colorBg } } />
                    <div className="line bg_2" style={ { backgroundColor: colorBg } } />
                    <div className="line bg_3" style={ { backgroundColor: colorBg } } />
                    <div className="line bg_4" style={ { backgroundColor: colorBg } } />
                </div> 
                */}
            </div>

            {
                dataPage.type_file == "Color" ? null :
                <div className="container div_cover_file">
                    <div className="div_data_project title_only">
                        <div className="data_project">
                            <div className="title" dangerouslySetInnerHTML={ { __html: dataPage.project ? dataPage.project.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        </div>
                    </div>
                    
                    <div className="show_data_text">
                        <div className="div_data_project">
                            <div className="data_project show_data_project_">
                                {
                                    dataPage.name_client == "" ? null : 
                                    <div className="name_cliente">
                                        <span className="dest_a">Cliente:</span> <span className="dest_b">{ dataPage.name_client }</span>
                                    </div>
                                }
                                {
                                    dataPage.year == "" ? null : 
                                    <div className="name_cliente">
                                        <span className="dest_a">Ano:</span> <span className="dest_b">{ dataPage.year }</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="div_data_text">
                            <div className="div_text">
                                <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text ? dataPage.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                dataPage.contents.map((elem, index)=>{
                    let container = "";
                    let space_align = "";
                    if(elem.container == true){                          
                        if(elem.contents[0].type !='gallery'){
                            container = "container";
                            space_align = "space_gap_" + elem.contents.length;                            
                        }                      
                    }else {
                        if(elem.contents.find(item => item.type != 'img')){
                            space_align = "space_gap_" + elem.contents.length;    
                        }
                    }

                    return(
                        <div className={ container + " div_contents contents_height_" + elem.height + " contents_width_" + elem.width } key={ index }>
                            <div className={ "div_show_contents" + " " + space_align }>
                                {
                                    elem.contents.map((elem_1, index_1)=>{
                                        return (
                                            <div className={ "show_data_contents width_data_" + elem.contents.length + " height_" + elem_1.height } key={ index_1 }>
                                                { ShowContentsData(elem_1.type, elem_1, index_1, container) }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }

            {
                dataPage.datasheet == '' ? null : 
                <div className="container show_datasheet">
                    <div className="text" />
                    <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.datasheet ? dataPage.datasheet.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div>
            }

            <PopUP_ReturnResponse />
        </div>
    )
}