import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import Cookies from 'universal-cookie';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgBehance, SvgDashboardAbout, SvgDashboardPresentation, SvgDashboardService, SvgDelete, SvgEdit, SvgEye, SvgGoogleDrive, SvgLink, SvgLink_opt2, SvgLockClose, SvgSave, SvgSearch, SvgSetaRight, SvgSettings, SvgUserDash } from "components/SvgFile";

import { Reg_EditOrRegisterProposal } from "services/RegisterData";
import PopUP_NewClient from "components/PopUp/NewClient";
import PopUP_NewPortfolio from "components/PopUp/NewPortfolio";
import Toast from "components/Toast";

export default function Page_Proposal_Details(){

    const [ loading, setLoading ]           = useState(false);
    const [ toast, setToast ]               = useState(false);
    const [ idUser, setIdUser ]             = useState(GetUserData("id"));
    const [ listClient, setListClient ]     = useState(GetDataPage('client'));
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('proposal'));
    const [ idPage, setIdPage ]             = useState(GetListPag('currentPageId'));

    const [ url, setUrl ]                   = useState(InitialData('url'));
    const [ typeLogo, setTypeLogo ]         = useState(InitialData('type_logo'));
    const [ color, setColor ]               = useState(InitialData('color'));
    const [ client, setClient ]             = useState(InitialData('client'));
    const [ statusClient, setStatusClient]  = useState(false);
    const [ addNewClient, setAddNewClient ] = useState(false);
    const [ newClient, setNewClient ]       = useState('');

    const [ listContact, setListContact ]    = useState(ShowContact());
    const [ contact, setContact ]            = useState(InitialData('contact'));
    const [ newContact, setNewContact ]      = useState([]);

    const [ project, setProject ]           = useState(InitialData('title'));
    const [ description, setDescription ]   = useState(InitialData('description'));

    const [ proposalStatus, setProposalStatus ]     = useState(InitialData('status'));
    const [ selectStatus, setSelectStatus ]         = useState(false);

    const [ showAboutCompany, setShowAboutCompany ] = useState(InitialData('showAboutCompany'));
    const [ meeting, setMeeting ]                   = useState(InitialData('meeting'));

    const [ link, setLink ]           = useState(InitialData('link'));
    const [ portfolio, setPortfolio ] = useState(InitialData('portfolio'));

    const [ showPortfolio, setShowPortfolio ] = useState(GetDataPage('portfolio'));
    const [ newPortfolio, setNewPortfolio ]   = useState([])
    const [ statusPort, setStatusPort ]       = useState(false);

    function InitialData(type){
        if(idPage !=0){
            const newData = showDataPage.find(item => item.id == idPage);
            if(type == 'client'){
                const dataClient = listClient.find(item => item.id == newData[type]);
                return { "value": newData[type], "label": dataClient.name }; 
            }
            return newData[type];
        }
        if(type == 'status'){
            return 'Contato inicial';
        }
        if(type == 'showAboutCompany'){
            return false;
        }
        if(type == 'link' || type == 'portfolio' || type == 'contact'){
            return [];
        }
        if(type == 'client'){
            return { "value": 0, "label": "Selecione um cliente" }; 
        }
        if(type == 'contact'){
            return { "value": 0, "label": "Selecione um contato" }; 
        }
        return '';
    }

    function ListPortfolio(){
        const options = []
        showPortfolio.map((key, index)=>{
            if(portfolio.find(item => item.id_project == key.id)){ }else {
                let labelOpt = key.name_client + ' - ' + key.project;
                options.push({
                    "value": key.id, "label": labelOpt
                });
            }
        })
        return options;
    } 

    function ShowContact(){
        if(client){
            if(client.value !=0){
                const newData = listClient.find(item => item.id == client.value);
                return newData.contact;
            }
            return [];
        }
        return [];
    }

    function NewContact(){
        setNewContact([...newContact, { "id": 0, "idNew": 0, "type": 0, "name": "Contatos", "position": "", "email": "", "phone": "", "status": false }])
    }  

    function ShowTypeStatus(){
        switch (proposalStatus) {
            case 'Contato inicial':
                return(
                    <div className="list_opt_status status_1" onClick={ ()=>{ setSelectStatus(!selectStatus) } }>
                        { proposalStatus }
                    </div>
                )
            case 'Reunião agendada':
                return(
                    <div className="list_opt_status status_2" onClick={ ()=>{ setSelectStatus(!selectStatus) } }>
                        { proposalStatus }
                    </div>
                )
            case 'Proposta enviada':
                return(
                    <div className="list_opt_status status_3" onClick={ ()=>{ setSelectStatus(!selectStatus) } }>
                        { proposalStatus }
                    </div>
                )
            case 'Negociação':
                return(
                    <div className="list_opt_status status_4" onClick={ ()=>{ setSelectStatus(!selectStatus) } }>
                        { proposalStatus }
                    </div>
                )
            case 'Conquistado':
                return(
                    <div className="list_opt_status status_5" onClick={ ()=>{ setSelectStatus(!selectStatus) } }>
                        { proposalStatus }
                    </div>
                )
            case 'Perdido':
                return(
                    <div className="list_opt_status status_6" onClick={ ()=>{ setSelectStatus(!selectStatus) } }>
                        { proposalStatus }
                    </div>
                )
            case 'Refazer proposta':
                return(
                    <div className="list_opt_status status_7" onClick={ ()=>{ setSelectStatus(!selectStatus) } }>
                        { proposalStatus }
                    </div>
                )
            case 'Preparando proposta':
                return(
                    <div className="list_opt_status status_8" onClick={ ()=>{ setSelectStatus(!selectStatus) } }>
                        { proposalStatus }
                    </div>
                )
        }
    }

    function HandleContact(type, index, value){
        const newData = [...newContact];
        newData.map((key, index)=>{
            key.status = false;
        })
        newData[index][type] = value;
        setNewContact(newData);
    }

    function RemuveContact(index, id, value){
        if(id == 0){
            const newData = [...newContact];
            newData.splice(index, 1);
            setNewContact(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "proposal", "type": "delete_contact", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function NewClient(){
        SetModalData("NewClient", { "id": idPage });
        SetModalState('NewClient', true);
    }

    function DeleteProject(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "proposal", "type": "delete_portfolio", "name": value });
        SetModalState('Confirmation', true);
    }

    function OpenProposal(){
        window.open(process.env.REACT_APP_API_URL + '?project=proposal/' + idPage, '_blank');
    }

    function OpenPortifolio(id){
        window.open(process.env.REACT_APP_API_URL + '?project=portfolio/' + id, '_blank');
    }

    function ShowFileCover(type_file, file){
        switch (type_file) {
            case 'Vimeo':
                let linkVimeo = file.split('/');
                return(
                    <iframe className="project_data_img" src={ "https://player.vimeo.com/video/" + linkVimeo[3] + "?autoplay=1&loop=1&title=0&byline=0&portrait=0&loop=1&controls=0" } frameBorder="0" allowFullScreen />
                )

            case 'Youtube':
                let linkYoutube = file.split('=');
                return(
                    <iframe className="project_data_img" src={ "https://www.youtube.com/embed/" + linkYoutube[1] + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + linkYoutube[1] + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen />
                )
        }
    }

    function CopyLink(type, value){
        setToast(true);
        navigator.clipboard.writeText(process.env.REACT_APP_API_URL + "?" + type + "=" + value);
        setTimeout(() => {
            setToast(false);
        }, 1500);
    }

    function NewPortfolio(){
        SetModalData('NewPortfolio', { "id": idPage, "portfolio": portfolio, "setNewPortfolio": setNewPortfolio });
        SetModalState('NewPortfolio', true);
    }

    function OpenColor(){
        SetModalData('Color', { "setColorPage": setColor });
        SetModalState('Color', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        SetModalState('NewPortfolio', false);
        Reg_EditOrRegisterProposal(idPage, idUser, typeLogo, color, client.value, project, description, proposalStatus, showAboutCompany, meeting, newContact, newPortfolio, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        setStatusPort(false);
        setNewContact([]);
        setNewPortfolio([]);
        setStatusClient(false); 
        setAddNewClient(false);
        setSelectStatus(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        setStatusPort(false);
        setNewContact([]);
        setNewPortfolio([]);
        setStatusClient(false); 
        setAddNewClient(false);
        setSelectStatus(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'proposal');
        SetListPag('currentPageId', 'remuve');
    }    

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage('client', setListClient);
        RegisterDataPage('proposal', setShowDataPage);
        RegisterDataPage('portfolio', setShowPortfolio);
        RegisterListPag('currentPageId', setIdPage);
        ShowTypeStatus()
    }, []);

    useEffect(()=>{
        setShowDataPage(GetDataPage('proposal'));

        setUrl(InitialData('url'));
        setTypeLogo(InitialData('type_logo'));
        setColor(InitialData('color'));
        setClient(InitialData('client'));
        setContact(InitialData('contact'));
        setProject(InitialData('title'));
        setDescription(InitialData('description'));
        setProposalStatus(InitialData('status'));
        setShowAboutCompany(InitialData('showAboutCompany'));
        setMeeting(InitialData('meeting'));
        setLink(InitialData('link'));
        setPortfolio(InitialData('portfolio'));
        ShowTypeStatus()
    }, [showDataPage, idPage]);

    useEffect(()=>{
        if(client.value > 0){
            setListContact(ShowContact());
        }
    }, [client]);

    return(
        <div className="Page_Proposal_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            {
                toast == false ? null : <Toast />
            }
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Proposta
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <div className="list_opt_alt_page">                        
                        {
                            idPage == 0 ? null :
                            <>                     
                                <div className="icons_project" onClick={ ()=>{ CopyLink('proposal', url) } }>
                                    <div className="preview_eye">
                                        <SvgLink_opt2 color="#6c757d" className="icons" />
                                    </div>
                                </div>
                                <div className="icons_project" onClick={ ()=>{ OpenProposal() } }>
                                    <div className="preview_eye">
                                        <SvgEye color="#E51D4D" className="icons" />
                                    </div>
                                </div>
                            </>
                        }
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>
                <div className="show_data_register">
                    <div className="data_project">
                        <div className="show_page_data" style={ { marginTop:0 } }>
                    
                            <div className="list_input_data">
                                <div className="div_input">
                                    <select className="" onChange={ (e)=>{ setTypeLogo(e.target.value) } } value={ typeLogo }>
                                        <option value="logo_2.png">Branco</option>
                                        <option value="logo_3.png">Preto</option>
                                    </select>
                                    <span className="name_input">Logo</span>
                                </div>
                                <div className="div_input" onClick={ ()=>{ OpenColor() } }>
                                    <div className="div_color" style={ { backgroundColor: color } } />
                                    <span className="name_input">Cor de fundo</span>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input">
                                    <div className="div_show_select_height space_client">
                                        <div className="div_show_select_opt_selected" onClick={ ()=>{ setStatusClient(!statusClient); setSelectStatus(false); } }>
                                            <div className="div_show_select_name_data" title={ client.label }>
                                                { client.label }
                                                <span className="name_input">Cliente</span>
                                            </div>
                                            <div className="div_show_select_icons">
                                                <SvgSetaRight color="#E51D4D" className="icons" />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        statusClient == false ? null :
                                        <div className="div_show_select_list_data space_client">
                                            <div className="add_new_data">
                                                <div className="div_show_select_name div_show_select_new_data" onClick={ ()=>{ setClient({ "value": 0, "label": "Novo cliente" }); setAddNewClient(!addNewClient); setStatusClient(false); NewClient() } }>
                                                    <span className="">+</span>
                                                    Novo cliente
                                                </div>
                                            </div>
                                            <div className="show_data_existing">
                                                {
                                                    listClient.map((key, index)=>{
                                                        return (
                                                            <div className="div_show_select_name div_show_select_name_data" key={ index } title={ key.name } onClick={ ()=>{ setClient({ "value": key.id, "label": key.name }); setStatusClient(false); setAddNewClient(false); } }>
                                                                { key.name }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="close_list_data">
                                                <div className="div_show_select_name div_show_select_new_data div_show_select_cancel" onClick={ ()=>{ setSelectStatus(false); setStatusClient(false); } }>
                                                    Cancelar
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setProject(e.target.value) } } value={ project } required />
                                    <span className="name_input">Projeto</span>
                                </div>
                            </div>
                            <div className="list_input_data">   
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Descrição</div>
                                    <JoditEditor className="text_editor" config={ config } value={ description ? description.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setDescription(newContent) } />
                                </div>
                            </div>
                        </div>
                        
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">
                                    <SvgDashboardPresentation color="#606875" className="icons" />
                                    Portfólios
                                </div>
                                <div className="new_data_page" onClick={ ()=>{ NewPortfolio() } }>
                                    Novo portfólio
                                </div>
                            </div>
                            {
                                portfolio.length > 0 ? 
                                <div className="list_portfolio_new_proposal">
                                    {
                                        portfolio.map((key, index)=>{                                    
                                            return(   
                                                <div className="div_project_data" key={ index }>
                                                    <div className="project_data_inf">
                                                        <div className="project_data_client">{ key.nameClient }</div>
                                                        <div className="project_data_show_opt">
                                                            <div className="project_data_delete" onClick={ ()=>{ DeleteProject(key.id, key.nameClient) }}>
                                                                <SvgDelete color="#ffffff" className="icons" />
                                                            </div>
                                                            <div className="project_data_eye" onClick={ ()=>{ CopyLink('portfolio', key.url) } }>
                                                                <SvgLink_opt2 color="#ffffff" className="icons" />
                                                            </div>
                                                            <div className="project_data_eye" onClick={ ()=>{ OpenPortifolio(key.id_project) } }>
                                                                <SvgEye color="#ffffff" className="icons" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.restricted == false ? null :
                                                        <div className="project_data_lock_close">
                                                            <SvgLockClose color="#FFFFFF" className="lock" />
                                                        </div>
                                                    }
                                                    <div className="project_data_div_img">
                                                        {
                                                            key.type_file == 'Image' ?
                                                            <img alt="show img" src={ key.file != '' ? "./assets/portfolio/cover/thumbnail/thumbnail_" + key.file : "./assets/not_file.jpg" } className="project_data_img" />
                                                            : key.type_file == 'Color' ? <div className="color_bg" style={ { backgroundColor: key.cover_color } }>Cor no fundo</div> : ShowFileCover(key.type_file, key.file)
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div> : 
                                    statusPort == true ? null :
                                    <div className="list_input_data">
                                        <div className="no_data">Nenhum portfólio encontrado...</div>
                                    </div>
                            }
                        </div>
                    </div>

                    <div className="data_fixed">
                        <div className="div_show_text show_page_data" style={ { marginTop:0 } }>
                            <div className="div_restricted">
                                <div className="div_restricted_lock">
                                    <SvgSettings color="#606875" className="icons" />
                                </div>
                                <div className="div_restricted_title">
                                    Configuração
                                </div>
                            </div>
                            <div className="show_opt_config">
                                <div className="title_fixed">Status</div>
                            </div>

                            <div className="show_opt_config show_status">
                                {
                                    ShowTypeStatus()
                                }
                                {
                                    selectStatus == false ? null :
                                    <div className="show_list_opt_status">
                                        {
                                            proposalStatus == 'Contato inicial' ? null : <div className="status_1"  onClick={ ()=>{ setProposalStatus('Contato inicial'); setSelectStatus(false); } }>Contato inicial</div>
                                        }
                                        {
                                            proposalStatus == 'Reunião agendada' ? null : <div className="status_2" onClick={ ()=>{ setProposalStatus('Reunião agendada'); setSelectStatus(false); } }>Reunião agendada</div>
                                        }
                                        {
                                            proposalStatus == 'Proposta enviada' ? null : <div className="status_3" onClick={ ()=>{ setProposalStatus('Proposta enviada'); setSelectStatus(false); } }>Proposta enviada</div>
                                        }
                                        {
                                            proposalStatus == 'Negociação' ? null : <div className="status_4" onClick={ ()=>{ setProposalStatus('Negociação'); setSelectStatus(false); } }>Negociação</div>
                                        }
                                        {
                                            proposalStatus == 'Conquistado' ? null : <div className="status_5" onClick={ ()=>{ setProposalStatus('Conquistado'); setSelectStatus(false); } }>Conquistado</div>
                                        }
                                        {
                                            proposalStatus == 'Perdido' ? null : <div className="status_6" onClick={ ()=>{ setProposalStatus('Perdido'); setSelectStatus(false); } }>Perdido</div>
                                        }
                                        {
                                            proposalStatus == 'Refazer proposta' ? null : <div className="status_7" onClick={ ()=>{ setProposalStatus('Refazer proposta'); setSelectStatus(false); } }>Refazer proposta</div>
                                        }
                                        {
                                            proposalStatus == 'Preparando proposta' ? null : <div className="status_8" onClick={ ()=>{ setProposalStatus('Preparando proposta'); setSelectStatus(false); } }>Preparando proposta</div>
                                        }
                                    </div>
                                }
                            </div>

                            <div className="subtitle space_top_fixed">Mostrar</div>
                            <div className="show_opt_config">
                                <div className="title_fixed">Sobre a empresa</div>
                                <div className="div_restricted_switch">
                                    <label className="switch">
                                        <div className="switch_wrapper">
                                            <input type="checkbox" onChange={ (e)=>{ setShowAboutCompany(!showAboutCompany) } } checked={ showAboutCompany } />
                                            <span className="switch_button"></span>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            {/* 
                            <div className="show_opt_config">
                                <div className="title_fixed">Serviços</div>
                                <div className="div_restricted_switch">
                                    <label className="switch">
                                        <div className="switch_wrapper">
                                            <input type="checkbox" onChange={ (e)=>{ setShowService(!showService) } } checked={ showService } />
                                            <span className="switch_button"></span>
                                        </div>
                                    </label>
                                </div>
                            </div> 
                            */}

                            {/* 
                            <div className="show_opt_config">
                                <div className="title_fixed">Orçamento</div>
                                <div className="div_restricted_switch">
                                    <label className="switch">
                                        <div className="switch_wrapper">
                                            <input type="checkbox" onChange={ (e)=>{ setShowBudget(!showBudget) } } checked={ showBudget } />
                                            <span className="switch_button"></span>
                                        </div>
                                    </label>
                                </div>
                            </div> 
                            */}

                            <div className="div_restricted space_top_fixed">
                                <div className="div_restricted_lock">
                                    <SvgLink_opt2 color="#606875" className="icons" />
                                </div>
                                <div className="div_restricted_title">
                                    Link ata reunião
                                </div>  
                            </div>
                            <div className="show_opt_config">                                  
                                <div className="space_div">
                                    <input type="text" onChange={ (e)=>{ setMeeting(e.target.value) } } value={ meeting.replaceAll(' ', '') }placeholder="Link" />
                                </div>
                            </div>
                        </div>

                        <div className="div_show_text show_page_data">
                            <div className="div_restricted">
                                <div className="div_restricted_lock">
                                    <SvgUserDash color="#606875" className="icons" />
                                </div>
                                <div className="div_restricted_title">
                                    Contatos
                                </div>
                            </div>
                            {
                                contact.map((key, index)=>{
                                    return(
                                        <div className="div_show_select list_contact cursor_pointer" key={ index } onClick={ ()=>{ RemuveContact(index, key.id, key.name); } }>
                                            <div className="cont_position">{ key.position }</div>
                                            <div className="cont_name">{ key.name }</div>
                                            <div className="cont_data">{ key.email }</div>
                                            <div className="cont_data">{ key.phone }</div>
                                        </div>
                                    )
                                })
                            }
                            {
                                newContact.map((key, index)=>{
                                    return(
                                        <div className="div_show_select list_contact" key={ index }>
                                            <div className="show_opt_contact">
                                                <div className="div_show_select_height show_width_contact">
                                                    <div className="div_show_select_opt_selected" onClick={ ()=>{ HandleContact('status', index, !key.status); } }>
                                                        <div className="div_show_select_name_data" title={ key.name }>
                                                            { key.name }
                                                        </div>
                                                        <div className="div_show_select_icons">
                                                            <SvgSetaRight color="#E51D4D" className="icons" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="" onClick={ ()=>{ RemuveContact(index, key.id, key.name); } }>
                                                    <SvgDelete color="#F00000" className="icons" />
                                                </div>
                                            </div>
                                            {
                                                key.status == false ? null :
                                                <div className="div_show_select_list_data">
                                                    <div className="add_new_data">
                                                        <div className="div_show_select_name div_show_select_new_data" onClick={ ()=>{ HandleContact('type', index, 1); HandleContact('status', index, false); } }>
                                                            <span className="">+</span>
                                                            Novo contato
                                                        </div>
                                                    </div>
                                                    <div className="show_data_existing">
                                                        {
                                                            listContact.length > 0 ?
                                                            listContact.map((key_1, index_1)=>{
                                                                if(contact.find(item => item.id_contact == key_1.id) || newContact.find(item => item.idNew == key_1.id)){}else {
                                                                    return (
                                                                        <div className="div_show_select_name div_show_select_name_data" key={ index_1 } title={ key_1.name } onClick={ ()=>{ HandleContact('idNew', index, key_1.id); HandleContact('name', index, key_1.name);HandleContact('type', index, 0); HandleContact('status', index, false); } }>
                                                                            { key_1.name }
                                                                        </div>
                                                                    )
                                                                }
                                                            }) :
                                                            <div className="div_show_select_name div_show_select_name_data">
                                                                Necessário selecionar uma empresa
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="close_list_data">
                                                        <div className="div_show_select_name div_show_select_new_data div_show_select_cancel" onClick={ ()=>{ HandleContact('status', index, false); } }>
                                                            Cancelar
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                key.type == 0 ? null :
                                                <div className="list_opt_new_contact">
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleContact('name', index, e.target.value) } } required />
                                                        <span className="name_input">Nome</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleContact('position', index, e.target.value) } } />
                                                        <span className="name_input">Cargo</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleContact('email', index, e.target.value) } } />
                                                        <span className="name_input">E-mail</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleContact('phone', index, e.target.value) } } />
                                                        <span className="name_input">Celular</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                client.value > 0 ?
                                <div className="div_contact">
                                    <div className="new_contact" onClick={ ()=>{ NewContact() } }>
                                        <span className="add_more">+</span>
                                    </div>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
                <PopUP_NewPortfolio />
            </form>
            
            <PopUP_NewClient />
        </div>
    )
}