import { useState, useEffect, useRef } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import Select, { components } from 'react-select';

import { Reg_Services } from "services/RegisterData";

import { RegisterModalData } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { GetModalData, SetModalData, SetModalState } from "interface/PopUp";

import { SvgAddData, SvgCopy, SvgDelete, SvgEdit, SvgGoogleDrive, SvgImg, SvgOpt_1, SvgOpt_2, SvgOpt_3, SvgOpt_4, SvgOpt_5, SvgReturnCircle, SvgSave, SvgSearch, SvgSetaRight, SvgSettings, SvgText, SvgVimeo, SvgYoutube } from "components/SvgFile";

import PopUp_NewCategory from "components/PopUp/NewCategory";

export default function Page_Service_Details(){

    const [ loading, setLoading ]           = useState(false);
    const [ idUser, setIdUser ]             = useState(GetUserData("id"));
    const [ showData, setShowData ]         = useState(GetDataPage('services'));
    const [ showCategory, setShowCategory ] = useState(GetDataPage('services_category'));
    const [ idPage, setIdPage ]             = useState(GetListPag('currentPageId'));

    const [ optFile, setOptFile ] = useState([
        "opc1.jpg", "opc2.jpg", "opc3.jpg", "opc4.jpg", "opc5.jpg", "opc6.jpg", "opc7.jpg", "opc8.jpg"
    ]);

    const [ code, setCode ]         = useState(InitialData('code'));
    const [ name, setName ]         = useState(InitialData('name'));
    const [ cash, setCash ]         = useState(InitialData('cash'));
    const [ textBudg, setTextBudg ] = useState(InitialData('textBudg'));

    const [ textPres, setTextPres ]             = useState(InitialData('textPres'));
    const [ statusCategory, setStatusCategory ] = useState(false);
    const [ category, setCategory ]             = useState(InitialData('category'));
    const [ newCategory, setNewCategory ]       = useState([]);
    const [ contents, setContents ]             = useState(InitialData('contents'));
    const [ link, setLink ]                     = useState(InitialData('link'));

    useEffect(()=>{
        RegisterUserData('id', setIdUser);        
        RegisterDataPage('services', setShowData);
        RegisterDataPage('services_category', setShowCategory);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('services'));
        setShowCategory(GetDataPage('services_category'));

        setCode(InitialData('code'));
        setName(InitialData('name'));
        setCash(InitialData('cash'));
        setTextBudg(InitialData('textBudg'));

        setTextPres(InitialData('textPres'));
        setStatusCategory(false);
        setCategory(InitialData('category'));
        setNewCategory([]);
        setContents(InitialData('contents'));

        setLink(InitialData('link'));
    }, [showData, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = showData.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'category' || type == 'contents' || type == 'link'){
            return [];
        }
        return '';
    }

    function CopyServices(){
        SetModalData("Confirmation", { "id": idPage, "origin": "services", "type": "copy_services", "name": code, "idUser": idUser });
        SetModalState('Confirmation', true);
    }
    
    function ListCategory(){
        const options = [];
        showCategory.map((key, index)=>{
            if(category.find(item => item.idCategory == key.id)){ }else {
                options.push({
                    "value": key.id, "label": key.name
                });
            }
        });
        return options;
    }

    function AddNewLink(){
        setLink([...link, { "id": 0, "link": "" }]);
    }  

    function HandleLink(index, value){
        const newData = [...link];
        newData[index].link = value;
        setLink(newData);
    }

    function DeleteLink(id, index){
        if(id == 0){
            const newData = [...link];
            newData.splice(index, 1);
            setLink(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "services", "type": "delete_link", "name": "Link de referência" });
            SetModalState('Confirmation', true);
        }
    }

    function DeleteData(id, name){
        SetModalData("Confirmation", { "id": id, "origin": "services", "type": "delete_category", "name": name });
        SetModalState('Confirmation', true);
    }

    function AddNewFile(type){
        const newData = [];
        newData.push({
            "id": 0,
            "type": type,

            "type_file_1": "Image",
            "name_1": "",
            "img_1": "",
            "status_1": false,
            "title_1": "",
            "video_1": "",

            "type_file_2": "Image",
            "name_2": "",
            "img_2": "",
            "status_2": false,
            "title_2": "",
            "video_2": "",

            "type_file_3": "Image",
            "name_3": "",
            "img_3": "",
            "status_3": false,
            "title_3": "",
            "video_3": "",

            "type_file_4": "Image",
            "name_4": "",
            "img_4": "",
            "status_4": false,
            "title_4": "",
            "video_4": ""
        });
        setContents(newData);
    }
    
    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function PopUpCrop(crop, type, index, value){
        SetModalData('PhotoCrop', { "origin": "services", "crop": crop, "type": type, "index": index, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
        SetModalState('PhotoCrop', true);
    }

    function RegisterCropData(img){
        const newData = [...contents];
        newData[img.index]['status' + img.typeInput]      = true;
        newData[img.index]['img' + img.typeInput]         = img.file;
        newData[img.index]['positionX' + img.typeInput]   = img.positionX;
        newData[img.index]['positionY' + img.typeInput]   = img.positionY;
        newData[img.index]['crop_width' + img.typeInput]  = img.width;
        newData[img.index]['crop_height' + img.typeInput] = img.height;
        newData[img.index]['typeCrop' + img.typeInput]    = img.typeCrop;
        newData[img.index]['showWidth' + img.typeInput]   = img.showWidth;
        newData[img.index]['widthReal' + img.typeInput]   = img.widthReal;
        setContents(newData);
    }

    function HandleDataContents(type, index, value){
        const newData = [...contents];
        newData[index][type] = value;
        setContents(newData);
    }

    function ShowDataType(type, index){
        switch (type) {
            case "opc1.jpg":
                return(
                    <div className="add_project">
                        <div className="list_input_data">
                            <div className="div_input">
                                <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_1', index, e.target.value) } } value={ contents[index]['type_file_1'] }>
                                    <option value="">#</option>
                                    <option value="Image">Imagem</option>
                                    <option value="Vimeo">Vimeo</option>
                                    <option value="Youtube">Youtube</option>
                                </select>
                                <span className="name_input">Tipo</span>
                            </div>
                            {
                                contents[index]['type_file_1'] == "Image" ? 
                                <>
                                    <div className="div_input space_div">
                                        <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_1', index, e.target.value) } } value={ contents[index]['name_1'] } />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <span className="div_input div_add_img">
                                        <div className={ contents[index]['status_1'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                {
                                                    contents[index]['status_1'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                }
                                                <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_1', index, e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            contents[index]['img_1'] !='' ?
                                                <>
                                                    <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_1', index, ""); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    {
                                                        contents[index]['status_1'] == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_1'])) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_1']) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                    }
                                                </>
                                            : null
                                        }
                                    </span>
                                </> : 
                                <>
                                    <div className="div_input space_div">
                                        <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_1', index, e.target.value) } } value={ contents[index]['title_1'] } />
                                        <span className="name_input">Título</span>
                                    </div>
                                </>
                            }
                        </div>
                        {
                            contents[index]['type_file_1'] == "Image" ? null :
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_1', index, e.target.value) } } value={ contents[index]['video_1'] } />
                                    <span className="name_input">Link do vídeo</span>
                                </div>
                                {
                                    contents[index]['video_1'] == '' ? null :
                                    <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_1'], contents[index]['video_1']) } }>
                                        {
                                            contents[index]['type_file_1'] == 'Vimeo' ?
                                            <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                            <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                        }
                                    </div>
                                }
                            </div>
                        }   
                    </div>
                )

            case "opc2.jpg":
                return(
                    <>                       
                        <div className="add_project">
                            <div className="list_input_data">
                                <div className="div_input">
                                    <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_1', index, e.target.value) } } value={ contents[index]['type_file_1'] }>
                                        <option value="">#</option>
                                        <option value="Image">Imagem</option>
                                        <option value="Vimeo">Vimeo</option>
                                        <option value="Youtube">Youtube</option>
                                    </select>
                                    <span className="name_input">Tipo</span>
                                </div>
                                {
                                    contents[index]['type_file_1'] == "Image" ? 
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_1', index, e.target.value) } } value={ contents[index]['name_1'] } />
                                            <span className="name_input">Nome</span>
                                        </div>
                                        <span className="div_input div_add_img">
                                            <div className={ contents[index]['status_1'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    {
                                                        contents[index]['status_1'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                    }
                                                    <input type="file" onChange={ (e)=>{ PopUpCrop(2, '_1', index, e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                contents[index]['img_1'] !='' ?
                                                    <>
                                                        <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_1', index, ""); } }>
                                                            <SvgDelete className="icons" color="#ffffff" />
                                                        </div>
                                                        {
                                                            contents[index]['status_1'] == true ?
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_1'])) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_1']) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                        }
                                                    </>
                                                : null
                                            }
                                        </span>
                                    </> : 
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_1', index, e.target.value) } } value={ contents[index]['title_1'] } />
                                            <span className="name_input">Título</span>
                                        </div>
                                    </>
                                }
                            </div>
                            {
                                contents[index]['type_file_1'] == "Image" ? null :
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_1', index, e.target.value) } } value={ contents[index]['video_1'] } />
                                        <span className="name_input">Link do vídeo</span>
                                    </div>
                                    {
                                        contents[index]['video_1'] == '' ? null :
                                        <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_1'], contents[index]['video_1']) } }>
                                            {
                                                contents[index]['type_file_1'] == 'Vimeo' ?
                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                            }
                                        </div>
                                    }
                                </div>
                            }   
                        </div>

                        <div className="add_project" style={ { marginTop: '16px' } }>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_2', index, e.target.value) } } value={ contents[index]['type_file_2'] }>
                                        <option value="">#</option>
                                        <option value="Image">Imagem</option>
                                        <option value="Vimeo">Vimeo</option>
                                        <option value="Youtube">Youtube</option>
                                    </select>
                                    <span className="name_input">Tipo</span>
                                </div>
                                {
                                    contents[index]['type_file_2'] == "Image" ? 
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_2', index, e.target.value) } } value={ contents[index]['name_2'] } />
                                            <span className="name_input">Nome</span>
                                        </div>
                                        <span className="div_input div_add_img">
                                            <div className={ contents[index]['status_2'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    {
                                                        contents[index]['status_2'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                    }
                                                    <input type="file" onChange={ (e)=>{ PopUpCrop(2, '_2', index, e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                contents[index]['img_2'] !='' ?
                                                    <>
                                                        <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_2', index, ""); } }>
                                                            <SvgDelete className="icons" color="#ffffff" />
                                                        </div>
                                                        {
                                                            contents[index]['status_2'] == true ?
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_2'])) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_2']) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                        }
                                                    </>
                                                : null
                                            }
                                        </span>
                                    </> : 
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_2', index, e.target.value) } } value={ contents[index]['title_2'] } />
                                            <span className="name_input">Título</span>
                                        </div>
                                    </>
                                }
                            </div>
                            {
                                contents[index]['type_file_2'] == "Image" ? null :
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_2', index, e.target.value) } } value={ contents[index]['video_2'] } />
                                        <span className="name_input">Link do vídeo</span>
                                    </div>
                                    {
                                        contents[index]['video_2'] == '' ? null :
                                        <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_2'], contents[index]['video_2']) } }>
                                            {
                                                contents[index]['type_file_2'] == 'Vimeo' ?
                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                            }
                                        </div>
                                    }
                                </div>
                            }  
                        </div>
                    </>
                )

            case "opc3.jpg":
                return(
                    <>            
                        <div className="add_project">
                            <div className="list_input_data">
                                <div className="div_input">
                                    <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_1', index, e.target.value) } } value={ contents[index]['type_file_1'] }>
                                        <option value="">#</option>
                                        <option value="Image">Imagem</option>
                                        <option value="Vimeo">Vimeo</option>
                                        <option value="Youtube">Youtube</option>
                                    </select>
                                    <span className="name_input">Tipo</span>
                                </div>
                                {
                                    contents[index]['type_file_1'] == "Image" ? 
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_1', index, e.target.value) } } value={ contents[index]['name_1'] } />
                                            <span className="name_input">Nome</span>
                                        </div>
                                        <span className="div_input div_add_img">
                                            <div className={ contents[index]['status_1'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    {
                                                        contents[index]['status_1'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                    }
                                                    <input type="file" onChange={ (e)=>{ PopUpCrop(2, '_1', index, e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                contents[index]['img_1'] !='' ?
                                                    <>
                                                        <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_1', index, ""); } }>
                                                            <SvgDelete className="icons" color="#ffffff" />
                                                        </div>
                                                        {
                                                            contents[index]['status_1'] == true ?
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_1'])) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_1']) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                        }
                                                    </>
                                                : null
                                            }
                                        </span>
                                    </> : 
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_1', index, e.target.value) } } value={ contents[index]['title_1'] } />
                                            <span className="name_input">Título</span>
                                        </div>
                                    </>
                                }
                            </div>
                            {
                                contents[index]['type_file_1'] == "Image" ? null :
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_1', index, e.target.value) } } value={ contents[index]['video_1'] } />
                                        <span className="name_input">Link do vídeo</span>
                                    </div>
                                    {
                                        contents[index]['video_1'] == '' ? null :
                                        <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_1'], contents[index]['video_1']) } }>
                                            {
                                                contents[index]['type_file_1'] == 'Vimeo' ?
                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                            }
                                        </div>
                                    }
                                </div>
                            }   
                        </div>
                        <div className="two_project">
                            <div className="add_project" style={ { marginTop: '16px' } }>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_2', index, e.target.value) } } value={ contents[index]['type_file_2'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_2'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_2', index, e.target.value) } } value={ contents[index]['name_2'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_2'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_2'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_2', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_2'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_2', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_2'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_2'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_2']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_2', index, e.target.value) } } value={ contents[index]['title_2'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_2'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_2', index, e.target.value) } } value={ contents[index]['video_2'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_2'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_2'], contents[index]['video_2']) } }>
                                                {
                                                    contents[index]['type_file_2'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }  
                            </div>
                            <div className="add_project" style={ { marginTop: '16px' } }>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_3', index, e.target.value) } } value={ contents[index]['type_file_3'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_3'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_3', index, e.target.value) } } value={ contents[index]['name_3'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_3'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_3'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_3', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_3'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_3', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_3'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_3'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_3']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_3', index, e.target.value) } } value={ contents[index]['title_3'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_3'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_3', index, e.target.value) } } value={ contents[index]['video_3'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_3'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_3'], contents[index]['video_3']) } }>
                                                {
                                                    contents[index]['type_file_3'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }  
                            </div>
                        </div>
                    </>
                )

            case "opc4.jpg":
                return(
                    <>           
                        <div className="two_project">
                            <div className="add_project">
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_1', index, e.target.value) } } value={ contents[index]['type_file_1'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_1'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_1', index, e.target.value) } } value={ contents[index]['name_1'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_1'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_1'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_1', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_1'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_1', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_1'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_1'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_1']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_1', index, e.target.value) } } value={ contents[index]['title_1'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_1'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_1', index, e.target.value) } } value={ contents[index]['video_1'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_1'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_1'], contents[index]['video_1']) } }>
                                                {
                                                    contents[index]['type_file_1'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }   
                            </div>
                            <div className="add_project">
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_2', index, e.target.value) } } value={ contents[index]['type_file_2'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_2'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_2', index, e.target.value) } } value={ contents[index]['name_2'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_2'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_2'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_2', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_2'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_2', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_2'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_2'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_2']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_2', index, e.target.value) } } value={ contents[index]['title_2'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_2'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_2', index, e.target.value) } } value={ contents[index]['video_2'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_2'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_2'], contents[index]['video_2']) } }>
                                                {
                                                    contents[index]['type_file_2'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }  
                            </div>
                        </div> 
                        <div className="add_project" style={ { marginTop: '16px' } }>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_3', index, e.target.value) } } value={ contents[index]['type_file_3'] }>
                                        <option value="">#</option>
                                        <option value="Image">Imagem</option>
                                        <option value="Vimeo">Vimeo</option>
                                        <option value="Youtube">Youtube</option>
                                    </select>
                                    <span className="name_input">Tipo</span>
                                </div>
                                {
                                    contents[index]['type_file_3'] == "Image" ? 
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_3', index, e.target.value) } } value={ contents[index]['name_3'] } />
                                            <span className="name_input">Nome</span>
                                        </div>
                                        <span className="div_input div_add_img">
                                            <div className={ contents[index]['status_3'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    {
                                                        contents[index]['status_3'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                    }
                                                    <input type="file" onChange={ (e)=>{ PopUpCrop(2, '_3', index, e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                contents[index]['img_3'] !='' ?
                                                    <>
                                                        <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_3', index, ""); } }>
                                                            <SvgDelete className="icons" color="#ffffff" />
                                                        </div>
                                                        {
                                                            contents[index]['status_3'] == true ?
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_3'])) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_3']) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                        }
                                                    </>
                                                : null
                                            }
                                        </span>
                                    </> : 
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_3', index, e.target.value) } } value={ contents[index]['title_3'] } />
                                            <span className="name_input">Título</span>
                                        </div>
                                    </>
                                }
                            </div>
                            {
                                contents[index]['type_file_3'] == "Image" ? null :
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_3', index, e.target.value) } } value={ contents[index]['video_3'] } />
                                        <span className="name_input">Link do vídeo</span>
                                    </div>
                                    {
                                        contents[index]['video_3'] == '' ? null :
                                        <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_3'], contents[index]['video_3']) } }>
                                            {
                                                contents[index]['type_file_3'] == 'Vimeo' ?
                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                            }
                                        </div>
                                    }
                                </div>
                            }  
                        </div>
                    </>
                )

            case "opc5.jpg":
                return(
                    <>  
                        <div className="two_project">
                            <div className="add_project">
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_1', index, e.target.value) } } value={ contents[index]['type_file_1'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_1'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_1', index, e.target.value) } } value={ contents[index]['name_1'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_1'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_1'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_1', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_1'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_1', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_1'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_1'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_1']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_1', index, e.target.value) } } value={ contents[index]['title_1'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_1'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_1', index, e.target.value) } } value={ contents[index]['video_1'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_1'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_1'], contents[index]['video_1']) } }>
                                                {
                                                    contents[index]['type_file_1'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }   
                            </div>
                            <div className="add_project">
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_2', index, e.target.value) } } value={ contents[index]['type_file_2'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_2'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_2', index, e.target.value) } } value={ contents[index]['name_2'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_2'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_2'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_2', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_2'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_2', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_2'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_2'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_2']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_2', index, e.target.value) } } value={ contents[index]['title_2'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_2'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_2', index, e.target.value) } } value={ contents[index]['video_2'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_2'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_2'], contents[index]['video_2']) } }>
                                                {
                                                    contents[index]['type_file_2'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }  
                            </div>
                        </div>   
                        <div className="two_project">
                            <div className="add_project" style={ { marginTop: '16px' } }>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_3', index, e.target.value) } } value={ contents[index]['type_file_3'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_3'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_3', index, e.target.value) } } value={ contents[index]['name_3'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_3'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_3'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_3', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_3'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_3', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_3'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_3'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_3']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_3', index, e.target.value) } } value={ contents[index]['title_3'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_3'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_3', index, e.target.value) } } value={ contents[index]['video_3'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_3'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_3'], contents[index]['video_3']) } }>
                                                {
                                                    contents[index]['type_file_3'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }   
                            </div>
                            <div className="add_project" style={ { marginTop: '16px' } }>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_4', index, e.target.value) } } value={ contents[index]['type_file_4'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_4'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_4', index, e.target.value) } } value={ contents[index]['name_4'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_4'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_4'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_4', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_4'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_4', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_4'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_4'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_4']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_4', index, e.target.value) } } value={ contents[index]['title_4'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_4'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_4', index, e.target.value) } } value={ contents[index]['video_4'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_4'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_4'], contents[index]['video_4']) } }>
                                                {
                                                    contents[index]['type_file_4'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }    
                            </div>
                        </div> 
                    </>
                )

            case "opc6.jpg":
                return(                    
                    <>  
                        <div className="two_project">
                            <div className="add_project">
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_1', index, e.target.value) } } value={ contents[index]['type_file_1'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_1'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_1', index, e.target.value) } } value={ contents[index]['name_1'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_1'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_1'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(3, '_1', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_1'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_1', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_1'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_1'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_1']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_1', index, e.target.value) } } value={ contents[index]['title_1'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_1'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_1', index, e.target.value) } } value={ contents[index]['video_1'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_1'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_1'], contents[index]['video_1']) } }>
                                                {
                                                    contents[index]['type_file_1'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }   
                            </div>
                            <div className="add_project">
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_2', index, e.target.value) } } value={ contents[index]['type_file_2'] }>
                                            <option value="">#</option>
                                            <option value="Image">Imagem</option>
                                            <option value="Vimeo">Vimeo</option>
                                            <option value="Youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        contents[index]['type_file_2'] == "Image" ? 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_2', index, e.target.value) } } value={ contents[index]['name_2'] } />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ contents[index]['status_2'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        {
                                                            contents[index]['status_2'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                        }
                                                        <input type="file" onChange={ (e)=>{ PopUpCrop(3, '_2', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    contents[index]['img_2'] !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_2', index, ""); } }>
                                                                <SvgDelete className="icons" color="#ffffff" />
                                                            </div>
                                                            {
                                                                contents[index]['status_2'] == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_2'])) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_2']) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </span>
                                        </> : 
                                        <>
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_2', index, e.target.value) } } value={ contents[index]['title_2'] } />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    contents[index]['type_file_2'] == "Image" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_2', index, e.target.value) } } value={ contents[index]['video_2'] } />
                                            <span className="name_input">Link do vídeo</span>
                                        </div>
                                        {
                                            contents[index]['video_2'] == '' ? null :
                                            <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_2'], contents[index]['video_2']) } }>
                                                {
                                                    contents[index]['type_file_2'] == 'Vimeo' ?
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                }
                                            </div>
                                        }
                                    </div>
                                }  
                            </div>
                        </div>
                    </>
                )

            case "opc7.jpg":
                return(
                    <>
                        <div className="two_project">
                            <div className="">                            
                                <div className="add_project">
                                    <div className="list_input_data">
                                        <div className="div_input">
                                            <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_1', index, e.target.value) } } value={ contents[index]['type_file_1'] }>
                                                <option value="">#</option>
                                                <option value="Image">Imagem</option>
                                                <option value="Vimeo">Vimeo</option>
                                                <option value="Youtube">Youtube</option>
                                            </select>
                                            <span className="name_input">Tipo</span>
                                        </div>
                                        {
                                            contents[index]['type_file_1'] == "Image" ? 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_1', index, e.target.value) } } value={ contents[index]['name_1'] } />
                                                    <span className="name_input">Nome</span>
                                                </div>
                                                <span className="div_input div_add_img">
                                                    <div className={ contents[index]['status_1'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            {
                                                                contents[index]['status_1'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                            }
                                                            <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_1', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    {
                                                        contents[index]['img_1'] !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_1', index, ""); } }>
                                                                    <SvgDelete className="icons" color="#ffffff" />
                                                                </div>
                                                                {
                                                                    contents[index]['status_1'] == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_1'])) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_1']) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                            </>
                                                        : null
                                                    }
                                                </span>
                                            </> : 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_1', index, e.target.value) } } value={ contents[index]['title_1'] } />
                                                    <span className="name_input">Título</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {
                                        contents[index]['type_file_1'] == "Image" ? null :
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_1', index, e.target.value) } } value={ contents[index]['video_1'] } />
                                                <span className="name_input">Link do vídeo</span>
                                            </div>
                                            {
                                                contents[index]['video_1'] == '' ? null :
                                                <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_1'], contents[index]['video_1']) } }>
                                                    {
                                                        contents[index]['type_file_1'] == 'Vimeo' ?
                                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }   
                                </div>                      
                                
                                <div className="add_project" style={ { marginTop: '16px' } }>
                                    <div className="list_input_data">
                                        <div className="div_input">
                                            <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_2', index, e.target.value) } } value={ contents[index]['type_file_2'] }>
                                                <option value="">#</option>
                                                <option value="Image">Imagem</option>
                                                <option value="Vimeo">Vimeo</option>
                                                <option value="Youtube">Youtube</option>
                                            </select>
                                            <span className="name_input">Tipo</span>
                                        </div>
                                        {
                                            contents[index]['type_file_2'] == "Image" ? 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_2', index, e.target.value) } } value={ contents[index]['name_2'] } />
                                                    <span className="name_input">Nome</span>
                                                </div>
                                                <span className="div_input div_add_img">
                                                    <div className={ contents[index]['status_2'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            {
                                                                contents[index]['status_2'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                            }
                                                            <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_2', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    {
                                                        contents[index]['img_2'] !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_2', index, ""); } }>
                                                                    <SvgDelete className="icons" color="#ffffff" />
                                                                </div>
                                                                {
                                                                    contents[index]['status_2'] == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_2'])) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_2']) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                            </>
                                                        : null
                                                    }
                                                </span>
                                            </> : 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_2', index, e.target.value) } } value={ contents[index]['title_2'] } />
                                                    <span className="name_input">Título</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {
                                        contents[index]['type_file_2'] == "Image" ? null :
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_2', index, e.target.value) } } value={ contents[index]['video_2'] } />
                                                <span className="name_input">Link do vídeo</span>
                                            </div>
                                            {
                                                contents[index]['video_2'] == '' ? null :
                                                <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_2'], contents[index]['video_2']) } }>
                                                    {
                                                        contents[index]['type_file_2'] == 'Vimeo' ?
                                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }  
                                </div>
                            </div>
                            <div className="" style={ { height: "314px" } }>
                                <div className="add_project" style={ { height: "calc(100% - 20px)" } }>
                                    <div className="list_input_data">
                                        <div className="div_input">
                                            <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_3', index, e.target.value) } } value={ contents[index]['type_file_3'] }>
                                                <option value="">#</option>
                                                <option value="Image">Imagem</option>
                                                <option value="Vimeo">Vimeo</option>
                                                <option value="Youtube">Youtube</option>
                                            </select>
                                            <span className="name_input">Tipo</span>
                                        </div>
                                        {
                                            contents[index]['type_file_3'] == "Image" ? 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_3', index, e.target.value) } } value={ contents[index]['name_3'] } />
                                                    <span className="name_input">Nome</span>
                                                </div>
                                                <span className="div_input div_add_img">
                                                    <div className={ contents[index]['status_3'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            {
                                                                contents[index]['status_3'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                            }
                                                            <input type="file" onChange={ (e)=>{ PopUpCrop(3, '_3', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    {
                                                        contents[index]['img_3'] !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_3', index, ""); } }>
                                                                    <SvgDelete className="icons" color="#ffffff" />
                                                                </div>
                                                                {
                                                                    contents[index]['status_3'] == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_3'])) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_3']) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                            </>
                                                        : null
                                                    }
                                                </span>
                                            </> : 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_3', index, e.target.value) } } value={ contents[index]['title_3'] } />
                                                    <span className="name_input">Título</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {
                                        contents[index]['type_file_3'] == "Image" ? null :
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_3', index, e.target.value) } } value={ contents[index]['video_3'] } />
                                                <span className="name_input">Link do vídeo</span>
                                            </div>
                                            {
                                                contents[index]['video_3'] == '' ? null :
                                                <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_3'], contents[index]['video_3']) } }>
                                                    {
                                                        contents[index]['type_file_3'] == 'Vimeo' ?
                                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }   
                                </div>
                            </div>
                        </div>
                    </>
                )

            case "opc8.jpg":
                return(
                    <>
                        <div className="two_project">
                            <div className="" style={ { height: "314px" } }>
                                <div className="add_project" style={ { height: "calc(100% - 20px)" } }>
                                    <div className="list_input_data">
                                        <div className="div_input">
                                            <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_1', index, e.target.value) } } value={ contents[index]['type_file_1'] }>
                                                <option value="">#</option>
                                                <option value="Image">Imagem</option>
                                                <option value="Vimeo">Vimeo</option>
                                                <option value="Youtube">Youtube</option>
                                            </select>
                                            <span className="name_input">Tipo</span>
                                        </div>
                                        {
                                            contents[index]['type_file_1'] == "Image" ? 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_1', index, e.target.value) } } value={ contents[index]['name_1'] } />
                                                    <span className="name_input">Nome</span>
                                                </div>
                                                <span className="div_input div_add_img">
                                                    <div className={ contents[index]['status_1'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            {
                                                                contents[index]['status_1'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                            }
                                                            <input type="file" onChange={ (e)=>{ PopUpCrop(3, '_1', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    {
                                                        contents[index]['img_1'] !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_1', index, ""); } }>
                                                                    <SvgDelete className="icons" color="#ffffff" />
                                                                </div>
                                                                {
                                                                    contents[index]['status_1'] == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_1'])) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_1']) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                            </>
                                                        : null
                                                    }
                                                </span>
                                            </> : 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_1', index, e.target.value) } } value={ contents[index]['title_1'] } />
                                                    <span className="name_input">Título</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {
                                        contents[index]['type_file_1'] == "Image" ? null :
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_1', index, e.target.value) } } value={ contents[index]['video_1'] } />
                                                <span className="name_input">Link do vídeo</span>
                                            </div>
                                            {
                                                contents[index]['video_1'] == '' ? null :
                                                <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_1'], contents[index]['video_1']) } }>
                                                    {
                                                        contents[index]['type_file_1'] == 'Vimeo' ?
                                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }   
                                </div>
                            </div>
                            <div className="">                            
                                <div className="add_project">
                                    <div className="list_input_data">
                                        <div className="div_input">
                                            <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_2', index, e.target.value) } } value={ contents[index]['type_file_2'] }>
                                                <option value="">#</option>
                                                <option value="Image">Imagem</option>
                                                <option value="Vimeo">Vimeo</option>
                                                <option value="Youtube">Youtube</option>
                                            </select>
                                            <span className="name_input">Tipo</span>
                                        </div>
                                        {
                                            contents[index]['type_file_2'] == "Image" ? 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_2', index, e.target.value) } } value={ contents[index]['name_2'] } />
                                                    <span className="name_input">Nome</span>
                                                </div>
                                                <span className="div_input div_add_img">
                                                    <div className={ contents[index]['status_2'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            {
                                                                contents[index]['status_2'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                            }
                                                            <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_2', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    {
                                                        contents[index]['img_2'] !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_2', index, ""); } }>
                                                                    <SvgDelete className="icons" color="#ffffff" />
                                                                </div>
                                                                {
                                                                    contents[index]['status_2'] == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_2'])) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_2']) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                            </>
                                                        : null
                                                    }
                                                </span>
                                            </> : 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_2', index, e.target.value) } } value={ contents[index]['title_2'] } />
                                                    <span className="name_input">Título</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {
                                        contents[index]['type_file_2'] == "Image" ? null :
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_2', index, e.target.value) } } value={ contents[index]['video_2'] } />
                                                <span className="name_input">Link do vídeo</span>
                                            </div>
                                            {
                                                contents[index]['video_2'] == '' ? null :
                                                <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_2'], contents[index]['video_2']) } }>
                                                    {
                                                        contents[index]['type_file_2'] == 'Vimeo' ?
                                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }   
                                </div>

                                <div className="add_project" style={ { marginTop: '16px' } }>
                                    <div className="list_input_data">
                                        <div className="div_input">
                                            <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_file_3', index, e.target.value) } } value={ contents[index]['type_file_3'] }>
                                                <option value="">#</option>
                                                <option value="Image">Imagem</option>
                                                <option value="Vimeo">Vimeo</option>
                                                <option value="Youtube">Youtube</option>
                                            </select>
                                            <span className="name_input">Tipo</span>
                                        </div>
                                        {
                                            contents[index]['type_file_3'] == "Image" ? 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('name_3', index, e.target.value) } } value={ contents[index]['name_3'] } />
                                                    <span className="name_input">Nome</span>
                                                </div>
                                                <span className="div_input div_add_img">
                                                    <div className={ contents[index]['status_3'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            {
                                                                contents[index]['status_3'] == true ? "Imagem adicionada" : "Adicionar imagem*"
                                                            }
                                                            <input type="file" onChange={ (e)=>{ PopUpCrop(1, '_3', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    {
                                                        contents[index]['img_3'] !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ HandleDataContents('img_3', index, ""); } }>
                                                                    <SvgDelete className="icons" color="#ffffff" />
                                                                </div>
                                                                {
                                                                    contents[index]['status_3'] == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(contents[index]['img_3'])) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "service/" + contents[index]['img_3']) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                            </>
                                                        : null
                                                    }
                                                </span>
                                            </> : 
                                            <>
                                                <div className="div_input space_div">
                                                    <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title_3', index, e.target.value) } } value={ contents[index]['title_3'] } />
                                                    <span className="name_input">Título</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {
                                        contents[index]['type_file_3'] == "Image" ? null :
                                        <div className="list_input_data">
                                            <div className="div_input space_div">
                                                <input type="text" className="name_img" onChange={ (e)=>{ HandleDataContents('video_3', index, e.target.value) } } value={ contents[index]['video_3'] } />
                                                <span className="name_input">Link do vídeo</span>
                                            </div>
                                            {
                                                contents[index]['video_3'] == '' ? null :
                                                <div className="div_input" onClick={ ()=>{ OpenFile(contents[index]['type_file_3'], contents[index]['video_3']) } }>
                                                    {
                                                        contents[index]['type_file_3'] == 'Vimeo' ?
                                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }   
                                </div> 
                            </div>
                        </div>
                    </>
                )
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Services(idPage, idUser, code, name, cash, textBudg, textPres, link, newCategory, contents, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'services');
        SetListPag('currentPageId', 'remuve');
    }

    return(
        <div className="Page_Service_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Serviços
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <div className="highlight_title_page">
                        {
                            idPage == 0 ? "Novo serviço" : code
                        }
                    </div>
                    <div className="list_opt_alt_page">
                        {
                            idPage == 0 ? null :
                            <div className="div_icons" onClick={ ()=>{ CopyServices() } }>
                                <SvgCopy color="#324d6b" className="icons" />
                            </div>
                        }
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>
                
                <div className="show_page_data" style={ { marginTop:0 } }>
                    <div className="list_type_data">
                        <div className="type_title">
                            Dados para orçamento
                        </div>
                    </div>      
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="tag" onChange={ (e)=>{ setCode(e.target.value) } } value={ code != '' ? code.replaceAll(' ', '') : "" } maxLength="6" required />
                            <span className="name_input">Tag</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="year" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome do serviço</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="cash" onChange={ (e)=>{ setCash(e.target.value) } } value={ cash } maxLength="20" required />
                            <span className="name_input">R$ Preço</span>
                        </div>
                    </div>
                    <div className="list_input_data">  
                        <div className="div_input space_div">
                            <JoditEditor className="text_editor" config={ config } value={ textBudg ? textBudg.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setTextBudg(newContent) } />
                        </div>
                    </div>   
                </div>    

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            Lista das categorias
                        </div>
                        <div className="new_data_page" onClick={ ()=>{ setStatusCategory(!statusCategory); } }>
                            {
                                statusCategory == false ? "Adicionar category" : 
                                <span onClick={ ()=>{ setNewCategory([]) } }>Cancelar</span>
                            }
                        </div>
                    </div>
                    {
                        statusCategory == false ? null :
                        <div className="list_input_data" style={ { marginBottom: category.length == 0 ? "0px": "16px" } }>
                            <div className="div_input space_div">
                                <Select className="space_div" defaultOptions options={ ListCategory() } onChange={ (e)=> { setNewCategory(e) } } placeholder="..." isMulti />
                            </div>
                        </div>
                    }
                    <div className="list_name_page">
                        {
                            category.length > 0 ?
                                category.map((key, index)=>{
                                    return(
                                        <div className="div_name_page_access" key={ index }>
                                            <div className="">
                                                { key.name }
                                            </div>
                                            <div className="" onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    )
                                })
                            :
                            statusCategory == true ? null : <div className="no_data">Nenhuma categoria adicionada até o momento...</div>
                        }
                    </div>
                </div> 

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            Dados para apresentação
                        </div>
                    </div> 
                    <div className="list_input_data">    
                        <div className="div_input space_div">
                            <JoditEditor className="text_editor" config={ config } value={ textPres ? textPres.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setTextPres(newContent) } />
                        </div>     
                    </div> 
                    
                    <div className="list_input_data" style={ { marginTop: '20px' } }>
                        {
                            contents.length == 0 ? 
                                <div className="add_project">
                                    {                                                
                                        optFile.map((elem, index)=>{
                                            return(
                                                <div className="div_opt" key={ index } onClick={ ()=>{ AddNewFile(elem) } }>
                                                    <img alt={ "img_" + index } src={ "./assets/example/" + elem } className="img_opt" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            :
                            contents.map((elem, index)=>{
                                return(                                            
                                    <div className="div_data_contents" key={ index }>
                                        <div className="return_circle" onClick={ ()=>{ setContents([]) } }>
                                            <SvgReturnCircle className="icons" color="#f00000" />
                                        </div>
                                        {
                                            ShowDataType(elem.type, index)
                                        }
                                    </div> 
                                )
                            })
                        }
                    </div>
                </div>   

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            Link de referência
                        </div>
                    </div>
                    {
                        link.map((key, index)=>{
                            return(
                                <div className="list_input_data" key={ index } style={ { marginBottom: '10px' } }>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleLink(index, e.target.value) } } value={ key.link.replaceAll(' ', '') } />
                                        <span className="name_input">Link</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ DeleteLink(key.id, index) } }>
                                        <SvgDelete color="#F00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="list_input_data">  
                        <div className="add_project">
                            <div className="add_data" onClick={ ()=>{ AddNewLink() } }>
                                <SvgAddData color="#F00000" className="icons" />
                            </div>
                        </div>
                    </div>
                </div> 
            </form>
            <PopUp_NewCategory />
        </div>
    )
}