import React, { useState, useEffect } from "react";

import './DetailsTasks.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { configText } from "fixedData";

import { SvgClose, SvgDelete } from "components/SvgFile";

import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Reg_MenuTasks } from "services/RegisterData";

export default function PopUP_DetailsTasks(props){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));

    const [ typeTasks, setTypeTasks ]     = useState('');
    const [ responsible, setResponsible ] = useState([{ "value": "" + GetUserData('id') + "", "label": GetUserData('name') }]);
    const [ finalDate, setFinalDate ]     = useState('');
    const [ priority, setPriority ]       = useState('');
    const [ dataId, setDataId ]           = useState(0);
    const [ dataName, setDataName ]       = useState('Geral');
    const [ title, setTitle ]             = useState('');
    const [ text, setText ]               = useState('');
    
    const [ status, setStatus ]     = useState('Aguardando');
    const [ motive, setMotive ]     = useState('');
    const [ feedback, setFeedback ] = useState('');
    const [ obs, setObs ]           = useState('');
    const [ newStart, setNewStart ] = useState('');
    const [ newEnd, setNewEnd ]     = useState('');
    const [ respon, setRespon ]     = useState([]);

    function ShowListOption(){
        const newData = [{ "value": 0, "label": "Geral" }];
        switch (typeTasks) {
            case "portfolio":            
                    GetDataPage('portfolio').map((elem, index)=>{
                        newData.push({ "value": elem.id, "label": "Ano: " + elem.year + " - " + elem.project });
                    })
                break;
            case "proposal":          
                    GetDataPage('proposal').map((elem, index)=>{
                        newData.push({ "value": elem.id, "label": "Ano: " + elem.year + " - " + elem.title });
                    })
                break;
            case "budget":          
                    GetDataPage('budget').map((elem, index)=>{
                        let nameBudget = "#EST-" + elem.code;
                        if(elem.version !=0){
                            nameBudget = nameBudget + " Revisão " + elem.version;
                        }
                        nameBudget = nameBudget + " - " + elem.subject + " - Status: " + elem.nameStatus;

                        newData.push({ "value": elem.id, "label": nameBudget });
                    })
                break;
        }
        return newData;
    }

    function SaveData(event){
        event.preventDefault(); 
        if(responsible.length == 0){
            SetModalData('ReturnResponse', { "page": "missingData", "text": "É necessário definir ao menos um responsável pela tarefa..." });
            SetModalState('ReturnResponse', true);   
        }else {   
            event.preventDefault();      
            setLoading(true);
            Reg_MenuTasks(userId, modalData.id, typeTasks, responsible, modalData.start, finalDate, priority, dataId, title, text, status, motive, feedback, newStart, newEnd, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
    }

    function DeleteTasks(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "list_tasks", "type": "delete_date", "name": value });
        SetModalState('Confirmation', true);
    }

    function CallbackSuccess(){
        ClosePopUp();
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ShowResponsible(){
        const newData = [];
        GetDataPage('access_dash').map((elem, index)=>{
            newData.push({ "value": elem.id, "label": elem.name });
        })
        return newData;
    }

    function AddTypeTasks(value){
        if(value == "commercial"){
            // 2 - ID Ricardo 
            if(responsible.find(item => item.value == "2")){ }else {
                responsible.push({ "value": "2", "label": "Ricardo" });
            }
            // 7 - ID Lucas 
            if(responsible.find(item => item.value == "7")){ }else {
                responsible.push({ "value": "7", "label": "Lucas Rocha" });
            }
            // 9 - ID Shaw
            if(responsible.find(item => item.value == "9")){ }else {
                responsible.push({ "value": "9", "label": "Shaw" });
            }
        }
        setTypeTasks(value);
    }

    function ClosePopUp(){
        SetModalState('DetailsTasks', false);
    }

    useEffect(()=>{
        RegisterModalData('DetailsTasks', setModaldata);
        RegisterModalObserver('DetailsTasks', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            if(modalData.type != "new_tasks"){
                setTypeTasks(modalData.type);
                setFinalDate(modalData.end_only);
                setPriority(modalData.priority);
                if(modalData.id_given == 0){
                    setDataId(0);
                    setDataName("Geral");
                }else {                  
                    setDataId(modalData.id_given);
                    setDataName(modalData.name_given);
                }
                setTitle(modalData.title);
                setText(modalData.text);
                
                setStatus(modalData.status);
                setMotive(modalData.motive);
                setFeedback(modalData.feedback);
                setObs(modalData.obs);
                setRespon(modalData.responsible);
            }else {
                setTypeTasks('');
                setFinalDate('');
                setPriority('');
                setDataId(0);
                setDataName('Geral');
                setTitle('');
                setText('');
                
                setStatus('Aguardando');
                setMotive('');
                setFeedback('');
                setObs('');
                setNewStart('');
                setNewEnd('');
            }
        }
    }, [showPopUp]);

    useEffect(()=>{
        if(showPopUp == true){
            if(typeTasks != "alert" || typeTasks != "general" || typeTasks != "commercial" || typeTasks != ""){
                ShowListOption();
            }
        }
    }, [typeTasks]);

    return (
        (showPopUp ?
            <>
                <form className="PopUp" onSubmit={ SaveData }>
                    <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                        <img alt="loading" src="./assets/loading.gif" className="loading" />
                    </div>
                    <div className="all details_tasks">
                        <div className="div_data type_div">
                            <div className="title">
                                { modalData.title }
                                {
                                    modalData.type == "new_tasks" ? " - " + modalData.start_br : null
                                }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                {
                                    modalData.type == "new_tasks" ? 
                                    <>
                                        <div className="list_input_data">
                                            <div className="div_input">
                                                <select className="space_select" onChange={ (e)=>{ AddTypeTasks(e.target.value) } } value={ typeTasks } required>
                                                    <option value="">#</option>
                                                    <option value="alert">Alerta</option>
                                                    <option value="commercial">Comercial</option>
                                                    <option value="general">Geral</option>
                                                    <option value="portfolio">Portfólios</option>
                                                    <option value="proposal">Propostas</option>
                                                    <option value="budget">Orçamentos</option>
                                                </select>
                                                <span className="name_input">Origem</span>
                                            </div>
                                            <div className="div_input">
                                                <input type="date" className="disabled" value={ modalData.start } readOnly />
                                                <span className="name_input">Início</span>
                                            </div>
                                            <div className="div_input">
                                                <input type="date" className="" onChange={ (e)=>{ setFinalDate(e.target.value) } } value={ finalDate } required />
                                                <span className="name_input">Término</span>
                                            </div>
                                            <div className="div_input">
                                                <select className="priority" onChange={ (e)=>{ setPriority(e.target.value) } } value={ priority } required>
                                                    <option value="">#</option>
                                                    <option value="low">Baixa</option>
                                                    <option value="average">Média</option>
                                                    <option value="high">Alta</option>
                                                </select>
                                                <span className="name_input">Prioridade</span>
                                            </div>
                                            <div className="div_input">
                                                <select className="status" onChange={ (e)=>{ setStatus(e.target.value) } } value={ status } required>
                                                    <option value="Aguardando">Aguardando</option>
                                                    <option value="Finalizado">Finalizado</option>
                                                    <option value="Cancelado">Cancelado</option>
                                                </select>
                                                <span className="name_input">Status</span>
                                            </div>
                                        </div>
                                        {
                                            typeTasks == "alert" || typeTasks == "general" || typeTasks == "commercial" || typeTasks == "" ? null :
                                            <div className="list_input_data space_div">
                                                <div className="div_input space_div" style={ { textAlign: "left" } }>
                                                    <Select className="space_div" defaultOptions options={ ShowListOption() } value={ { "value": dataId, "label": dataName } } onChange={ (e)=> { setDataId(e.value); setDataName(e.label) } } placeholder="..." />
                                                    <span className="name_input">Selecione
                                                        {
                                                            typeTasks == "portfolio" ? " um portfólio" :
                                                                typeTasks == "proposal" ? " uma proposta" : " um orçamento"
                                                        }
                                                    </span>
                                                </div>                                                
                                            </div>
                                        }
                                        
                                        <div className="list_input_data">
                                            <div className="div_input space_div" style={ { textAlign: "left" } }>
                                                <Select className="space_div" defaultOptions options={ ShowResponsible() } onChange={ (e)=> { setResponsible(e); } } value={ responsible } placeholder="..." isMulti />
                                                <span className="name_input">Responsáveis</span>
                                            </div>
                                        </div>

                                        <div className="list_input_data space_div">
                                            <div className="div_input space_div">
                                                <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } required />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data space_div">
                                            <div className="div_input space_div">
                                                <JoditEditor config={ configText } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } required />
                                            </div>
                                        </div>
                                    </>
                                    : 
                                    <>
                                        <div className="list_input_data">
                                            <div className="div_input">
                                                <select className="space_select disabled" value={ typeTasks } disabled>
                                                    <option value="">#</option>
                                                    <option value="alert">Alerta</option>
                                                    <option value="general">Geral</option>
                                                    <option value="portfolio">Portfólios</option>
                                                    <option value="proposal">Propostas</option>
                                                    <option value="budget">Orçamentos</option>
                                                </select>
                                                <span className="name_input">Origem</span>
                                            </div>
                                            <div className="div_input">
                                                <input type="date" className="disabled" value={ modalData.start_only } readOnly />
                                                <span className="name_input">Início</span>
                                            </div>
                                            <div className="div_input">
                                                <input type="date" className="disabled" value={ finalDate } readOnly />
                                                <span className="name_input">Término</span>
                                            </div>
                                            <div className="div_input">
                                                <select className="priority" onChange={ (e)=>{ setPriority(e.target.value) } } value={ priority } required>
                                                    <option value="low">Baixa</option>
                                                    <option value="average">Média</option>
                                                    <option value="high">Alta</option>
                                                </select>
                                                <span className="name_input">Prioridade</span>
                                            </div>
                                            <div className="div_input">
                                                <select className="status" onChange={ (e)=>{ setStatus(e.target.value) } } value={ status } required>
                                                    <option value="Aguardando">Aguardando</option>
                                                    <option value="Finalizado">Finalizado</option>
                                                    <option value="Cancelado">Cancelado</option>
                                                    {
                                                        modalData.type == "new_tasks" ? null : <option value="Reagendado">Reagendar</option>
                                                    }
                                                </select>
                                                <span className="name_input">Status</span>
                                            </div>
                                            <div className="div_input" onClick={ ()=>{ DeleteTasks(modalData.id, title) } }>
                                                <SvgDelete className="icons" color="#f00000" />
                                            </div>
                                        </div>
                                        
                                        {
                                            status != "Aguardando" ? 
                                            <div className="list_input_data space_div show_data_text">
                                                {
                                                    status == "Finalizado" ?
                                                    <div className="space_div space_column">
                                                        <span className="">Feedback</span>
                                                        <JoditEditor config={ configText } value={ feedback ? feedback.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setFeedback(newContent) } required />
                                                    </div> :
                                                    <>
                                                        {
                                                            status == "Reagendado" ? 
                                                            <div className="space_column space_row">
                                                                <div className="div_input">
                                                                    <input type="date" className="rescheduled" onChange={ (e)=>{ setNewStart(e.target.value) } } value={ newStart } required />
                                                                    <span className="name_input">Início</span>
                                                                </div>
                                                                <div className="div_input">
                                                                    <input type="date" className="rescheduled" onChange={ (e)=>{ setNewEnd(e.target.value) } } value={ newEnd } required />
                                                                    <span className="name_input">Término</span>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                        <div className="space_div space_column">
                                                            <span className="">Motivo</span>
                                                            <JoditEditor config={ configText } value={ motive ? motive.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setMotive(newContent) } required />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            : null
                                        }

                                        {
                                            typeTasks == "alert" || typeTasks == "general" || typeTasks == "" ? null :
                                            <div className="list_input_data space_div">
                                                <div className="div_input space_div">
                                                    <input type="text" className="disabled" value={ dataName } readOnly />
                                                    <span className="name_input">
                                                        {
                                                            typeTasks == "portfolio" ? "Portfólio" : typeTasks == "proposal" ? "Proposta" : "Orçamento"
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        <div className="list_input_data space_div">
                                            <div className="div_input space_div">
                                                <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } required />
                                                <span className="name_input">Título</span>
                                            </div>
                                        </div>
                                        <div className="list_input_data space_div">
                                            <div className="div_input space_div">
                                                <JoditEditor config={ configText } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } required />
                                            </div>
                                        </div>
                                        {
                                            respon.length > 0 ?
                                            <>
                                                <div className="list_responsible">
                                                    Lista dos responsáveis pela tarefa
                                                </div>
                                                <div className="list_input_data space_responsible">
                                                    {                                                    
                                                        respon.map((elem, index)=>{
                                                            return(
                                                                <div className="div_input space_div" key={ index }>
                                                                    <input type="text" className="disabled" value={ elem.name } readOnly />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </>
                                            : null
                                        }
                                        {
                                            obs == "" ? null :
                                            <div className="list_input_data space_div">
                                                <div className="list_responsible">
                                                    Motivos do reagendamento
                                                </div>
                                                <div className="div_input space_div space_column" dangerouslySetInnerHTML={ { __html: obs ? obs.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                            </div>
                                        }
                                    </>
                                }

                                <button className="register_data button_btn">
                                    Cadastrar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
