import { useState, useEffect } from "react";

import './Clients.css';

import Select from 'react-select';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgArchive, SvgArrowSearch, SvgDelete, SvgEdit, SvgFilter, SvgSearch, SvgSetaRight } from "components/SvgFile";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { GetUserData } from "interface/Users";

export default function Page_Clients(){

    const date = new Date();

    const [ search, setSearch ]             = useState('');
    const [ dataPage, setDataPage ]         = useState(GetDataPage('client'));
    const [ showDataPage, setShowDataPage ] = useState(InitialData(1));
    const [ disabled, setDisabled ]         = useState(InitialData(0));
    const [ showQtd, setShowQtd ]           = useState(50);
    
    const [ tableTd, setTableTd ] = useState(TypeTD());

    const [ itensPage, setItensPage ] = useState(showQtd);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showDataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showDataPage.slice(startItens, endItens);

    const [ status, setStatus ]                 = useState(false);
    const [ searchYear, setSearchYear ]         = useState('...');
    const [ searchBudget, setSearchBudget ]     = useState('...');
    const [ searchProposal, setSearchProposal ] = useState('...');

    function TypeTD(){
        const dataTD  = [];
        const optTD   = GetDataPage('list_opt_td').filter(item => item.origin == "client");        
        const newData = GetDataPage("access_dash").find(item => item.id == GetUserData('id')).list_table_td;
        optTD.map((elem, index)=>{
            if(newData.find(item => item.id_given == elem.id)){
                dataTD.push(elem.page);
            }
        });
        return dataTD;
    }

    function InitialData(value){
        const newData = [];
        dataPage.map((elem, index)=>{
            if(elem.status == value){                
                newData.push(elem);
            }
        });
        return newData;
    }

    function ListYear(){
        const newData = [];
        GetDataPage('client').map((elem, index)=>{
            if(elem.status == 1){
                if(elem.customer_since > 1){
                    newData.push(elem.customer_since)
                }
            }
        });
        const options = [{ "value": "n/d", "label": "..." }];
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        duplicate.map((elem, index)=>{
            options.push({ "value": elem, "label": elem });
        })
        return options;
    }
    
    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('client').forEach(item =>{
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.corporate_name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.acting_area.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.customer_since.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.note.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowDataPage(duplicate);
        }else if(value == ''){
            setShowDataPage(GetDataPage('client'));
        }
        setSearch(value);
        
        setStatus(false);
        setSearchYear('...');
        setSearchBudget('...');
        setSearchProposal('...');
    }

    function SearchFilter(type){
        if(type == 0){
            const newData = [];
            {
                GetDataPage('client').forEach(item =>{
                    if(searchYear !='...'){
                        if(item.customer_since.toLowerCase().indexOf(searchYear.toLowerCase()) != -1){
                            if(searchBudget == 'Sim'){
                                if(item.budget.length > 0){
                                    if(searchProposal == 'Sim'){
                                        if(item.proposal.length > 0){
                                            newData.push(item.id);
                                        }
                                    }else {
                                        if(searchProposal == 'Não'){
                                            if(item.proposal.length == 0){
                                                newData.push(item.id);
                                            }
                                        }else {
                                            newData.push(item.id);
                                        } 
                                    }
                                }

                            }else {
                                if(searchBudget == 'Não'){
                                    if(item.budget.length == 0){
                                        if(searchProposal == 'Sim'){
                                            if(item.proposal.length > 0){
                                                newData.push(item.id);
                                            }
                                        }else {
                                            if(searchProposal == 'Não'){
                                                if(item.proposal.length == 0){
                                                    newData.push(item.id);
                                                }
                                            }else {
                                                newData.push(item.id);
                                            }                                        
                                        }
                                    }
                                }else {
                                    if(searchProposal == 'Sim'){
                                        if(item.proposal.length > 0){
                                            newData.push(item.id);
                                        }
                                    }else {
                                        if(searchProposal == 'Não'){
                                            if(item.proposal.length == 0){
                                                newData.push(item.id);
                                            }
                                        }else {
                                            newData.push(item.id);
                                        } 
                                    }
                                }
                            }
                        }

                    }else {
                        if(searchBudget == 'Sim'){
                            if(item.budget.length > 0){
                                if(searchProposal == 'Sim'){
                                    if(item.proposal.length > 0){
                                        newData.push(item.id);
                                    }
                                }else {
                                    if(searchProposal == 'Não'){
                                        if(item.proposal.length == 0){
                                            newData.push(item.id);
                                        }
                                    }else {
                                        newData.push(item.id);
                                    } 
                                }
                            }

                        }else {
                            if(searchBudget == 'Não'){
                                if(item.budget.length == 0){
                                    if(searchProposal == 'Sim'){
                                        if(item.proposal.length > 0){
                                            newData.push(item.id);
                                        }
                                    }else {
                                        if(searchProposal == 'Não'){
                                            if(item.proposal.length == 0){
                                                newData.push(item.id);
                                            }
                                        }else {
                                            newData.push(item.id);
                                        }                                        
                                    }
                                }
                            }else {
                                if(searchProposal == 'Sim'){
                                    if(item.proposal.length > 0){
                                        newData.push(item.id);
                                    }
                                }else {
                                    if(searchProposal == 'Não'){
                                        if(item.proposal.length == 0){
                                            newData.push(item.id);
                                        }
                                    }else {
                                        newData.push(item.id);
                                    } 
                                }
                            }
                        }
                    }
                })
            }
            
            const showData = [];
            newData.map((elem, index)=>{
                if(GetDataPage('client').find(item => item.id == elem)){
                    showData.push(GetDataPage('client').find(item => item.id == elem));
                }
            });
            setShowDataPage(showData);

        }else if(type == 1){
            SearchInput('');
            setStatus(false);
            setSearchYear('...');
            setSearchBudget('...');
            setSearchProposal('...');
        }
    }

    function NewClients(id){
        SetListPag('currentPage', 'client_details')
        SetListPag('currentPageId', id);
    }

    function NewHistoric(){
        SetModalData("Historic", { "origin": "client", "type_register": "reactivate", "type": "Clientes desativados", "data": disabled });
        SetModalState('Historic', true);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "client", "type": "delete_client", "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('client', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('client'));
        setShowDataPage(InitialData(1));
        setDisabled(InitialData(0));
    }, [dataPage]);

    return(
        <div className="Page_Clients">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Clientes
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar..." />
                    <div data-tooltip-id="filter_seatch" data-tooltip-content="Filtrar pesquisa" data-tooltip-place="top" className="" onClick={ ()=>{ setStatus(true); setSearch(''); } }>
                        {
                            status == false ? 
                            <SvgFilter color="#606875" className="icons" /> :
                            <SvgArrowSearch color="#606875" className="icons" />
                        }
                    </div>
                    <Tooltip id="filter_seatch" />
                </div>
                <div className="list_opt_alt_page">
                    {
                        disabled.length == 0 ? null :
                        <>
                            <div data-tooltip-id="historic_" data-tooltip-content="Clique aqui para visualizar os clientes desativados" data-tooltip-place="top" className="" onClick={ ()=>{ NewHistoric() } }>
                                <SvgArchive color="#606875" className="icons" />
                            </div>
                            <Tooltip id="historic_" />
                        </>
                    }
                    <div className="new_block_text" onClick={ ()=>{ NewClients(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">cliente</div>
                    </div>
                </div>
            </div>

            {
                status == false ? null : 
                <div className="div_filter">
                    <div className="show_page_data" style={ { marginBottom: "20px" } }>
                        <div className="list_input_data">
                            <div className="div_input">
                                <Select className="year" defaultOptions options={ ListYear() } onChange={ (e)=> { setSearchYear(e.label); } } value={ { "value": searchYear, "label": searchYear } } placeholder="..." />
                                <span className="name_input">Ano</span>
                            </div>
                            <div className="div_input">
                                <Select className="budget" defaultOptions options={[ { "value": "Sim", "label": "Sim" }, { "value": "Não", "label": "Não" } ]} onChange={ (e)=> { setSearchBudget(e.label); } } value={ { "value": searchBudget, "label": searchBudget } } placeholder="..." />
                                <span className="name_input">Orçamento solicitado</span>
                            </div>
                            <div className="div_input">
                                <Select className="budget" defaultOptions options={[ { "value": "Sim", "label": "Sim" }, { "value": "Não", "label": "Não" } ]} onChange={ (e)=> { setSearchProposal(e.label); } } value={ { "value": searchProposal, "label": searchProposal } } placeholder="..." />
                                <span className="name_input">Proposta solicitada</span>
                            </div>
                            <div className="div_input" onClick={ ()=>{ SearchFilter(0) } }>
                                <div className="new_data_page">Pesquisar</div>                            
                            </div>
                            <div className="div_input" onClick={ ()=>{ SearchFilter(1) } }>
                                <div className="new_block_text">Limpar filtro</div>                            
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="list_table">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        {
                            tableTd.length > 0 ?
                            <tr>
                                <th width="20" align="center">#</th>
                                {
                                    tableTd.map((elem, index)=>{
                                        switch (elem) {
                                            case "name":
                                                return <th key={ index }>Cliente</th>;

                                            case "cnpj":
                                                return <th width="200" key={ index }>CNPJ</th>;

                                            case "corporate_name":
                                                return <th width="200" key={ index }>Razão Social</th>;

                                            case "acting_area":
                                                return <th key={ index }>Área de atuação</th>;

                                            case "customer_since":
                                                return <th width="40" key={ index }>Cliente desde</th>;

                                            case "end_budget":
                                                return <th width="150" key={ index }>Último orçamento</th>;

                                            case "logotipo":
                                                return <th width="50" key={ index }>Logo</th>;
                                        }
                                    })
                                }
                                <th width="50" align="right">#</th>
                            </tr> : 
                            <tr>
                                <th width="20" align="center">#</th>
                                <th>Cliente</th>
                                <th width="200">Razão Social</th>
                                <th width="150">Último orçamento</th>
                                <th width="50" align="right">#</th>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ? 
                                currentItens.map((key, index)=>{
                                    if(tableTd.length > 0){
                                        return(
                                            <tr key={ index }>
                                                <td align="center">{ startItens + index + 1 }</td>
                                                
                                                {
                                                    tableTd.map((elem, index)=>{
                                                        switch (elem) {
                                                            case "name":
                                                                return <td key={ index }>{ key.name }</td>;

                                                            case "cnpj":
                                                                return <td key={ index }>{ key.cnpj }</td>;

                                                            case "corporate_name":
                                                                return <td key={ index }>{ key.corporate_name ? key.corporate_name : "---" }</td>;

                                                            case "acting_area":
                                                                return <td key={ index }>{ key.acting_area }</td>;

                                                            case "customer_since":
                                                                return <td key={ index }>{ key.customer_since }</td>;

                                                            case "end_budget":
                                                                return <td key={ index }>{ key.last_purchase_br ? key.last_purchase_br : "---" }</td>;
                                                        }
                                                    })
                                                }

                                                <td align="right">
                                                    <div className="list_opt">
                                                        <div data-tooltip-id={ "delete_" + key.id } data-tooltip-content="Deletar cliente" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                            <SvgDelete color="#f00000" className="icons" />
                                                        </div>
                                                        <Tooltip id={ "delete_" + key.id } />
                                                        <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Editar cliente" data-tooltip-place="top" onClick={ ()=>{ NewClients(key.id) } }>
                                                            <SvgEdit color="#324d6b" className="icons" />
                                                        </div>
                                                        <Tooltip id={ "edit_" + key.id } />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }else {
                                        return(
                                            <tr key={ index }>
                                                <td align="center">{ startItens + index + 1 }</td>
                                                <td>{ key.name }</td>
                                                <td>{ key.corporate_name ? key.corporate_name : "---" }</td>
                                                <td>{ key.last_purchase_br ? key.last_purchase_br : "---" }</td>
                                                <td align="right">
                                                    <div className="list_opt">
                                                        <div data-tooltip-id={ "delete_" + key.id } data-tooltip-content="Deletar cliente" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                            <SvgDelete color="#f00000" className="icons" />
                                                        </div>
                                                        <Tooltip id={ "delete_" + key.id } />
                                                        <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Editar cliente" data-tooltip-place="top" onClick={ ()=>{ NewClients(key.id) } }>
                                                            <SvgEdit color="#324d6b" className="icons" />
                                                        </div>
                                                        <Tooltip id={ "edit_" + key.id } />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })
                            :
                            <tr>
                                <td colSpan="5">
                                    <div className="no_data" style={ { marginTop: 0 } }>Nenhum cliente encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showDataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}