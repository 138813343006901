import React, { useState, useEffect } from "react";

import './AltOrderTable.css';

import { SvgClose } from "components/SvgFile";

import { GetUserData } from "interface/Users";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

export default function PopUP_AltOrderTable(props){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));
    
    const [ orderTD, setOrderTD ] = useState([]);

    function Salvar(){
        setLoading(true);
        // Reg_AltVersionBudget(id, version, userId, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('AltOrderTable', false);
    }

    useEffect(()=>{
        RegisterModalData('AltOrderTable', setModaldata);
        RegisterModalObserver('AltOrderTable', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                        <img alt="loading" src="./assets/loading.gif" className="loading" />
                    </div>
                    <div className="all alt_order_table">
                        <div className="div_data type_div">
                            <div className="title">
                                Ordem das colunas do { modalData.name }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
