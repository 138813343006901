import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { Reg_AddTd, Reg_UserDash } from "services/RegisterData";
import PopUP_AltOrderTable from "components/PopUp/AltOrderTable";

export default function Page_Users_Details(){

    const [ loading, setLoading ]   = useState(false);

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("access_dash"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ status, setStatus ]   = useState(InitialData('status'));
    const [ name, setName ]       = useState(InitialData('name'));
    const [ email, setEmail ]     = useState(InitialData('email'));
    const [ pass, setPass ]       = useState('');
    const [ file, setFile ]       = useState(InitialData('file'));
    const [ nivel, setNivel ]     = useState(InitialData('nivel'));
    const [ note, setNote ]       = useState(InitialData('note'));
    const [ page, setPage ]       = useState(InitialData('page'));
    const [ tableTd, setTableTd ] = useState(InitialData('list_table_td'));

    const [ changesMade, setChangesMade ] = useState(InitialData('changesMade'));

    const [ statusFile, setStatusFile ] = useState(false);

    const [ addPage, setAddPage ] = useState([]);
    const [ opt, setOpt ]         = useState([
        { "value": "about_chroma/0", "label": "Sobre a chroma" },
        { "value": "client/1", "label": "Cliente" },
        { "value": "portfolio/2", "label": "Portfólio" },
        { "value": "proposal/3", "label": "Proposta" },
        { "value": "services/4", "label": "Serviços" },
        { "value": "budget/5", "label": "Orçamentos" },
        { "value": "report/6", "label": "Relatório" }
    ]);
    const [ statusPage, setStatusPage ] = useState(false);
    
    const [ clientTd, setClientTd ]         = useState([]);
    const [ listClientTd, setListClientTd ] = useState(GetDataPage('list_opt_td').filter(item => item.origin == "client"));

    const [ budgetTd, setBudgetTd ]         = useState([]);
    const [ listBudgetTd, setListBudgetTd ] = useState(GetDataPage('list_opt_td').filter(item => item.origin == "budget"));

    const [ proposalTd, setProposalTd ]         = useState([]);
    const [ listProposalTd, setListProposalTd ] = useState(GetDataPage('list_opt_td').filter(item => item.origin == "proposal"));

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('access', setUserAccess);
        RegisterDataPage('access_dash', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("access_dash"));
        setIdPage(GetListPag('currentPageId'));

        setStatus(InitialData('status'));
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setNivel(InitialData('nivel'));
        setNote(InitialData('note'));
        setPage(InitialData('page'));
        setTableTd(InitialData('list_table_td'))
        setChangesMade(InitialData('changesMade'));

        setClientTd([]);
        setBudgetTd([]);
        setProposalTd([]);
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'page' || type === 'list_table_td' || type === 'changesMade'){
            return [];
        }
        return '';
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatusFile(true);
        }else {
            setFile('');
            setStatusFile(false);
        }
    }

    function ShowOpt(){
        const newData = [];
        opt.map((key, index)=>{
            let namePage = key.value.split('/');
            if(page.find(item => item.page == namePage[0])){ }else {
                newData.push(key);
            }
        })
        return newData;
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "access_dash", "type" : type, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function SaveTd(id_td){
        Reg_AddTd(userId, id_td, ()=>{ }, ()=>{ CallbackError() });
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setPass('');
        setAddPage([]);
        setLoading(false);
        setStatusFile(false);
        setStatusPage(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setPass('');
        setAddPage([]);
        setLoading(false);
        setStatusFile(false);
        setStatusPage(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'access_dash');
        SetListPag('currentPageId', 'remuve');
    }
    
    return(
        <div className="Page_Users_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Usuários
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="16" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="60" required />
                            <span className="name_input">E-mail</span>
                        </div>
                        <div className="div_input">
                            {
                                idPage !=0 ?
                                <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" value={ pass } /> :
                                <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" required />
                            }
                            <span className="name_input">Senha</span>
                        </div>
                        {
                            idPage == userId ? null :
                            <div className="div_input">
                                <select className="data_select" onChange={ (e)=>{ setNivel(e.target.value) } } value={ nivel }>
                                    <option value="0">Usuário</option>
                                    <option value="1">Administrador</option>
                                </select>
                                <span className="name_input">Acesso</span>
                            </div>
                        }
                        <span className="div_input div_add_img">
                            <div className={ statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusFile == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        statusFile == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação</div>
                            <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                        </div>
                    </div>
                </div>
                
                {
                    nivel == 0 ? 
                        <>
                            <div className="show_page_data">
                                <div className="list_type_data">
                                    <div className="type_title">Clientes - Colunas da tabela</div>
                                    <div className="type_title cursor_pointer" onClick={ ()=>{ SetModalData('AltOrderTable', { "type": "client", "name": "Clientes" }); SetModalState('AltOrderTable', true) } }>
                                        Adicionar ou Alterar colunas
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    {
                                        listClientTd.map((elem, index)=>{
                                            if(tableTd.find(item => item.id_given == elem.id)){
                                                return(
                                                    <div className="table_td td_active" key={ index } onClick={ ()=>{ DeleteData('table_td', tableTd.find(item => item.id_given == elem.id).id, elem.name) } }>
                                                        { elem.name }
                                                    </div>
                                                )

                                            }else {
                                                return(
                                                    <div className="table_td" key={ index } onClick={ ()=>{ SaveTd(elem.id) } }>
                                                        { elem.name }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            
                            <div className="show_page_data">
                                <div className="list_type_data">
                                    <div className="type_title">Orçamentos - Colunas da tabela</div>
                                    <div className="type_title cursor_pointer" onClick={ ()=>{ SetModalData('AltOrderTable', { "type": "budget", "name": "Orçamento" }); SetModalState('AltOrderTable', true) } }>
                                        Adicionar ou Alterar colunas
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    {
                                        listBudgetTd.map((elem, index)=>{
                                            if(tableTd.find(item => item.id_given == elem.id)){
                                                return(
                                                    <div className="table_td td_active" key={ index } onClick={ ()=>{ DeleteData('table_td', tableTd.find(item => item.id_given == elem.id).id, elem.name) } }>
                                                        { elem.name }
                                                    </div>
                                                )

                                            }else {
                                                return(
                                                    <div className="table_td" key={ index } onClick={ ()=>{ SaveTd(elem.id) } }>
                                                        { elem.name }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>

                            <div className="show_page_data">
                                <div className="list_type_data">
                                    <div className="type_title">Propostas - Colunas da tabela</div>
                                    <div className="type_title cursor_pointer" onClick={ ()=>{ SetModalData('AltOrderTable', { "type": "proposal", "name": "Propostas" }); SetModalState('AltOrderTable', true) } }>
                                        Adicionar ou Alterar colunas
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    {
                                        listProposalTd.map((elem, index)=>{
                                            if(tableTd.find(item => item.id_given == elem.id)){
                                                return(
                                                    <div className="table_td td_active" key={ index } onClick={ ()=>{ DeleteData('table_td', tableTd.find(item => item.id_given == elem.id).id, elem.name) } }>
                                                        { elem.name }
                                                    </div>
                                                )

                                            }else {
                                                return(
                                                    <div className="table_td" key={ index } onClick={ ()=>{ SaveTd(elem.id) } }>
                                                        { elem.name }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </>
                    : null
                }

                {
                    idPage == 0 ? null :
                        nivel == 1 ? null :
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Acesso as páginas</div>
                                <div className="new_data_page" onClick={ ()=>{ setStatusPage(!statusPage); } }>
                                    { statusPage == true ? "Cancelar" : "Liberar nova página" }
                                </div>
                            </div>                            
                            {
                                statusPage == true ?
                                <div className="list_input_data" style={ { marginBottom: '16px' } }>
                                    <div className="div_space space_div">
                                        <Select className="div_space space_div" defaultOptions options={ ShowOpt() } onChange={ (e)=> { setAddPage(e); } } value={ addPage } placeholder="Clique aqui para visualizar os serviços..." isMulti />
                                    </div>
                                </div>
                                : null
                            }
                            <div className="list_name_page">
                                {
                                    page.length > 0 ?
                                        page.map((key, index)=>{
                                            return(
                                                <div className="div_name_page_access" key={ index }>
                                                    <div className="">
                                                        { key.name }
                                                    </div>
                                                    <div className="" onClick={ ()=>{ DeleteData('delete_page', key.id, key.name) } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :
                                    statusPage == true ? null : <div className="no_data">Nenhuma página liberada até o momento...</div>
                                }
                            </div>
                        </div>
                }
            </form>
            <PopUP_AltOrderTable />
        </div>
    )
}