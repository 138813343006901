import React, { useState, useEffect } from "react";

import './AltStatusTasks.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { configText } from "fixedData";

import { SvgClose, SvgDelete } from "components/SvgFile";

import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Reg_TasksStatus } from "services/RegisterData";

export default function PopUP_AltStatusTasks(props){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));
    
    const [ status, setStatus ]     = useState('Aguardando');
    const [ motive, setMotive ]     = useState('');
    const [ feedback, setFeedback ] = useState('');
    const [ newStart, setNewStart ] = useState('');
    const [ newEnd, setNewEnd ]     = useState('');

    function SaveData(event){
        event.preventDefault();      
        setLoading(true);
        Reg_TasksStatus(userId, modalData.id, status, motive, feedback, newStart, newEnd, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('AltStatusTasks', false);
        
    }

    useEffect(()=>{
        RegisterModalData('AltStatusTasks', setModaldata);
        RegisterModalObserver('AltStatusTasks', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setStatus('Aguardando');
            setMotive('');
            setFeedback('');
            setNewStart('');
            setNewEnd('');
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <form className="PopUp" onSubmit={ SaveData }>
                    <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                        <img alt="loading" src="./assets/loading.gif" className="loading" />
                    </div>
                    <div className="all alt_status_tasks">
                        <div className="div_data type_div">
                            <div className="title">
                                { modalData.title }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">                                    
                                    <div className="div_input">
                                        <select className="status" onChange={ (e)=>{ setStatus(e.target.value) } } value={ status } required>
                                            <option value="Aguardando">Aguardando</option>
                                            <option value="Finalizado">Finalizado</option>
                                            <option value="Cancelado">Cancelado</option>
                                            {
                                                modalData.type == "new_tasks" ? null : <option value="Reagendado">Reagendar</option>
                                            }
                                        </select>
                                        <span className="name_input">Status</span>
                                    </div>
                                    {
                                        status == "Reagendado" ? 
                                        <>
                                            <div className="div_input">
                                                <input type="date" className="rescheduled" onChange={ (e)=>{ setNewStart(e.target.value) } } value={ newStart } required />
                                                <span className="name_input">Início</span>
                                            </div>
                                            <div className="div_input">
                                                <input type="date" className="rescheduled" onChange={ (e)=>{ setNewEnd(e.target.value) } } value={ newEnd } required />
                                                <span className="name_input">Término</span>
                                            </div>
                                        </>
                                        : null
                                    }
                                </div>                                
                                {
                                    status != "Aguardando" ? 
                                    <div className="list_input_data space_div show_data_text">
                                        {
                                            status == "Finalizado" ?
                                            <div className="space_div space_column">
                                                <span className="">Feedback</span>
                                                <JoditEditor config={ configText } value={ feedback ? feedback.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setFeedback(newContent) } required />
                                            </div> :
                                            <div className="space_div space_column">
                                                <span className="">Motivo</span>
                                                <JoditEditor config={ configText } value={ motive ? motive.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setMotive(newContent) } required />
                                            </div>
                                        }
                                    </div>
                                    : null
                                }

                                <button className="register_data button_btn">
                                    Cadastrar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
