import { useState, useEffect, useRef } from "react";

import './About.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgEye, SvgImg, SvgSave, SvgSetaRight, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { Reg_About } from "services/RegisterData";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

export default function Page_About(){

    const editText = useRef();

    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('about_chroma'));

    const [ homeTitle, setHomeTitle ]   = useState(InitialData('home_title'));
    const [ homeType, setHomeType ]     = useState(InitialData('home_type'));
    const [ homeFile, setHomeFile ]     = useState(InitialData('home_file'));
    const [ homeImg, setHomeImg ]       = useState(InitialData('home_img'));
    const [ homeStatus, setHomeStatus ] = useState(false);

    const [ servColor, setServColor ]       = useState(InitialData('serv_color'));
    const [ servTitle, setServTitle ]       = useState(InitialData('serv_title'));
    const [ servSubtitle, setServSubtitle ] = useState(InitialData('serv_subtitle'));
    const [ servImg, setServImg ]           = useState(InitialData('serv_img'));
    const [ servList, setServList ]         = useState(InitialData('list_serv'));

    const [ starColor, setStarColor ]   = useState(InitialData('star_color'));
    const [ starTitle, setStarTitle ]   = useState(InitialData('about_title'));
    const [ starList, setStarList ]     = useState(InitialData('list_star'));
    const [ starFooter, setStarFooter ] = useState(InitialData('list_footer'));

    const [ clientColor, setClientColor ] = useState(InitialData('client_color'));
    const [ brandList, setBrandList ]     = useState(InitialData('list_brand'));
    
    const [ endColor, setEndColor ] = useState(InitialData('end_color'));
    const [ textEnd, setTextEnd ]   = useState(InitialData('text_end'));

    useEffect(()=>{
        RegisterDataPage('about_chroma', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('about_chroma'));

        setHomeTitle(InitialData('home_title'));
        setHomeType(InitialData('home_type'));
        setHomeFile(InitialData('home_file'));
        setHomeImg(InitialData('home_img'));
        setHomeStatus(false);

        setServColor(InitialData('serv_color'));
        setServTitle(InitialData('serv_title'));
        setServSubtitle(InitialData('serv_subtitle'));
        setServImg(InitialData('serv_img'));
        setServList(InitialData('list_serv'));

        setStarColor(InitialData('star_color'));
        setStarTitle(InitialData('about_title'));
        setStarList(InitialData('list_star'));
        setStarFooter(InitialData('list_footer'));

        setClientColor(InitialData('client_color'));
        setBrandList(InitialData('list_brand'));
        
        setEndColor(InitialData('end_color'));
        setTextEnd(InitialData('text_end'));
    }, [dataPage]);

    function InitialData(type){
        return dataPage[type];
    }

    function AddNewData(type){
        switch (type) {
            case 'serv':
                    setServList([...servList, { "id": 0, "title": "", "text": "" }]);
                break;
            
            case 'star':
                    setStarList([...starList, { "id": 0, "title": "", "text": "", "star": 0 }]);
                break;
            
            case 'brand':
                    setBrandList([...brandList, { "id": 0, "title": "", "img": "", "status": false }]);
                break;
        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function AddImg(value){
        if(value !=''){
            setHomeImg(value);
            setHomeStatus(true);
        }else {
            setHomeImg("");
            setHomeStatus(false);
        }
    }

    function Delete(type, index, id, value){
        if(id == 0){
            if(type == 'serv'){
                const newData = [...servList];
                newData.splice(index, 1);
                setServList(newData);
            }
            if(type == 'star'){
                const newData = [...starList];
                newData.splice(index, 1);
                setStarList(newData);
            }
            if(type == 'brand'){
                const newData = [...brandList];
                newData.splice(index, 1);
                setBrandList(newData);
            }
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "about_chroma", "type": "delete_data", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleData(type, input, index, value){
        switch (type) {
            case 'serv':
                    const newData_1 = [...servList];
                    newData_1[index][input] = value;
                    setServList(newData_1);
                break;

            case 'star':
                    const newData_2 = [...starList];
                    newData_2[index][input] = value;
                    setStarList(newData_2);
                break;

            case 'star_footer':
                    const newData_3 = [...starFooter];
                    newData_3[index][input] = value;
                    setStarFooter(newData_3);
                break;

            case 'brand':
                    const newData_4 = [...brandList];
                    newData_4[index][input] = value;
                    if(input == 'img'){
                        if(value){
                            newData_4[index]['status'] = true;
                        }else {
                            newData_4[index]['status'] = false;
                        }
                    }
                    setBrandList(newData_4);
                break;
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_About(homeTitle, homeType, homeFile, homeImg, servColor, servTitle, servSubtitle, servImg, servList, starColor, starTitle, starList, starFooter, clientColor, brandList, textEnd, endColor, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function OpenPreview(){
        window.open(process.env.REACT_APP_API_URL + '?project=proposal/0', '_blank');
    }

    return(
        <div className="Page_About">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Sobre a Chroma
                        </div>
                    </div>
                    <div className="list_opt_alt_page">
                        <div className="icons_project" onClick={ ()=>{ OpenPreview() } }>
                            <div className="preview_eye">
                                <SvgEye color="#E51D4D" className="icons" />
                            </div>
                        </div>
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>

                <div className="obs">
                    Caso quiser quebra de linha no título coloque 
                    <span className="caract">&lsaquo;</span>br /<span className="caract">&rsaquo;</span> entre o texto
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Home</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setHomeTitle(e.target.value) } } value={ homeTitle } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>                        
                        <div className="div_input">
                            <select className="space_select" onChange={ (e)=>{ setHomeType(e.target.value) } } value={ homeType } required>
                                <option value="Image">Imagem</option>
                                <option value="Vimeo">Vídeo Vimeo</option>
                                <option value="Youtube">Vídeo Youtube</option>
                            </select>
                            <span className="name_input">Tipo de capa</span>
                        </div>
                        {
                            homeType != 'Image' ? null :
                            <span className="div_input div_add_img">
                                <div className={ homeStatus == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { homeStatus == true ? "Imagem adicionada" : "Adicionar imagem*" }
                                        <input type="file" onChange={ (e)=>{ AddImg(e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    homeImg !='' ?
                                        <>                                        
                                            <div className="delete_img_add" onClick={ ()=>{ AddImg(''); } }>
                                                <SvgDelete className="icons" color="#ffffff" />
                                            </div>
                                            {
                                                homeStatus == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(homeImg)) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "about/" + homeImg) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div>
                                            }
                                        </>
                                    : null
                                }
                            </span>
                        }
                    </div>
                    {
                        homeType != 'Image' ? 
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ setHomeFile(e.target.value) } } value={ homeFile } />
                                <span className="name_input">Link do vídeo</span>
                            </div>
                            {
                                homeFile == '' ? null :
                                <div className="div_input space_top" onClick={ ()=>{ OpenFile(homeType, homeFile) } }>
                                    {
                                        homeType == 'Vimeo' ?  
                                        <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                        <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                    }
                                </div>
                            }
                        </div> : null
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Serviços</div>
                        <div className="new_data_page" onClick={ ()=>{ AddNewData('serv'); } }>
                            <span className="icons_menu">+</span> serviço
                        </div>
                    </div>
                    
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setServColor(e.target.value) } } value={ servColor } required />
                            <span className="name_input">Cor de fundo</span>
                        </div>
                        <div className="div_input">
                            <div className="div_color" style={ { backgroundColor: servColor } } />
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setServTitle(e.target.value) } } value={ servTitle } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setServSubtitle(e.target.value) } } value={ servSubtitle } maxLength="140" />
                            <span className="name_input">Subtitulo</span>
                        </div>
                    </div>
                    <div className="list_input_data">  
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Serviço oferecido</div>
                            {
                                servList.length > 0 ?
                                    servList.map((key, index) =>{
                                        return(
                                            <div className="list_type_service" key={ index }>
                                                <div className="list_input_data">  
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleData('serv', 'title', index, e.target.value) } } value={ key.title } maxLength="140" />
                                                        <span className="name_input">Título</span>
                                                    </div>
                                                    <div className="div_input" onClick={ ()=>{ Delete('serv', index, key.id, key.title) } } style={ { display: "flex" } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                                <div className="list_input_data">  
                                                    <div className="div_input space_div">
                                                        <JoditEditor config={ config } value={ key.text ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('serv', 'text', index, newContent) } />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                <div className="list_input_data list_type_service">
                                    <div className="no_data">Nenhum serviço encontrado...</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Sobre nós</div>
                        <div className="new_data_page" onClick={ ()=>{ AddNewData('star'); } }>
                            <span className="icons_menu">+</span> avaliação
                        </div>
                    </div>
                    
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setStarColor(e.target.value) } } value={ starColor } required />
                            <span className="name_input">Cor de fundo</span>
                        </div>
                        <div className="div_input">
                            <div className="div_color" style={ { backgroundColor: starColor } } />
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setStarTitle(e.target.value) } } value={ starTitle } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>
                    </div>
                    <div className="list_input_data">  
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Avaliações</div>
                            {
                                starList.length > 0 ?
                                    starList.map((key, index) =>{
                                        return(
                                            <div className="list_type_service" key={ index }>
                                                <div className="list_input_data">  
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleData('star', 'title', index, e.target.value) } } value={ key.title } maxLength="140" />
                                                        <span className="name_input">Nome</span>
                                                    </div>
                                                    <div className="div_input">
                                                        <input type="text" className="star" onChange={ (e)=>{ HandleData('star', 'star', index, e.target.value) } } value={ key.star } maxLength="1" />
                                                        <span className="name_input">Estrelas</span>
                                                    </div>
                                                    <div className="div_input" onClick={ ()=>{ Delete('star', index, key.id, key.title) } } style={ { display: "flex" } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                                <div className="list_input_data">  
                                                    <div className="div_input space_div">
                                                        <JoditEditor config={ config } value={ key.text ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('star', 'text', index, newContent) } />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                <div className="list_input_data list_type_service">
                                    <div className="no_data">Nenhuma avaliação encontrado...</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="list_input_data">  
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Rodapé</div>
                            {
                                starFooter.length > 0 ?
                                    starFooter.map((key, index) =>{
                                        return(
                                            <div className="list_type_service" key={ index }>
                                                <div className="list_input_data">  
                                                    <div className="div_input">
                                                        <input type="text" className="star" onChange={ (e)=>{ HandleData('star_footer', 'sigla', index, e.target.value) } } value={ key.sigla } maxLength="4" />
                                                        <span className="name_input">Sigla</span>
                                                    </div>
                                                    <div className="div_input">
                                                        <input type="text" className="star" onChange={ (e)=>{ HandleData('star_footer', 'number', index, e.target.value) } } value={ key.number } maxLength="10" />
                                                        <span className="name_input">Número</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleData('star_footer', 'title', index, e.target.value) } } value={ key.title } maxLength="140" />
                                                        <span className="name_input">Nome</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                <div className="list_input_data list_type_service">
                                    <div className="no_data">Nenhuma avaliação encontrado...</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Marcas que confiam em nosso time</div>
                        <div className="new_data_page" onClick={ ()=>{ AddNewData('brand'); } }>
                            <span className="icons_menu">+</span> marca
                        </div>
                    </div>
                    
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setClientColor(e.target.value) } } value={ clientColor } required />
                            <span className="name_input">Cor de fundo</span>
                        </div>
                        <div className="div_input">
                            <div className="div_color" style={ { backgroundColor: clientColor } } />
                        </div>
                    </div>

                    {
                        brandList.length > 0 ?
                            brandList.map((key, index)=>{
                                return(
                                    <div className="list_input_data list_inf_data" key={ index }>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleData('brand', 'title', index, e.target.value) } } value={ key.title } maxLength="140" />
                                            <span className="name_input">Título</span>
                                        </div>
                                        <span className="div_input div_add_img">
                                            <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { key.status == true ? "Imagem adicionada" : "Adicionar imagem*" }
                                                    <input type="file" onChange={ (e)=>{ HandleData('brand', 'img', index, e.target.files[0]) } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                key.img !='' ?
                                                    <>                                        
                                                        <div className="delete_img_add" onClick={ ()=>{ HandleData('brand', 'img', index, '') } }>
                                                            <SvgDelete className="icons" color="#ffffff" />
                                                        </div>
                                                        {
                                                            key.status == true ?
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.img)) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "about/" + key.img) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                        }
                                                    </>
                                                : null
                                            }
                                        </span>
                                        <div className="div_input" onClick={ ()=>{ Delete('brand', index, key.id, key.title) } } style={ { display: "flex" } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                        :
                        <div className="list_input_data">
                            <div className="no_data">Nenhuma marca encontrada...</div>
                        </div>
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Texto final</div>
                    </div>
                    
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setEndColor(e.target.value) } } value={ endColor } required />
                            <span className="name_input">Cor de fundo</span>
                        </div>
                        <div className="div_input">
                            <div className="div_color" style={ { backgroundColor: endColor } } />
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor config={ config } value={ textEnd ? textEnd.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setTextEnd(newContent) } required />
                        </div>
                    </div> 
                </div>
            </form>
        </div>
    )
}