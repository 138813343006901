import React, { useState, useEffect } from "react";

import './NewOrEditTextFixed.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgClose } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { Reg_TextFixed } from "services/RegisterData";
import { GetUserData, RegisterUserData } from "interface/Users";

export default function PopUp_TextFixed(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));
    const [ dataPage, setDataPage ]   = useState(GetDataPage('budget_text_fixed'));
    
    const [ type, setType ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ text, setText ]   = useState('');

    useEffect(()=>{
        RegisterModalData('TextFixed', setModaldata);
        RegisterModalObserver('TextFixed', setShowPopUp);

        RegisterUserData('id', setUserId);
        RegisterDataPage('budget_text_fixed', setDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setType(InitialData('type'));
            setTitle(InitialData('title'));
            setText(InitialData('text'));
        }
    }, [showPopUp]);

    function InitialData(type){
        if(modalData.id != 0){
            const newData = dataPage.find(item => item.id == modalData.id);
            return newData[type];
        }
        return '';
    }

    function SaveData(){
        Reg_TextFixed(userId, modalData.id, type, title, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
    }

    function ClosePopUp(){
        SetModalState('TextFixed', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_text_fixed">
                        <div className="div_data type_div">
                            <div className="title">
                                Texto fixo
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data space_div">  
                                    <div className="div_input">
                                        <select className="type_tag" onChange={ (e)=>{ setType(e.target.value); } } value={ type } required>
                                            <option value="">#</option>
                                            <option value="Automatic">Automático</option>
                                            <option value="Selection">Seleção</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                </div>
                                <div className="list_input_data space_div"> 
                                    <div className="div_input space_width space_div">
                                        <input type="text" className="space_width space_div" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                                        <span className="name_input">Título</span>
                                    </div>
                                </div>
                                <div className="list_input_data space_div">
                                    <div className="div_input space_div">
                                        <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } required />
                                    </div>
                                </div>
                                <div className="register_data button_text_fixed space_div" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
