import { useState, useEffect } from "react";

import './Signature.css';

import { SetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { SvgDelete, SvgEdit, SvgSetaRight, SvgSignature } from "components/SvgFile";

import PopUp_Signature from "components/PopUp/NewOrEditSignature";

export default function Page_Budget_Signature(){

    const [ dataPage, setDataPage ] = useState(GetDataPage('budget_signature'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(dataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = dataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('budget_signature', setDataPage);
    }, []);

    function NewSignature(id){
        SetModalData("Signature", { "id": id });
        SetModalState('Signature', true);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "budget_signature", "type": "delete_signature", "name": value });
        SetModalState('Confirmation', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'budget');
    }

    return(
        <div className="Page_Budget">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                        Orçamentos
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page page_return" onClick={ ()=>{ ReturnPage() } }>
                        Assinaturas
                    </div>
                </div>
                <div className="list_opt_alt_page">
                    <div className="new_block_text" onClick={ ()=>{ NewSignature(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Assinatura
                        </div>
                    </div>
                </div>
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Nome</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td>{ startItens + index + 1 }</td>
                                            <td>{ key.name }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ OpenFile('showImg', "signature/" + key.img) } }>
                                                        <SvgSignature color="#324d6b" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ NewSignature(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data" style={ { marginTop: 0 } }>Nenhuma assinatura encontrada...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    dataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUp_Signature />
        </div>
    )

}