import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgArchive, SvgDelete, SvgEdit, SvgEye, SvgHistoric, SvgImg, SvgPdf, SvgSave, SvgSetaRight } from "components/SvgFile";

import { Reg_Client } from "services/RegisterData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { phoneMask } from "services/Mask";

import PopUp_NewContact from "components/PopUp/NewContact";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

export default function Page_Clients_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ idUser, setIdUser ]     = useState(GetUserData("id"));
    const [ dataPage, setDataPage ] = useState(GetDataPage('client'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ statusClient, setStatusClient ]   = useState(InitialData('status'));
    const [ name, setName ]                   = useState(InitialData('name'));
    const [ corporateName, setCorporateName ] = useState(InitialData('corporate_name'));
    const [ cnpj, setCnpj ]                   = useState(InitialData('cnpj'));
    const [ actingArea, setActingArea ]       = useState(InitialData('acting_area'));
    const [ customerSince, setCustomerSince ] = useState(InitialData('customer_since'));
    const [ note, setNote ]                   = useState(InitialData('note'));
    const [ contact, setContact ]             = useState(InitialData('contact'));

    const [ budget, setBudget ]       = useState(ContentData('budget'));
    const [ portfolio, setPortfolio ] = useState(ContentData('portfolio'));
    const [ proposal, setProposal ]   = useState(ContentData('proposal'));

    const [ logoClient, setLogoClient ] = useState(InitialData('logo_client'));
    const [ status, setStatus ]         = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage("client", setDataPage);
        RegisterListPag('currentPageId', setIdPage)
    }, []);

    useEffect(()=>{
        setIdUser(GetUserData("id"));
        setDataPage(GetDataPage('client'));
        setIdPage(GetListPag('currentPageId'));

        setStatusClient(InitialData('status'));
        setName(InitialData('name'));
        setCorporateName(InitialData('corporate_name'));
        setCnpj(InitialData('cnpj'));
        setActingArea(InitialData('acting_area'));
        setCustomerSince(InitialData('customer_since'));
        setNote(InitialData('note'));
        setContact(InitialData('contact'));

        setLogoClient(InitialData('logo_client'));
        setStatus(false);
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'contact'){
            return [];
        }
        return '';
    }

    function ContentData(type){
        if(idPage != 0){
            if(type == 'budget'){
                const typeClient = GetDataPage('budget').filter(item => item.type == 0);
                const newData = typeClient.filter(item => item.idClient == idPage);
                return newData;

            }else if(type == 'portfolio'){
                const newData = GetDataPage('portfolio').filter(item => item.client == idPage);
                return newData;

            }else if(type == 'proposal'){
                const newData = GetDataPage('proposal').filter(item => item.client == idPage);
                return newData;
            }
        }else {
            return [];
        }
    }

    function NewContact(){
        setContact([...contact, { "id": 0, "name": "", "email": "", "phone": "", "office": "", "file": "", "status": false }]);
    }

    function HandleContact(index, type, value){
        const newData = [...contact];
        if(type == 'file'){
            if(value){
                newData[index]['file']   = value;
                newData[index]['status'] = true;
            }else {
                newData[index]['file']   = '';
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setContact(newData);
    }
    
    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "client", "type": "delete_contact", "name": value });
        SetModalState('Confirmation', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Client(idUser, idPage, name, logoClient, corporateName, cnpj, actingArea, customerSince, note, contact, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'client');
        SetListPag('currentPageId', 'remuve');
    }

    function AddFile(value){
        if(value){
            setStatus(true);
            setLogoClient(value);
        }else {
            setStatus(false);
            setLogoClient('');
        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function DetailsBudget(type, id){
        if(type == 0){
            SetListPag('currentPage', 'budget_details')
            SetListPag('currentPageId', id);
        }else {
            window.open(process.env.REACT_APP_API_URL + '?project=budget/' + id, '_blank');
        }
    }

    function OpenProtfolio(type, id){
        if(type == 0){
            window.open(process.env.REACT_APP_API_URL + '?project=portfolio/' + id, '_blank');
        }else {
            SetListPag('currentPage', 'portfolio_details');
            SetListPag('currentPageId', id);
        }
    }

    function RegisterBudget(idProject, idBudget){
        SetListPag('currentPage', 'budget_details');
        SetListPag('currentPageId', idBudget);
        SetListPag('idNewRegister', idProject);
    }

    function OpenProposal(type, id){
        if(type == 0){
            SetListPag('currentPage', 'proposal_details')
            SetListPag('currentPageId', id);
        }else {
            window.open(process.env.REACT_APP_API_URL + '?project=proposal/' + id, '_blank');
        }
    }

    function Reactivate(){
        SetModalData("Confirmation", { "id": idPage, "origin": "client", "type_register": "reactivate", "type": "reactivate_client", "name": name });
        SetModalState('Confirmation', true);
    }
    
    return(
        <div className="Page_Clients_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Clientes
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>

                    <div className="list_opt_alt_page">
                        {
                            statusClient == 1 ? null :
                            <div className="div_icons" onClick={ ()=>{ Reactivate() } }>
                                <SvgArchive color="#606875" className="icons" />
                            </div>
                        }
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                Salvar
                            </div>
                        </button>
                    </div>
                </div>
                
                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setCorporateName(e.target.value) } } value={ corporateName } maxLength="100" />
                            <span className="name_input">Razão Social</span>
                        </div>
                        
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Logo adicionada" : "Adicionar logo" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } accept="image/*" />
                                </label>
                            </div>
                            {
                                logoClient !='' ?
                                <>                                        
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(logoClient)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "client/logo/" + logoClient) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="cnpj" onChange={ (e)=>{ setCnpj(e.target.value) } } value={ cnpj } maxLength="18" />
                            <span className="name_input">CNPJ</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setActingArea(e.target.value) } } value={ actingArea } maxLength="100" />
                            <span className="name_input">Área de atuação</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="customer_since" onChange={ (e)=>{ setCustomerSince(e.target.value) } } value={ customerSince.replaceAll(/\D/g, '') } maxLength="4" />
                            <span className="name_input">Cliente desde</span>
                        </div>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação</div>
                            <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                        </div>
                    </div>
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Contatos</div>
                        <div className="new_data_page" onClick={ ()=>{ NewContact(); } }>
                            <span className="icons_menu">+</span> contato
                        </div>
                    </div>
                    {
                        contact.length > 0 ?
                            contact.map((key, index)=>{
                                return(
                                    <div className="list_input_data list_inf_data" key={ index }>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleContact(index, 'name', e.target.value) } } value={ key.name } maxLength="80" />
                                            <span className="name_input">Nome</span>
                                        </div>
                                        <div className="div_input">
                                            <input type="email" onChange={ (e)=>{ HandleContact(index, 'email', e.target.value) } } value={ key.email } maxLength="40" />
                                            <span className="name_input">E-mail</span>
                                        </div>
                                        <div className="div_input">
                                            <input type="text" className="phone" onChange={ (e)=>{ HandleContact(index, 'phone', e.target.value) } } value={ key.phone } maxLength="20" />
                                            <span className="name_input">Telefone</span>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleContact(index, 'office', e.target.value) } } value={ key.office } maxLength="50" />
                                            <span className="name_input">Cargo</span>
                                        </div>
                        
                                        <span className="div_input div_add_img">
                                            <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { key.status == true ? "Foto adicionada" : "Adicionar foto" }
                                                    <input type="file" onChange={ (e)=>{ HandleContact(index, 'file', e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                key.file !='' ?
                                                <>
                                                    <div className="delete_img_add" onClick={ ()=>{ HandleContact(index, 'file', ''); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    {
                                                        key.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "client/contact/" + key.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </span>
                                        <div className="div_input" onClick={ ()=>{ DeleteData(key.id, key.name) } } style={ { display: 'flex' } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                        :
                        <div className="list_input_data">
                            <div className="no_data">Nenhum contato encontrado...</div>
                        </div>
                    }
                </div>

                {
                    idPage == 0 ? null :
                    portfolio.length == 0 ? null :
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Portfólios</div>
                        </div>
                        <div className="list_input_data">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20">#</th>
                                        <th>Projeto</th>
                                        <th width="80">Ano</th>
                                        <th width="20" align="right">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        portfolio.map((key, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td align="left">{ index + 1 }</td>
                                                    <td>{ key.project }</td>
                                                    <td>{ key.year }</td>
                                                    <td align="right">
                                                        <div className="list_opt">
                                                            <div onClick={ ()=>{ OpenProtfolio(0, key.id) } }>
                                                                <SvgEye color="#324d6b" className="icons"/>
                                                            </div>
                                                            <div onClick={ ()=>{ OpenProtfolio(1, key.id) } }>
                                                                <SvgEdit color="#324d6b" className="icons"/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                
                {
                    idPage == 0 ? null :
                    budget.length == 0 ? null :
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Orçamentos</div>
                        </div>
                        <div className="list_input_data">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20">#</th>
                                        <th width="200">Status</th>
                                        <th>Código</th>
                                        <th width="80">Data</th>
                                        <th width="80">Valor R$</th>
                                        <th width="20" align="right">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        budget.map((key, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td align="left">{ index + 1 }</td>
                                                    <td>{ key.nameStatus }</td>
                                                    <td>#EST-{ key.code }</td>
                                                    <td>{ key.estimatedDate }</td>
                                                    <td>{ key.cash }</td>
                                                    <td align="right">
                                                        <div className="list_opt">
                                                            <div onClick={ ()=>{ DetailsBudget(1, key.id) } }>
                                                                <SvgPdf color="#F00000" className="icons" />
                                                            </div>
                                                            <div onClick={ ()=>{ DetailsBudget(0, key.id) } }>
                                                                <SvgEdit color="#324d6b" className="icons"/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

                
                {
                    idPage == 0 ? null :
                    proposal.length == 0 ? null :
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Propostas</div>
                        </div>
                        <div className="list_input_data">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th width="180">Status</th>
                                        <th>Projeto</th>
                                        <th width="80">Data</th>
                                        <th width="130">Tipo</th>
                                        <th width="30" align="right">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        proposal.map((key, index)=>{
                                            let classStatus = "";
                                            switch (key.status) {
                                                case 'Contato inicial': classStatus = 'status_1'; break;
                                                case 'Reunião agendada': classStatus = 'status_2'; break;
                                                case 'Proposta enviada': classStatus = 'status_3'; break;
                                                case 'Negociação': classStatus = 'status_4'; break;                                            
                                                case 'Conquistado': classStatus = 'status_5'; break;
                                                case 'Perdido': classStatus = 'status_6'; break;
                                                case 'Refazer proposta': classStatus = 'status_7'; break;                                           
                                                case 'Preparando proposta': classStatus = 'status_8'; break;
                                            }
                                            return(
                                                <tr key={ index }>
                                                    <td align="center">{ index + 1 }</td>
                                                    <td>
                                                        <div className={ "btn_status " + classStatus }>{ key.status }</div>
                                                    </td>
                                                    <td>{ key.title }</td>
                                                    <td>{ key.date_br }</td>
                                                    <td align="right">
                                                        <div className="list_opt_">
                                                            {
                                                                key.id_budget == 0 ?
                                                                <div className="new_budget" onClick={ ()=>{ RegisterBudget(key.id, key.id_budget) } }>+ Orçamento</div> :
                                                                <div className="show_budget" onClick={ ()=>{ RegisterBudget(key.id, key.id_budget) } }>Ver orçamento</div>
                                                            } 
                                                        </div>                                               
                                                    </td>
                                                    <td align="right">
                                                        <div className="list_opt">
                                                            <div onClick={ ()=>{ OpenProposal(0, key.id) } }>
                                                                <SvgEye color="#324d6b" className="icons"/>
                                                            </div>
                                                            <div onClick={ ()=>{ OpenProposal(1, key.id) } }>
                                                                <SvgEdit color="#324d6b" className="icons"/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </form>
            <PopUP_ReturnResponse />
            <PopUp_NewContact />
        </div>
    )
}