import React, { useState, useEffect } from "react";

import './SalesUpdates.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgCheked, SvgClose, SvgDelete, SvgImg } from "components/SvgFile";

import { GetListPag } from "interface/Page";
import { GetUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_Updates } from "services/RegisterData";

export default function PopUP_SalesUpdates(){

    const [ loading, setLoading ]         = useState(false);
    const [ idPage, setIdPage ]           = useState(GetListPag('currentPageId'));
    const [ userId, setUserId ]           = useState(GetUserData('id'));
    const [ update, setUpdate ]           = useState(GetDataPage('budget_update'));
    const [ updateEmail, setUpdateEmail ] = useState(GetDataPage('budget_update_email'));

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);

    const [ linkMonday, setLinkMonday ]               = useState('');
    const [ folderDrive, setFolderDrive ]             = useState('');
    const [ gestResp, setGestResp ]                   = useState('');
    const [ directionCreation, setDirectionCreation ] = useState('');
    const [ text, setText ]                           = useState('');
    const [ listEmail, setListEmail ]                 = useState('');
    const [ updateStatus, setUpdateStatus ]           = useState(false);

    function InitialDataEmail(){
        if(updateEmail.filter(item => item.id_budget == idPage)){
            const newData = updateEmail.filter(item => item.id_budget == idPage);
            return newData;

        }
        return [];
    }

    function InitialData(type){
        if(update.find(item => item.id_budget == idPage)){
            const newData = update.find(item => item.id_budget == idPage);
            return newData[type];

        }
        if(type == "list_email"){
            return [];
        }
        return "";
    }

    function SaveData(){
        setLoading(true);
        Reg_Updates(userId, idPage, linkMonday, folderDrive, gestResp, directionCreation, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setLoading(false);
        SetModalState('SalesUpdates', false);
    }

    function OpenBudget(){
        window.open(process.env.REACT_APP_API_URL + '?project=budget_update/' + idPage, '_blank');
    }

    function UpdateEmail(){
        setUpdateStatus(!updateStatus)
    }

    function SendEmail(){
        SetModalData('SendEmail', { "UpdateEmail": UpdateEmail, "subject": modalData.subject, "year": modalData.year });
        SetModalState('SendEmail', true);
    }

    useEffect(()=>{
        RegisterModalData('SalesUpdates', setModaldata);
        RegisterModalObserver('SalesUpdates', setShowPopUp);

        RegisterDataPage('budget_update', setUpdate);
        RegisterDataPage('budget_update_email', setUpdateEmail);
    }, []);

    useEffect(()=>{
        setUpdateEmail(GetDataPage('budget_update_email'));
        setListEmail(InitialDataEmail());
    }, [updateStatus]);

    useEffect(()=>{
        if(showPopUp == true){
            setUpdate(GetDataPage('budget_update'));
            setUpdateEmail(GetDataPage('budget_update_email'));
            
            setLinkMonday(InitialData('link_monday'));
            setFolderDrive(InitialData('folder_drive'));
            setGestResp(InitialData('gest_resp'));
            setDirectionCreation(InitialData('direction_creation'));
            setText(InitialData('text'));
            setListEmail(InitialDataEmail());
        }
    }, [showPopUp, update]);

    return (
        (showPopUp ?
            <>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <img alt="loading" src="./assets/loading.gif" className="loading" />
                </div>
                <div className="PopUp">
                    <div className="all popup_sales_updates">
                        <div className="div_data type_div">
                            <div className="title">
                                Updates de venda
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data topic_update">
                                    <div className="div_input div_pdf">
                                        <div className="show_pdf" onClick={ ()=>{ SendEmail() } }>
                                            Enviar e-mail
                                        </div>
                                    </div>
                                    <div className="div_input div_pdf">
                                        <div className="show_pdf" onClick={ ()=>{ OpenBudget(idPage) } }>
                                            Visualizar pdf
                                        </div>
                                    </div>
                                </div>
                                <div className="list_input_data space_div">
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setLinkMonday(e.target.value) } } value={ linkMonday } />
                                        <span className="name_input">Link Monday</span>
                                    </div>
                                </div>
                                <div className="list_input_data space_div">
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setFolderDrive(e.target.value) } } value={ folderDrive } />
                                        <span className="name_input">Pasta do drive</span>
                                    </div>
                                </div>
                                <div className="list_input_data space_div">
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setGestResp(e.target.value) } } value={ gestResp } />
                                        <span className="name_input">Gestor responsável</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setDirectionCreation(e.target.value) } } value={ directionCreation } />
                                        <span className="name_input">Direção e criação</span>
                                    </div>
                                </div>
                                <div className="list_input_data space_div">
                                    <div className="div_input space_div">
                                        <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } required />
                                    </div>
                                </div>

                                {
                                    listEmail.length > 0 ? 
                                    <table cellPadding="0" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th width="10">#</th>
                                                <th align="left">Email</th>
                                                <th align="left" width="40">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listEmail.map((elem, index)=>{
                                                    return(
                                                        <tr key={ index }>
                                                            <td>{ index + 1 }</td>
                                                            <td align="left">{ elem.email }</td>
                                                            <td align="left">{ elem.register_date }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    : null
                                }

                                <div className="register_data button_profile" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
