import React, { useState, useEffect } from "react";

import './NewCombo.css';

import { SvgCheked, SvgChekedReturn, SvgClose, SvgDelete, SvgSetaRight } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Reg_NewCombo } from "services/RegisterData";

export default function PopUp_NewCombo(props){

    const [ showServ, setShowServ ]   = useState(GetDataPage('services'));
    const [ showCombo, setShowCombo ] = useState(GetDataPage('services_combo'));

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ name, setName ]           = useState('');
    const [ services, setServices ]   = useState([]);
    const [ qtdHeight, setQtdHeight ] = useState(false);

    useEffect(()=>{
        RegisterModalData('NewCombo', setModaldata);
        RegisterModalObserver('NewCombo', setShowPopUp);
        
        RegisterDataPage('services', setShowServ);
        RegisterDataPage('services_combo', setShowCombo);
    }, []);
    
    useEffect(()=>{
        setShowCombo(GetDataPage('services_combo'));
        if(showPopUp == true){
            setServices(InitialData('services'));
        }
    }, [showCombo]);

    useEffect(()=>{
        if(showPopUp == true){
            setShowServ(GetDataPage('services'));
            setShowCombo(GetDataPage('combo'));

            setName(InitialData('name'));
            setServices(InitialData('services'));
        }
    }, [showPopUp]);

    function InitialData(type){
        if(modalData.id !=0){
            const newData = showCombo.find(item => item.id == modalData.id);      
            return newData[type];
        }
        if(type == 'services'){
            return [];
        }
        return '';
    }

    function AddNewServ(){
        setServices([...services, { "id": 0, "idNew": 0, "name": "", "status": false }]);
    }

    function HandleServ(type, index, value, status){
        const newData = [...services];
        newData.map((key, index)=>{
            key.status = false;
        });
        newData[index][type] = value;
        setServices(newData);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData        = [...services];
            newData.splice(index, 1);
            setServices(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "services", "type": "delete_combo", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(){
        Reg_NewCombo(modalData.id, name, services, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
        ClosePopUp();
    }

    function ClosePopUp(){
        setQtdHeight(false);
        SetModalState('NewCombo', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_newcombo">
                        <div className="div_data type_div">
                            <div className="title">
                                Combo
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="space_width" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" />
                                        <span className="name_input">Nome do combo</span>
                                    </div>
                                    <div className="div_input add_new_serv">
                                        <div className="" onClick={ ()=>{ AddNewServ() } }>
                                            + serviço
                                        </div>
                                    </div>
                                </div>
                                {
                                    services.map((key, index)=>{
                                        return(
                                            <div className="list_input_data" key={ index } style={ { position: 'relative' } }>
                                                <div className="div_show_select_height space_div">
                                                    <div className="div_show_select_opt_selected" onClick={ ()=>{ HandleServ('status', index, !key.status); } }>
                                                        <div className="div_show_select_name_data" title="Selecione um grupo">
                                                            { key.name == "" ? "Selecione um serviço" : key.name }
                                                        </div>
                                                        <div className="div_show_select_icons">
                                                            <SvgSetaRight color="#E51D4D" className="icons" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="div_input" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex', marginTop: 0 } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                                {
                                                    key.status == false ? null :
                                                    <div className="div_show_select_list_data">
                                                        <div className="show_data_existing">
                                                            <div className="div_show_select_name_data div_group">
                                                                Lista dos combos
                                                            </div>
                                                            {
                                                                showServ.map((key_1, index_1)=>{
                                                                    return (
                                                                        <div className="div_show_select_name div_show_select_name_data div_group" key={ index_1 } title={ key_1.name } onClick={ ()=>{ HandleServ('idNew', index, key_1.id); HandleServ('name', index, key_1.code + " - " + key_1.name);HandleServ('status', index, false); } }>
                                                                            <div className="">
                                                                                { key_1.code } - { key_1.name }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className="close_list_data">
                                                            <div className="div_show_select_name div_show_select_new_data div_show_select_cancel" onClick={ ()=>{ HandleServ('status', index, false); } }>
                                                                Cancelar
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )

                                    })
                                }

                                <div className="div_save">
                                    <div className="btn_" onClick={ ()=>{ ClosePopUp(); } }>Cancelar</div>
                                    <div className="btn_ button_save" onClick={ ()=>{ SaveData() } }>+ Adicionar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
