import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_CopyBudget, Reg_CopyService, Reg_Delete, Reg_OpenBudget, Reg_Reactivate } from "services/RegisterData";

import { GetUserData, RegisterUserData } from "interface/Users";

export default function PopUP_Confirmation(){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    function TypeAlt(){
        setLoading(true);
        switch (modalData.type) {
            case "copy_budget":
                    Reg_CopyBudget(modalData.id, userId, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;
            
            case "copy_services":
                    Reg_CopyService(modalData.id, userId, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;

            case "lock_open_budget":
                    Reg_OpenBudget(modalData.id, userId, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;
            
            case "reactivate_data":
                    Reg_Reactivate(modalData.type_register, modalData.origin, modalData.id, userId, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;

            default:
                    Reg_Delete(userId, modalData.id, modalData.origin, modalData.type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;
        }
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
        SetModalState('Confirmation', false);
        SetModalState('DetailsTasks', false);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
        SetModalState('Confirmation', false);
    }

    function ClosePopUp(){
        setLoading(false);
        SetModalState('Confirmation', false);
    }

    function TypeRemoval(){
        switch(modalData.type){
            case "lock_open_budget":
                return (
                    <div>
                        Gerar nova revisão
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "disabled_access_dash":
                return (
                    <div>
                        Desativar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "reactivate_access_dash":
                return (
                    <div>
                        Reativar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "copy_budget":
                return (
                    <div>
                        Copiar orçamento:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "copy_services":
                return (
                    <div>
                        Copiar serviço:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "reactivate_client":
                return (
                    <div>
                        Reativar cliente:
                        <div>{ modalData.name }</div>
                    </div>
                );
            default:
                return (
                    <div>
                        Deletar:
                        <div>{ modalData.name }</div>
                    </div>
                );
        }
    }

    return (
        (showPopUp ?
            <>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <img alt="loading" src="./assets/loading.gif" className="loading" />
                </div>
                <div className="PopUp">
                    <div className="all Confirmation">
                        <div className="div_data">
                            <div className="title">
                                { TypeRemoval() }
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            {
                                modalData.type != "lock_open_budget" ? null :
                                    modalData.version_bigger <= modalData.version_actual ? null : 
                                    <div className="alert">
                                        <div className="attention">*Atenção*</div> 
                                        <div className="">
                                            Não é possivel editar uma versão anterior do orçamento, ao liberar o orçamento, ele irá mudar para a versão *{ modalData.version_bigger + 1 }*, porém os dados se manteram igual a versão atual
                                        </div>
                                    </div>
                            }
                            <div className="content div_confirmation">
                                <div onClick={ ()=>{ TypeAlt(); } }>
                                    <div className="opt_select yes_update">Sim</div>
                                </div>
                                <div onClick={ ()=>{ ClosePopUp(); } }>
                                    <div className="opt_select not_update">Não</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
