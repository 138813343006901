import React, { useState, useEffect } from "react";

import './Historic.css';

import { SvgClose, SvgDelete, SvgEdit, SvgImg } from "components/SvgFile";

import { GetUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";
import { SetListPag } from "interface/Page";

export default function PopUP_Historic(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ dataPage, setDataPage ]   = useState([]);
    const [ disabled, setDisabled ]   = useState([]);

    useEffect(()=>{
        RegisterModalData('Historic', setModaldata);
        RegisterModalObserver('Historic', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            RegisterDataPage(modalData.origin, setDataPage);
            setDataPage(GetDataPage(modalData.origin));
            setDisabled(InitialData(0));
        }
    }, [showPopUp]);

    useEffect(()=>{
        if(showPopUp == true){
            setDataPage(GetDataPage(modalData.origin));
            setDisabled(InitialData(0));
        }
    }, [dataPage]);

    function InitialData(value){
        const newData = [];
        dataPage.map((elem, index)=>{
            if(elem.status == value){                
                newData.push(elem);
            }
        });
        return newData;
    }

    function ClosePopUp(){
        SetModalState('Historic', false);
    }

    function Reactivate(id, value){
        SetModalData("Confirmation", { "id": id, "origin": modalData.origin, "type_register": modalData.type_register, "type": "reactivate_data", "name": value });
        SetModalState('Confirmation', true);
    }

    function OpenData(id){
        ClosePopUp();
        SetListPag('currentPage', modalData.origin + '_details')
        SetListPag('currentPageId', id);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_historic">
                        <div className="div_data type_div">
                            <div className="title">
                                { modalData.type }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <table cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th width="20" align="center">#</th>
                                            <th align="left">Nome</th>
                                            <th width="150" align="left">Data removido</th>
                                            <th width="150" align="left">Desativado por</th>
                                            <th width="50" align="right">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            disabled.map((elem, index)=>{
                                                return(
                                                    <tr key={ index }>
                                                        <td align="center">{ index + 1 }</td>
                                                        <td align="left">
                                                            {
                                                                modalData.origin == 'services' ? 
                                                                elem.code + ' - ' + elem.name : elem.name 
                                                            }
                                                        </td>
                                                        <td align="left">{ elem.date_removed }</td>
                                                        <td align="left">{ elem.name_user }</td>
                                                        <td>
                                                            <div className="list_opt">
                                                                <div className="reactivate" onClick={ ()=>{ Reactivate(elem.id, elem.name) }}>
                                                                    Reativar
                                                                </div>
                                                                <div onClick={ ()=>{ OpenData(elem.id) } }>
                                                                    <SvgEdit color="#324d6b" className="icons" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
