import { useState, useEffect } from "react";

import './Category.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import PopUp_NewCategory from "components/PopUp/NewCategory";
import { SetListPag } from "interface/Page";
import { SvgArchive, SvgDelete, SvgEdit, SvgSetaRight, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export default function Page_Services_Category(){

    const [ dataPage, setDataPage ] = useState(GetDataPage('services_category'));
    const [ showData, setShowData ] = useState(InitialData(1));
    const [ disabled, setDisabled ] = useState(InitialData(0));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('services_category', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('services_category'));
        setShowData(InitialData(1));
        setDisabled(InitialData(0));
    }, [dataPage]);

    function InitialData(value){
        const newData = [];
        dataPage.map((elem, index)=>{
            if(elem.status == value){                
                newData.push(elem);
            }
        });
        return newData;
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function NewCategory(id){
        SetModalData("NewCategory", { "id": id, "origin": "services_category" });
        SetModalState('NewCategory', true);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "services_category", "type": "delete_category_fixed", "name": value });
        SetModalState('Confirmation', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'services');
    }

    function NewHistoric(){
        SetModalData("Historic", { "origin": "services_category", "type_register": "reactivate_category", "type": "Categorias desativadas", "data": disabled });
        SetModalState('Historic', true);
    }
    
    return(
        <div className="Page_Services_Category">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                        Serviços
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page page_return" onClick={ ()=>{ ReturnPage() } }>
                        Categoria
                    </div>
                </div>
                <div className="list_opt_alt_page">
                    {
                        disabled.length == 0 ? null :
                        <>
                            <div data-tooltip-id="historic_" data-tooltip-content="Clique aqui para visualizar as categorias desativadas" data-tooltip-place="top" className="" onClick={ ()=>{ NewHistoric() } }>
                                <SvgArchive color="#606875" className="icons" />
                            </div>
                            <Tooltip id="historic_" />
                        </>
                    }   
                    <div className="new_block_text" onClick={ ()=>{ NewCategory(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Categoria
                        </div>
                    </div>
                </div>
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Nome</th>
                            <th width="40">Imagem</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    let style = null;
                                    return(
                                        <tr key={ index }>
                                            <td>{ startItens + index + 1 }</td>
                                            <td>{ key.name }</td>
                                            <td align="center">
                                                {
                                                    key.type_file == "Image" ?
                                                        key.file == '' ? 
                                                        <img data-tooltip-id={ "img_" + key.id } data-tooltip-content="Imagem de fundo" data-tooltip-place="top" alt={ "img_" + index } src="./assets/not_file.jpg" className="img_service" /> :
                                                        <img data-tooltip-id={ "delete_" + key.id } data-tooltip-content="Imagem de fundo" data-tooltip-place="top" alt={ "img_" + index } src={ "./assets/service/" + key.file } onClick={ ()=>{ OpenFile('showImg', "service/" + key.file) } } className="img_service" />
                                                    :
                                                        key.video == "" ? "---" :
                                                        <div onClick={ ()=>{ OpenFile(key.type_file, key.video) } } className="img_service">
                                                            {
                                                                key.type_file == 'Vimeo' ?  
                                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                                <SvgYoutube color="#324d6b" className="show_icons_project"/>
                                                            }
                                                        </div>
                                                    
                                                }
                                                <Tooltip id={ "img_" + key.id } />
                                            </td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    {
                                                        key.origin_ === 0 ? null : 
                                                        <>
                                                            <div data-tooltip-id={ "color_" + key.id } data-tooltip-content="Cor do texto" data-tooltip-place="top" className="show_color" style={ { backgroundColor: key.color } } />
                                                            <Tooltip id={ "color_" + key.id } />
                                                        </>
                                                    }
                                                    <div data-tooltip-id={ "delete_" + key.id } data-tooltip-content="Deletar categoria" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <Tooltip id={ "delete_" + key.id } />
                                                    <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Editar categoria" data-tooltip-place="top" onClick={ ()=>{ NewCategory(key.id, key.origin_) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                    <Tooltip id={ "edit_" + key.id } />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data" style={ { marginTop: 0 } }>Nenhuma categoria/tag encontrada...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    dataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUp_NewCategory />
        </div>
    )
}