
let DataPage = {
    "config_email"   : [],
    "about_chroma" : [],
    "client"       : [],

    "portfolio"     : [],
    "portfolio_tag" : [],

    "proposal"     : [],

    "services"          : [],
    "services_combo"    : [],
    "services_category" : [],

    "budget"              : [],
    "budget_motive"       : [],
    "budget_signature"    : [],
    "budget_text_fixed"   : [],
    "budget_historic"     : [],
    "budget_task"         : [],
    "budget_update"       : [],
    "budget_update_email" : [],
    "budget_category": [],

    "access_dash"  : [],
    "list_opt_td"  : [],

    "report_clients"   : [],
    "report_portfolio" : [],
    "report_proposal"  : [],
    "report_budget"    : [],

    "list_tasks"       : []
};

let NotifyDataPage = {
    "config_email"   : [],
    "about_chroma" : [],
    "client"       : [],

    "portfolio"     : [],
    "portfolio_tag" : [],

    "proposal"     : [],

    "services"          : [],
    "services_combo"    : [],
    "services_category" : [],

    "budget"              : [],
    "budget_motive"       : [],
    "budget_signature"    : [],
    "budget_text_fixed"   : [],
    "budget_historic"     : [],
    "budget_task"         : [],
    "budget_update"       : [],
    "budget_update_email" : [],
    "budget_category": [],

    "access_dash"  : [],
    "list_opt_td"  : [],

    "report_clients"   : [],
    "report_portfolio" : [],
    "report_proposal"  : [],
    "report_budget"    : [],

    "list_tasks"       : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["config_email"].forEach(element => { element(value['config_email']); });
    NotifyDataPage["about_chroma"].forEach(element => { element(value['about_chroma']); });
    NotifyDataPage["client"].forEach(element => { element(value['client']); });
    
    NotifyDataPage["portfolio"].forEach(element => { element(value['portfolio']); });
    NotifyDataPage["portfolio_tag"].forEach(element => { element(value['portfolio_tag']); });

    NotifyDataPage["proposal"].forEach(element => { element(value['proposal']); });

    NotifyDataPage["services"].forEach(element => { element(value['services']); });
    NotifyDataPage["services_combo"].forEach(element => { element(value['services_combo']); });
    NotifyDataPage["services_category"].forEach(element => { element(value['services_category']); });
    
    NotifyDataPage["budget"].forEach(element => { element(value['budget']); });
    NotifyDataPage["budget_motive"].forEach(element => { element(value['budget_motive']); })
    NotifyDataPage["budget_signature"].forEach(element => { element(value['budget_signature']); });
    NotifyDataPage["budget_text_fixed"].forEach(element => { element(value['budget_text_fixed']); });
    NotifyDataPage["budget_historic"].forEach(element => { element(value['budget_historic']); });
    NotifyDataPage["budget_task"].forEach(element => { element(value['budget_task']); });
    NotifyDataPage["budget_update"].forEach(element => { element(value['budget_update']); });
    NotifyDataPage["budget_update_email"].forEach(element => { element(value['budget_update_email']); });
    NotifyDataPage["budget_category"].forEach(element => { element(value['budget_category']); });

    NotifyDataPage["access_dash"].forEach(element => { element(value['access_dash']); });
    NotifyDataPage["list_opt_td"].forEach(element => { element(value['list_opt_td']); });

    NotifyDataPage["report_clients"].forEach(element => { element(value['report_clients']); });
    NotifyDataPage["report_portfolio"].forEach(element => { element(value['report_portfolio']); });
    NotifyDataPage["report_proposal"].forEach(element => { element(value['report_proposal']); });
    NotifyDataPage["report_budget"].forEach(element => { element(value['report_budget']); });

    NotifyDataPage["list_tasks"].forEach(element => { element(value['list_tasks']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
