import { useState, useEffect } from "react";

import './Tags.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import PopUp_NewCategory from "components/PopUp/NewTag";

import { SvgDelete, SvgEdit, SvgSearch, SvgSetaRight } from "components/SvgFile";
import PopUp_NewTag from "components/PopUp/NewTag";

export default function Page_Portfolio_Tags(){

    const [ search, setSearch ]       = useState('');
    const [ dataPage, setDataPage ]   = useState(GetDataPage('portfolio_tag'));
    const [ itensPage, setItensPage ] = useState(60);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(dataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = dataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('portfolio_tag', setDataPage);
    }, []);

    function SearchInput(value){
        const newData = [];
        if(value){
            GetDataPage("portfolio_tag").forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newData.push(item);
                }
            });
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setDataPage(duplicate);
        }else {
            setDataPage(GetDataPage("portfolio_tag"));
        }
        setSearch(value);
    }

    function NewTag(id){
        SetModalData("NewTag", { "id": id, "type": "Público", "name": "" });
        SetModalState('NewTag', true);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_tag", "name": value });
        SetModalState('Confirmation', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'portfolio');
    }
    
    return(
        <div className="Page_Portfolio_Tags">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                        Portfólio
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page page_return" onClick={ ()=>{ ReturnPage() } }>
                        Tags
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar..." />
                </div>
                <div className="list_opt_alt_page">
                    <div className="new_block_text" onClick={ ()=>{ NewTag(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Tag
                        </div>
                    </div>
                </div>
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th width="60">Tipo</th>
                            <th>Nome</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td>{ startItens + index + 1 }</td>
                                            <td>{ key.type }</td>
                                            <td>{ key.name }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ NewTag(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data" style={ { marginTop: 0 } }>Nenhuma tag encontrada...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    dataPage.length >= 60 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUp_NewTag />
        </div>
    )
}