import { useState, useEffect } from "react";

import './Toast.css';

import { SvgClose } from "components/SvgFile";

export default function Toast(props){

    return(
        <div className="Toast">
            <div className="div_text">
                <div className="text_toast">
                    Informação copiada com sucesso
                </div>
                {/* 
                <div className="div_icon_close">
                    <SvgClose className="icons" color="#F00000" />
                </div> 
                */}
            </div>
            <div className="progress" />
        </div>
    )
}