import { useState, useEffect } from "react";

import './Proposal.css';

import { SvgEdit, SvgEye, SvgReturn, SvgSetaRight } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetListPag } from "interface/Page";

export default function Page_Report_Proposal(){
    const date                            = new Date();
    const [ showYear, setShowYear ]       = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);

    const [ loading, setLoading ]   = useState(false);
    const [ showData, setShowData ] = useState(GetDataPage('proposal'));
    const [ listData, setListData ] = useState(InitialData(currentYear));    

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(listData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('proposal', setShowData);
    }, []);

    useEffect(()=>{
        setListData(InitialData(currentYear));
    }, [currentYear]);

    function InitialData(value){
        const newData = showData.filter(item => item.year == value);
        return newData;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'report');
        SetListPag('currentPageId', 'remuve');
    }

    function OpenData(id){
        SetListPag('currentPage', 'proposal_details')
        SetListPag('currentPageId', id);
    }

    function RegisterBudget(idProject, idBudget){
        SetListPag('currentPage', 'budget_details');
        SetListPag('currentPageId', idBudget);
        SetListPag('idNewRegister', idProject);
    }

    function OpenProposal(id){
        window.open(process.env.REACT_APP_API_URL + '?project=proposal/' + id, '_blank');
    }

    return(
        <div className="Page_Report_Proposal">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                        Relatório
                    </div>
                    <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Detalhes
                    </div>
                </div>
                <div className="">
                    <div className="name_project">
                        Propostas
                    </div>
                </div>
            </div>
            
            <div className="list_opt_year">
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <SvgReturn color="#324d6b" className="icons" />
                </div>
                <div className="name_project">
                    { currentYear }
                </div>
                <div className="div_return" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <SvgReturn color="#324d6b" className="icons icon_next" />
                </div>
            </div>

            <div className="space_top">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="180">Status</th>
                            <th>Cliente</th>
                            <th>Projeto</th>
                            <th width="80">Data</th>
                            <th width="130">Tipo</th>
                            <th width="30" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ? 
                                currentItens.map((key, index)=>{
                                    let classStatus = "";
                                    switch (key.status) {
                                        case 'Contato inicial': classStatus = 'status_1'; break;
                                        case 'Reunião agendada': classStatus = 'status_2'; break;
                                        case 'Proposta enviada': classStatus = 'status_3'; break;
                                        case 'Negociação': classStatus = 'status_4'; break;                                            
                                        case 'Conquistado': classStatus = 'status_5'; break;
                                        case 'Perdido': classStatus = 'status_6'; break;
                                        case 'Refazer proposta': classStatus = 'status_7'; break;                                           
                                        case 'Preparando proposta': classStatus = 'status_8'; break;
                                    }
                                    return(
                                        <tr key={ index }>
                                            <td align="center">{ startItens + index + 1 }</td>
                                            <td>
                                                <div className={ "btn_status " + classStatus }>{ key.status }</div>
                                            </td>
                                            <td>{ key.nameClient }</td>
                                            <td>{ key.title }</td>
                                            <td>{ key.date_br }</td>
                                            <td align="right">
                                                <div className="list_opt_">
                                                    {
                                                        key.id_budget == 0 ?
                                                        <div className="new_budget" onClick={ ()=>{ RegisterBudget(key.id, key.id_budget) } }>+ Orçamento</div> :
                                                        <div className="show_budget" onClick={ ()=>{ RegisterBudget(key.id, key.id_budget) } }>Ver orçamento</div>
                                                    } 
                                                </div>                                               
                                            </td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    {/* <div className="" title="Copiar link" onClick={ ()=>{ CopyLink(key.id) } }>
                                                        <SvgCopy color="#324d6b" className="icons" />
                                                    </div> */}
                                                    <div onClick={ ()=>{ OpenProposal(key.id) } }>
                                                        <SvgEye color="#324d6b" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ OpenData(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data" style={ { marginTop: 0 } }>Nenhuma proposta encontrada...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}