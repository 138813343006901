import { useState, useEffect, useRef } from "react";

import './Proposal.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SvgBehance, SvgFullScreenClose, SvgFullScreenOpen, SvgInstagram, SvgLinkedin, SvgLogoTipo, SvgLogoTipo_2, SvgPlay, SvgSetaRight, SvgStar, SvgYoutube } from "components/SvgFile";
import PopUP_ShowPortfolio from "components/PopUp/ShowFilePortfolio";

export default function Preview_Proposal(props){

    const PageFullScreen    = useRef();
    const [ width, setWidth ]   = useState('');
    const [ height, setHeight ] = useState('');

    const [ url, setUrl ]   = useState(ShowDataLink());

    const [ fullscreen, setFullscreen ] = useState(false);
    const [ about, setAbout ]           = useState(GetDataPage('about_chroma'));
    const [ listServ, setListServ ]     = useState(about.list_serv);
    const [ listStar, setListStar ]     = useState(about.list_star);
    const [ listFooter, setListFooter ] = useState(about.list_footer);
    const [ listBrand, setListBrand ]   = useState(about.list_brand);

    const [ proposal, setProposal ]   = useState(InitialData());
    const [ showAbout, setShowAbout ] = useState(ShowDataAbout());

    const [ colorBg, setColorBg ] = useState('');

    const [ page, setPage ]       = useState(1);
    const [ listBtn, setListBtn ] = useState([]);
    
    function ShowDataLink(){
        let link    = new URLSearchParams(window.location.search);
        let sepLink = link.get("project").split('/');
        
        if(sepLink[0] == 'proposal'){
            return sepLink[1];

        }else {
            return window.history.pushState("", "", "/");
        }
    }

    function InitialData(){
        const newData = GetDataPage('proposal').find(item => item.id == url);
        return newData;
    }

    function ShowDataAbout(){
        if(url == 0){
            return true;
        }else {
            return proposal.showAboutCompany;
        }
    }

    function OpenService(index, status){
        const newData = [...listServ];
        newData.map((elem, index_1)=>{
            let accordion = document.querySelector('.accordion_' + index_1);
            if(index_1 == index){                
                if(status == true){
                    accordion.style.maxHeight = accordion.scrollHeight + "px";
                    accordion.style.padding   = "12px 20px 20px";
                    elem.status = true;  
                }else {
                    accordion.style.maxHeight = null;
                    accordion.style.padding   = "12px 20px 0px";  
                    elem.status = false;  
                }
            
            }else {
                accordion.style.maxHeight = null;
                accordion.style.padding   = "12px 20px 0px";  
                elem.status = false;  
            }

        })
        setListServ(newData);
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                let linkVimeo = video.split('/');
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://player.vimeo.com/video/" + linkVimeo[3] + "?autoplay=1&loop=1&title=0&byline=0&portrait=0&loop=1&controls=0" } frameBorder="0" allowFullScreen />
                        </div>
                    </div>
                )

            case 'Youtube':
                let linkYoutube = video.split('=');
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://www.youtube.com/embed/" + linkYoutube[1] + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + linkYoutube[1] + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen />
                        </div>
                    </div>
                )
        }
    }

    function OpenFullscreen() {
        setFullscreen(true);
        let page = document.getElementById("FullScreen");
        if(page.requestFullscreen) {
            page.requestFullscreen();
            
        }else if(page.webkitRequestFullscreen) { 
            page.webkitRequestFullscreen();
            
        }else if(page.msRequestFullscreen) { 
            page.msRequestFullscreen();
        }
    }

    function CloseFullscreen() {
        setFullscreen(false);
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

    function ShowDataBtn(){
        const newData = [];
        if(showAbout == false){
            if(url != 0){
                if(proposal.preview.length > 0){
                    newData.push({ "btn": "allCategory" });
                }
                proposal.preview.map((elem, index)=>{
                    newData.push({ "btn": "category/" + elem.id });
                    elem.services.map((elem_1, index_1)=>{
                        newData.push({ "btn": "service/" + elem.id + "/" + elem_1.id });
                    });
                })
            }
        }else {
            newData.push({ "btn": "about_1" });
            newData.push({ "btn": "about_2" });
            newData.push({ "btn": "about_3" });

            if(url != 0){
                if(proposal.preview.length > 0){
                    newData.push({ "btn": "allCategory" });
                }
                proposal.preview.map((elem, index)=>{
                    newData.push({ "btn": "category/" + elem.id });
                    elem.services.map((elem_1, index_1)=>{
                        newData.push({ "btn": "service/" + elem.id + "/" + elem_1.id });
                    })
                })
            }
        }
        
        newData.push({ "btn": "fixed_1" });
        newData.push({ "btn": "fixed_2" });
        newData.push({ "btn": "fixed_3" });
        setListBtn(newData);
    }

    function ShowDataContents(type, data){
        switch (type) {
            case "opc1.jpg":
                return(
                    <div className="div_data_page_type div_video_or_img">
                        {
                            data.type_file_1 == "Image" ? 
                            <img alt="img" src={ "./assets/service/" + data.img_1 } className="div_img" /> :
                            ShowVideo(data.type_file_1, data.video_1)
                        }
                    </div>
                )

            case "opc2.jpg":
                return(
                    <div className="div_data_page_type type_2">
                        <div className="show_type_2">
                            <div className="div_video_or_img">
                                {
                                    data.type_file_1 == "Image" ? 
                                    <img alt="img" src={ "./assets/service/" + data.img_1 } className="div_img" /> :
                                    ShowVideo(data.type_file_1, data.video_1)
                                }
                            </div>
                        </div>
                        <div className="show_type_2">
                            <div className="div_video_or_img">
                                {
                                    data.type_file_2 == "Image" ? 
                                    <img alt="img" src={ "./assets/service/" + data.img_2 } className="div_img" /> :
                                    ShowVideo(data.type_file_2, data.video_2)
                                }
                            </div>
                        </div>
                    </div>
                )
                
            case "opc3.jpg":
                return(
                    <div className="div_data_page_type type_2">
                        <div className="show_type_2">
                            <div className="div_video_or_img">
                                {
                                    data.type_file_1 == "Image" ? 
                                    <img alt="img" src={ "./assets/service/" + data.img_1 } className="div_img" /> :
                                    ShowVideo(data.type_file_1, data.video_1)
                                }
                            </div>
                        </div>
                        <div className="show_type_2 type_3">
                            <div className="show_type_2">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_2 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_2 } className="div_img" /> :
                                        ShowVideo(data.type_file_2, data.video_2)
                                    }
                                </div>
                            </div>
                            <div className="show_type_2">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_3 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_3 } className="div_img" /> :
                                        ShowVideo(data.type_file_3, data.video_3)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
                
            case "opc4.jpg":
                return(
                    <div className="div_data_page_type type_2">
                        <div className="show_type_2 type_3">
                            <div className="show_type_2">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_1 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_1 } className="div_img" /> :
                                        ShowVideo(data.type_file_1, data.video_1)
                                    }
                                </div>
                            </div>
                            <div className="show_type_2">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_2 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_2 } className="div_img" /> :
                                        ShowVideo(data.type_file_2, data.video_2)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="show_type_2">
                            <div className="div_video_or_img">
                                {
                                    data.type_file_3 == "Image" ? 
                                    <img alt="img" src={ "./assets/service/" + data.img_3 } className="div_img" /> :
                                    ShowVideo(data.type_file_3, data.video_3)
                                }
                            </div>
                        </div>
                    </div>
                )
                
            case "opc5.jpg":
                return(
                    <div className="div_data_page_type type_2">
                        <div className="show_type_2 type_3">
                            <div className="show_type_2">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_1 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_1 } className="div_img" /> :
                                        ShowVideo(data.type_file_1, data.video_1)
                                    }
                                </div>
                            </div>
                            <div className="show_type_2">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_2 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_2 } className="div_img" /> :
                                        ShowVideo(data.type_file_2, data.video_2)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="show_type_2 type_3">
                            <div className="show_type_2">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_3 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_3 } className="div_img" /> :
                                        ShowVideo(data.type_file_3, data.video_3)
                                    }
                                </div>
                            </div>
                            <div className="show_type_2">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_4 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_4 } className="div_img" /> :
                                        ShowVideo(data.type_file_4, data.video_4)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
                
            case "opc6.jpg":
                return(
                    <div className="div_data_page_type type_6">
                        <div className="show_type_6">
                            <div className="div_video_or_img">
                                {
                                    data.type_file_1 == "Image" ? 
                                    <img alt="img" src={ "./assets/service/" + data.img_1 } className="div_img" /> :
                                    ShowVideo(data.type_file_1, data.video_1)
                                }
                            </div>
                        </div>
                        <div className="show_type_6">
                            <div className="div_video_or_img">
                                {
                                    data.type_file_2 == "Image" ? 
                                    <img alt="img" src={ "./assets/service/" + data.img_2 } className="div_img" /> :
                                    ShowVideo(data.type_file_2, data.video_2)
                                }
                            </div>
                        </div>
                    </div>
                )
                
            case "opc7.jpg":
                return(
                    <div className="div_data_page_type type_6">
                        <div className="type_7">
                            <div className="show_type_6">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_1 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_1 } className="div_img" /> :
                                        ShowVideo(data.type_file_1, data.video_1)
                                    }
                                </div>
                            </div>                            
                            <div className="show_type_6">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_2 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_2 } className="div_img" /> :
                                        ShowVideo(data.type_file_2, data.video_2)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="show_type_6 type_7">
                            <div className="div_video_or_img">
                                {
                                    data.type_file_3 == "Image" ? 
                                    <img alt="img" src={ "./assets/service/" + data.img_3 } className="div_img" /> :
                                    ShowVideo(data.type_file_3, data.video_3)
                                }
                            </div>
                        </div>
                    </div>
                )

            case "opc8.jpg":
                return(
                    <div className="div_data_page_type type_6">
                        <div className="show_type_6 type_7">
                            <div className="div_video_or_img">
                                {
                                    data.type_file_1 == "Image" ? 
                                    <img alt="img" src={ "./assets/service/" + data.img_1 } className="div_img" /> :
                                    ShowVideo(data.type_file_1, data.video_1)
                                }
                            </div>
                        </div>
                        <div className="type_7">
                            <div className="show_type_6">
                                <div className="div_video_or_img">
                                    {
                                        data.type_file_2 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_2 } className="div_img" /> :
                                        ShowVideo(data.type_file_2, data.video_2)
                                    }
                                </div>
                            </div>                            
                            <div className="show_type_6">
                                <div className="div_video_or_img">                                    
                                    {
                                        data.type_file_3 == "Image" ? 
                                        <img alt="img" src={ "./assets/service/" + data.img_3 } className="div_img" /> :
                                        ShowVideo(data.type_file_3, data.video_3)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    function OpenListPortfolio(listPortfolio){
        SetModalData('ShowPortfolio', { "data": listPortfolio });
        SetModalState('ShowPortfolio', true);
    }

    function ShowDataPage(value, type){
        let typePage = value.split('/');
        if(typePage[0] == 'allCategory'){
            return(
                <div className="proposal_list_services" style={ { backgroundColor: colorBg } }>
                    <div className={ "list_services list_services" + type }>
                        <div className="subtitle">Menu de ativações</div>

                        <div className="div_list_category">
                            {
                                proposal.preview.map((elem, index)=>{
                                    return(
                                        <div className="div_category" key={ index }>
                                            <div className="bg_color" />
                                            <div className="name_category" style={ { color: elem.color } }>{ elem.name }</div>
                                            <div className="list_service">
                                                {
                                                    elem.services.map((elem_1, index_1)=>{
                                                        return(
                                                            <div className="" key={ index_1 }>
                                                                { elem_1.code } { elem_1.name }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            )

        }else if(typePage[0] == 'category'){ 
            return(
                proposal.preview.map((elem, index)=>{
                    if(elem.id == typePage[1]){
                        return(
                            <div className={ "div_name_category div_name_category" + type } style={ { backgroundColor: colorBg } } key={ index }>
                                <div className="div_data_category">
                                    <div className="bg_color" style={ { backgroundColor: elem.color } } />
                                    <div className="name_category">{ elem.name }</div>
                                </div>

                                <div className="div_video_or_img">
                                    <div className="dotted" />
                                    {
                                        elem.type_file == 'Image' ? <img alt="img" src={ "./assets/service/" + elem.file } className="show_full" /> :
                                        ShowVideo(elem.type_file, elem.video)
                                    } 
                                </div>
                            </div>
                        )
                    }
                })
            )  

        }else if(typePage[0] == 'service'){
            return(
                proposal.preview.map((elem, index)=>{
                    if(elem.id == typePage[1]){
                        return(
                            elem.services.map((elem_1, index_1)=>{
                                if(elem_1.id == typePage[2]){
                                    return(
                                        <div className={ "div_list_service div_list_service" + type } style={ { backgroundColor: colorBg } } key={ index_1 }>
                                            <div className="div_line">
                                                <div className="div_show_logo" style={ { backgroundColor: colorBg } }>
                                                    <img alt="logo" src={ "./assets/" + elem.type_logo } className="icons" />
                                                    {/* <SvgLogoTipo_2 className="icons" color="#FFFFFF" /> */}
                                                </div>
                                                <div className="line_color" />
                                            </div>
                                            <div className={ elem_1.file.length == 0 ? "div_data div_data_full" : "div_data" }>
                                                <div className="subtitle sigla">{ elem_1.code }</div>
                                                <div className="name_service" style={ { color: elem.color } }>
                                                    { elem_1.name }
                                                </div>
                                                <div className="text" dangerouslySetInnerHTML={ { __html: elem_1.text ? elem_1.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                <div className="list_portfolio" onClick={ ()=>{ OpenListPortfolio(elem_1.portfolio) } }>
                                                    {
                                                        elem_1.portfolio.length == 0 ? null : 
                                                        <div className="">
                                                            Visualizar portfólios
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                elem_1.file.map((elem_2, index_2)=>{
                                                    return(
                                                        <div className="show_div_file" key={ index_2 }>
                                                            { ShowDataContents(elem_2.type, elem_2) }
                                                        </div>
                                                    )                                                        
                                                })
                                            }
                                        </div>
                                    )
                                }
                            })
                        )
                    }
                })
            ) 

        }else {
            switch (value) {
                case 'about_1':
                    return(
                        <div className="show_about_home">
                            <div className="div_text">
                                <div className={ "show_title show_title" + type } dangerouslySetInnerHTML={ { __html: about.home_title ? about.home_title.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            </div>

                            <div className={ "show_rede_social show_rede_social" + type }>
                                <div className="list_data_rede_social">
                                    <a href="" className={ "name_click name_click" + type } onClick={ ()=>{  } }>
                                        Vamos conversar
                                    </a>
                                    <div className="div_rede_social">
                                        <div className="div_icons_social">
                                            <a href="https://www.youtube.com/user/chromagarden" target="_blank">
                                                <SvgYoutube className={ "icons_rede icons_rede" + type } color="#ffffff" />
                                            </a>
                                        </div>
                                        <div className="div_icons_social">
                                            <a href="https://www.instagram.com/chromagarden/" target="_blank">
                                                <SvgInstagram className={ "icons_rede icons_rede" + type } color="#ffffff" />
                                            </a>
                                        </div>
                                        <div className="div_icons_social">
                                            <a href="https://www.behance.net/chromagarden" target="_blank">
                                                <SvgBehance className={ "icons_rede icons_rede" + type } color="#ffffff" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>      
                            
                            <div className="div_video_or_img">
                                <div className="dotted" />  
                                {
                                    about.home_type == 'Image' ? <img alt="img" src={ "./assets/about/" + about.home_img } className="show_full" /> :
                                    ShowVideo(about.home_type, about.home_file)
                                } 
                            </div>
                        </div>
                    );
                case 'about_2':
                    return(
                        <div className={ "show_about_btn show_about_btn" + type } style={ { backgroundColor: about.serv_color } }>
                            <div className="about_btn">
                                <div className={ "div_title div_title" + type }>
                                    <div className="subtitle">Serviços</div>
                                    <div className={ "title title" + type } dangerouslySetInnerHTML={ { __html: about.serv_title ? about.serv_title.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                    <div className={ "text text" + type } dangerouslySetInnerHTML={ { __html: about.serv_subtitle ? about.serv_subtitle.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                </div>
                                <div className="div_btn">
                                    {
                                        listServ.map((elem, index)=>{
                                            return(
                                                type != "" ? 
                                                <div className="show_opt" key={ index }>
                                                    <div className={ "title title" + type }>
                                                        { elem.title }
                                                    </div>
                                                </div> :
                                                <div className="show_opt" key={ index } onClick={ ()=>{ OpenService(index, !elem.status) } }>
                                                    <div className={ elem.status == true ? "title active" : "title" }>
                                                        { elem.title }
                                                    </div>
                                                    <div className={ "text accordion_" + index } dangerouslySetInnerHTML={ { __html: elem.text ? elem.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                case 'about_3':
                    return(
                        <div className="show_about_star" style={ { backgroundColor: about.star_color } }>
                            <div className={ "about_star about_star" + type }>
                                <div className="div_title">
                                    <div className="subtitle">Sobre nós</div>
                                    <div className="title" dangerouslySetInnerHTML={ { __html: about.about_title ? about.about_title.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                </div>

                                <div className="list_assessment">
                                    {
                                        listStar.map((elem, index)=>{
                                            let showStar = [];
                                            for (let index = 0; index < elem.star; index++) {
                                                showStar.push(<SvgStar className="icons" color="#ffffff" />);
                                            }

                                            return(
                                                <div className="div_star" key={ index }>
                                                    <div className="star">
                                                        {
                                                            showStar.map((elem_1, index_1)=>{
                                                                return(
                                                                    <div className="" key={ index_1 }>{ elem_1 }</div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="text" dangerouslySetInnerHTML={ { __html: elem.text ? elem.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                    <div className="client">{ elem.title }</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            
                            <div className={ "list_number list_number" + type }>
                                {
                                    listFooter.map((elem, index)=>{
                                        return(
                                            <div className="div_number" key={ index }>
                                                {
                                                    elem.sigla == '' ? null : <div className="sigla">{ elem.sigla }</div>
                                                }                                    
                                                <div className="number">{ elem.number }</div>
                                                <div className="title" dangerouslySetInnerHTML={ { __html: elem.title ? elem.title.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )

                case 'fixed_1':
                    return(
                        <div className="show_about_inf">
                            <div className={ "about_inf about_inf" + type }>
                                <div className="title">QUER RECEBER UMA PROPOSTA PERSONALIZADA?</div>
                                <div className="subtitle">Solicite ao nosso time comercial</div>
                                <div className="contact">
                                    <div className="email">comercial@chromagarden.com</div>
                                    <div className="numb">+55 (41) 99258-3829</div>                        
                                </div>
                                <div className="list_logotipo">  
                                    <div className="show_icon">
                                        <a href="https://www.instagram.com/chromagarden/" target="_blank">
                                            <SvgInstagram className="icons" color="#ffffff" />
                                        </a>
                                    </div>                   
                                    <div className="show_icon">
                                        <a href="https://www.linkedin.com/company/chroma-garden" target="_blank">
                                            <SvgLinkedin className="icons" color="#ffffff" />
                                        </a>                     
                                    </div>                   
                                    <div className="show_icon">
                                        <a href="https://www.behance.net/chromagarden" target="_blank">
                                            <SvgBehance className="icons" color="#ffffff" />
                                        </a>
                                    </div>                   
                                    <div className="show_icon">
                                        <a href="https://www.youtube.com/user/chromagarden" target="_blank">
                                            <SvgPlay className="icons" color="#ffffff" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                case 'fixed_2':
                    return(
                        <div className="show_about_clients" style={ { backgroundColor: about.client_color } }>
                            <div className={ "about_clients about_clients" + type }>
                                <div className="title">Marcas que confiam em nosso time: </div>
                                <div className="list_clients">
                                    {
                                        listBrand.map((elem, index)=>{
                                            return(
                                                <div className={ "logo_client logo_client" + type } key={ index }>
                                                    <img alt="img" src={ "./assets/about/" + elem.img } className="img" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="show_logo">
                                    <div className="client_logotipo">
                                        <SvgLogoTipo className="icons" color="#ffffff" />
                                    </div>
                                    <div className="text_logo">
                                        <div className="text">
                                            Confie em quem é líder de mercado.  Especialistas em eventos desde 2010.
                                        </div>
                                        <div className="link">
                                            <a href="www.chromagarden.com" target="_blank">
                                                www.chromagarden.com
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                case 'fixed_3':
                    return(
                        <div className="inf_fixed" style={ { backgroundColor: about.end_color } }>
                            <div className={ "text text" + type } dangerouslySetInnerHTML={ { __html: about.text_end ? about.text_end.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        </div>
                    )
            }
        }
    }

    useEffect(()=>{
        RegisterDataPage('about_chroma', setAbout);        
        if(url == 0){
            setColorBg('#000000');
            document.title = "Proposta";
            document.body.style.backgroundColor = '#000000';
        }else{
            setColorBg(proposal.color);
            document.title = "Proposta - " + proposal.title;
            document.body.style.backgroundColor = proposal.color;
        }
        ShowDataBtn();
    }, []);
    
    return(
        <div className="Preview_Proposal" id="FullScreen" ref={ PageFullScreen }>
            <div className="show_data">
                {
                    listBtn.map((elem, index)=>{
                        return(
                            <div className={ page == (index + 1) ? "show_data_page show_page_active" : "show_data_page" } key={ index }>
                                { ShowDataPage(elem.btn, "") }
                            </div>
                        )
                    })
                }
            </div>

            <div className="show_btn">
                <div className="qtd_page">
                    {
                        listBtn.map((elem, index)=>{
                            let space_example = '';
                            if(index == 0){
                                space_example = 'img_example_start';
                            }else if(listBtn.length == index + 1){
                                space_example = 'img_example_end';
                            }
                            // style={ { display: index == 5 ? "block": "none" } }
                            return(
                                <div className="space_page" onClick={ ()=>{ setPage(index + 1); } } key={ index }>
                                    <div className={ page == (index + 1) ? "color_line page_active" : "color_line" } />
                                    <div className={ "img_example " + space_example }>
                                        { ShowDataPage(elem.btn, "_example") }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="opt_alt_page">
                    <div className="alt_page">
                        {
                            page == 1 ?
                            <div className="arrow">
                                <SvgSetaRight className="icons left" color="#00000078" />
                            </div> : 
                            <div className="arrow" data-tooltip-id="arrow_left" data-tooltip-content="Voltar página" data-tooltip-place="top" onClick={ ()=>{ setPage(page - 1) }}>
                                <SvgSetaRight className="icons left" color="#ededed" />
                            </div>
                        }
                        <Tooltip id="arrow_left" />

                        <div className="">
                            { page }/{ listBtn.length }
                        </div>
                        {
                            listBtn.length == page ?
                            <div className="arrow">
                                <SvgSetaRight className="icons" color="#00000078" />
                            </div> :                            
                            <div className="arrow" data-tooltip-id="arrow_right" data-tooltip-content="Avançar página" data-tooltip-place="top" onClick={ ()=>{ setPage(page + 1) }}>
                                <SvgSetaRight className="icons" color="#ededed" />
                            </div>
                        }
                        <Tooltip id="arrow_right" />
                    </div>
                    
                    {
                        proposal.budget == "" ? "" :
                        <div className="div_budget">
                            <div className="btn_budget" onClick={ ()=>{ window.open(process.env.REACT_APP_API_URL + '?project=budget/' + proposal.id_budget, '_blank'); } }>
                                Visualizar orçamento
                            </div>
                        </div>
                    }

                    <div className="fullscreen">
                        {
                            fullscreen == false ? 
                            <div className="div_fullscreen" data-tooltip-id="fullscreen" data-tooltip-content="Abrir em tela cheia" data-tooltip-place="top" onClick={ ()=>{ OpenFullscreen(); } }>
                                <SvgFullScreenClose className="icons" color="#ededed" />
                            </div> :
                            <div className="div_fullscreen" data-tooltip-id="fullscreen" data-tooltip-content="Fechar a tela cheia" data-tooltip-place="top" onClick={ ()=>{ CloseFullscreen(); } }>
                                <SvgFullScreenOpen className="icons" color="#ededed" />
                            </div>
                        }
                        <Tooltip id="fullscreen" />
                    </div>
                </div>
            </div>
            <PopUP_ShowPortfolio />
        </div>
    )
}