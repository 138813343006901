import { useState, useEffect, useRef } from "react";

import './Text.css';

export default function PreviewFixed_Text(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    return(
        <div className="div_show_text_simple">
            <div className={ "text align_width_" + dataPage.width } dangerouslySetInnerHTML={ { __html: dataPage.text ? dataPage.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
        </div>
    )
}