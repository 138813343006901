import { useState, useEffect, useRef } from "react";

import './Video.css';

import YouTube from "react-youtube";
import Vimeo from '@u-wave/react-vimeo';

import { SvgPlay } from "components/SvgFile";

export default function PreviewFixed_Video(props){

    const [ dataPage, setDataPage ] = useState(props.data);
    const [ status, setStatus ]     = useState(false);

    const optsYoutube = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    function PlayDrive(){
        if(dataPage.type_video == 'Drive'){
            if(status == true){
                let playDrive = setInterval(() => {
                    if(document.querySelector('.html5-video-player')){
                        document.querySelector('.html5-video-player').classList.add('playing-mode');
                        clearInterval(playDrive);
                    }
                }, 100);
            }else {
                // let playDrive = setInterval(() => {
                //     if(document.querySelector('.html5-video-player')){
                //         document.querySelector('.html5-video-player').classList.remove('');
                //         clearInterval(playDrive);
                //     }
                // }, 100);
            }
        }
    }

    function ShowVideoContents(type, video){
        switch (type) {
            case 'Vimeo':
                let linkVimeo = video.split('/');

                return (
                    status == false ?
                    <img alt="img" src={ "https://vumbnail.com/" + linkVimeo[3]  + ".jpg" } className="div_video img_video" />
                    : 
                    <Vimeo className="div_video" video={ linkVimeo[3] } autoplay />
                )

            case 'Drive':
                let linkDrive = video.split('/');
                return (
                    <iframe className="div_video" src={ "https://drive.google.com/file/d/" + linkDrive[5] + "/preview" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />
                )
            
            default:
                let linkYoutube = video.split('=');
                return (
                    status == false ?
                    <img alt="img" src={ "https://img.youtube.com/vi/" + linkYoutube[1]  + "/maxresdefault.jpg" } className="div_video img_video" />
                    : 
                    <YouTube className="div_video"  videoId={ linkYoutube[1] } opts={ optsYoutube } />
                )
        }
    }

    return(
        <div className="div_list_show_video">
            <div className="div_show_video">
                {
                    dataPage.type_video == 'Drive' ?                     
                    <div className="div_title_drive">
                        { dataPage.title } 
                    </div> :                     
                    <div className={ status == true ? "div_title_play play_hide" : "div_title_play" } onClick={ ()=>{ setStatus(!status); PlayDrive() } }>
                        { dataPage.title }
                        <SvgPlay className="icons_play" color="#ffffff" />
                    </div>
                }
                { ShowVideoContents(dataPage.type_video, dataPage.link) }
            </div>
        </div>
    )
}