import { useState, useEffect, useRef } from "react";

import './Highlight_1.css';

export default function PreviewFixed_highlight_1(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    return(
        <div className="div_show_highlight_1">
            {
                dataPage.title == "" ? null : <div className="highlight_1_title" dangerouslySetInnerHTML={ { __html: dataPage.title.replaceAll('&#34;', '"') } } />
            }
            <div className={ "highlight_1_text align_" + dataPage.width }>
                <div className={ "text align_" + dataPage.width } dangerouslySetInnerHTML={ { __html: dataPage.text ? dataPage.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
            </div>
        </div>
    )
}